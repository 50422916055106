import React from "react";
import { formatNumber } from "../../../middleware/common-functions";
import { DeliveryType } from "../../../models/pos_model";
import useSettings from "../../../hooks/settings";
import { Button } from "../../common/buttons";

type Props = {
  total: number;
  click: () => void;
  count: number;
  delivery: DeliveryType;
  promptMakeOrder: () => void;
  addComment: () => void;
};

const OrderResume: React.FC<Props> = ({
  total,
  count,
  addComment,
  delivery,
  click,
  promptMakeOrder,
}) => {
  const buttonClasses = [
    "btn",
    "btn-raised",
    "btn-danger",
    "btn_lg",
    "btn-block",
    "ripple-effect",
    "btn_xs_lg",
    "make_order_btn",
  ];
  const disabled = !(total > 0);
  if (disabled) {
    buttonClasses.push("disabled", "disabled_danger");
  }
  return (
    <div className="row p-3 px-sm-0 m-0">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-7">
            <p className="media-body m-0 small lh-125">
              Productos{" "}
              <span className="products_num">{formatNumber(count)}</span>
            </p>
          </div>
          <div className="col-5 p-0">
            {" "}
            <Button
              data_type="order"
              classes="btn btn-transparent min_with_1 btn-block m-0 text-right"
              fun={addComment}
              icon="far fa-comment-alt text-muted ordercomments"
            />
          </div>
        </div>
        <p className="display_none" id="desc_block">
          Descuento $<span className="disc">0</span> (
          <span className="disc_p">0</span>%)
        </p>
        <hr />
        Total: $<span className="order_totals">{formatNumber(total)}</span>{" "}
      </div>
      {promptMakeOrder && (
        <button className="btn btn-block btn-danger" onClick={promptMakeOrder}>
          Guardar
        </button>
      )}
      <DeliveryOptions delivery={delivery} click={click} />
    </div>
  );
};

type DeliveryOptionsProps = {
  delivery: DeliveryType;
  click: () => void;
};

const DeliveryOptions: React.FC<DeliveryOptionsProps> = ({
  delivery,
  click,
}) => {
  const [settings] = useSettings();
  return (
    <div className="col-12">
      <hr />
      <div className="" data-toggle="buttons">
        <Delivery
          type={DeliveryType.for_here}
          checked={delivery === DeliveryType.for_here}
          click={click}
        />
        <Delivery
          type={DeliveryType.pickup}
          checked={delivery === DeliveryType.pickup}
          click={click}
        />
        {settings.disable_delivery ? null : (
          <Delivery
            type={DeliveryType.delivery}
            checked={delivery === DeliveryType.delivery}
            click={click}
          />
        )}
        <Delivery
          type={DeliveryType.other_apps}
          checked={delivery === DeliveryType.other_apps}
          click={click}
        />
      </div>
    </div>
  );
};

type DeliveryProps = {
  checked: boolean;
  click: () => void;
  type: DeliveryType;
};

const Delivery: React.FC<DeliveryProps> = ({ type, checked, click }) => {
  let info = null;
  switch (type) {
    case DeliveryType.pickup:
      info = {
        text: "Para Recoger",
        icon: "far fa-hand-point-up",
      };
      break;
    case DeliveryType.delivery:
      info = {
        text: "A Domicilio",
        icon: "fa fa-motorcycle",
      };
      break;
    case DeliveryType.other_apps:
      info = {
        text: "Plataformas",
        icon: "fas fa-mobile-alt",
      };
      break;
    case DeliveryType.for_here:
    default:
      info = {
        text: "En Comedor",
        icon: "fas fa-stop-circle",
      };
      break;
  }
  return (
    <button
      className={`btn btn-outline-dark btn_xs_lg btn-block ${
        checked ? "active" : null
      } btn_check_delivery`}
      onClick={click}
      data-type={type}
    >
      <i className={info.icon} aria-hidden="true"></i> {info.text}
    </button>
  );
};

export default OrderResume;
