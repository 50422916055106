import React from "react";
import { Link } from "react-router-dom";

const NewOrderBtn: React.FC = () => (
  <Link
    to="/point_of_sale"
    className="d-none d-lg-block btn btn-circle btn-raised btn-danger btn_goToPos btn-lg"
  >
    <i className="fas fa-plus"></i>
  </Link>
);

export default NewOrderBtn;
