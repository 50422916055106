import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import { createUploadLink } from "apollo-upload-client";

import PrinterProvider from "./context/printer-context";
import { getToken, onMessageListener, NotificationPayload } from "./firebase";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/font-awesome.css";
import "./css/foo-icons.css";
import "./css/app.css";

import Header from "./components/header";
import NavMob from "./components/nav/NavMob";
import ScrollToTop from "./middleware/scrollTop";
import SideNav from "./components/nav/side_nav";
import { FullPageLoader } from "./middleware/loaders";

//pages
import Orders from "./components/pages/orders";
import PointOfSale from "./components/pages/pos";
import Account from "./components/pages/account";
import Products from "./components/pages/products";
import CashOuts from "./components/pages/cash_cuts";
import Sales from "./components/pages/sales";
import LoginPage from "./components/pages/login";
import SignupPage from "./components/pages/signup";
import LogoutPage from "./components/pages/logout";
import Order from "./components/pages/order";
import DashNav from "./components/pages/dash_navigation";
import Users from "./components/pages/users";
import Branches from "./components/pages/branches";
import WelcomePage from "./components/pages/welcome";
import BranchName from "./components/common/branchname";

import { cache } from "./middleware/cache";
import { User } from "./models/user_model";

const App = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [sideShow, setSideShow] = useState(false);
  const graph_url = process.env.REACT_APP_GRAPH_URL;
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState<NotificationPayload>(
    {} as NotificationPayload
  );
  const userData: User = JSON.parse(localStorage.getItem("user") || "{}");

  const api = new ApolloClient({
    uri: graph_url,
    cache,
    connectToDevTools: process.env.NODE_ENV === "development",
    link: createUploadLink({
      uri: graph_url,
      headers: {
        authorization: localStorage.getItem("token")
          ? `Bearer ${localStorage.getItem("token")}`
          : "",
        "client-version": `${process.env.REACT_APP_CURRENT_GIT_SHA}`,
      },
    }),
    headers: {
      authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
      "client-version": `${process.env.REACT_APP_CURRENT_GIT_SHA}`,
    },
  });

  useEffect(() => {
    if (userData.id) {
      if (userData.privileges === 0) {
        ////delete after Dec 1st 2021
        if (window.location.pathname !== "/logout") {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        setIsAuth(true);
      }
    }
    setTimeout(() => {
      setShowSplash(false);
    }, 1500);
    if ("Notification" in window) {
      getToken(setTokenFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTokenFound) {
      ////save token to DB
      console.log("App.tsx:71 | isTokenFound", isTokenFound);
    }
  }, [isTokenFound]);

  useEffect(() => {
    if (notification.show) {
      switch (notification.type) {
        case "danger":
          toast.error(notification.title);
          break;
        case "success":
          toast.success(notification.title);
          break;
        case "warning":
          toast.warn(notification.title);
          break;
        case "info":
          toast.info(notification.title);
          break;
        default:
          toast(notification.title);
          break;
      }
    }
  }, [notification]);

  const showSide = () => {
    setSideShow(!sideShow);
  };

  if ("Notification" in window) {
    onMessageListener()
      .then((payload: any) => {
        const notification_data: NotificationPayload = payload.notification;
        setNotification({
          show: true,
          title: notification_data.title,
          body: notification_data.body,
          type: notification_data.type,
          icon: notification_data.icon,
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
  }

  const layout = () => {
    if (isAuth === false) {
      return (
        <React.Fragment>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />
            <Redirect to="/login" />
          </Switch>
        </React.Fragment>
      );
    } else if (isAuth) {
      return (
        <React.Fragment>
          <main>
            <ToastContainer />
            <div className="row main_container">
              <div
                className={`col-lg-2 ${sideShow ? "" : "hidden"}`}
                id="sideNavCont"
              >
                <SideNav active={sideShow} showside={showSide} />
              </div>
              <div className={`col-lg-${sideShow ? 10 : 12}`}>
                <Header sideShow={sideShow} />
                <Switch>
                  <Route exact path="/" component={Orders} />
                  <Route
                    path="/point_of_sale/:order_id?"
                    component={PointOfSale}
                  />
                  <Route path="/account" component={Account} />
                  <Route path="/products" component={Products} />
                  <Route path="/cash_outs_panel" component={CashOuts} />
                  <Route path="/sales/:section?" component={Sales} />
                  <Route path="/users" component={Users} />
                  <Route path="/branches" component={Branches} />
                  <Route path="/order/:order_id" component={Order} />
                  <Route path="/dash_navigation" component={DashNav} />
                  <Route path="/logout" component={LogoutPage} />
                  <Route path="/welcome" component={WelcomePage} />
                  <Redirect to="/" />
                </Switch>
              </div>
            </div>
          </main>
          <NavMob />
          {userData.branch ? <BranchName branch={userData.branch} /> : null}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };
  if (showSplash) {
    return <FullPageLoader show />;
  }
  return (
    <BrowserRouter>
      <ScrollToTop>
        {
          // @ts-ignore
          <ApolloProvider client={api}>
            <PrinterProvider>{layout()}</PrinterProvider>
          </ApolloProvider>
        }
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
