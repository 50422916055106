import { gql } from "@apollo/client";

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($data: ProductInput!) {
    updateProduct(data: $data) {
      id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($data: ProductInput!) {
    createProduct(data: $data) {
      id
    }
  }
`;
export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($product_id: ID!) {
    deleteProduct(product_id: $product_id)
  }
`;

export const SEARCH_CATEGORIES = gql`
  query SearchCategories($search: String!) {
    searchCategories(search: $search) {
      name
      id
    }
  }
`;

export const SEARCH_INGREDIENTS = gql`
  query SearchIngredients($search: String!) {
    searchIngredients(search: $search) {
      id
      name
    }
  }
`;

export const SAVE_INGREDIENT = gql`
  mutation saveNewIngredient($ingredient: String!, $lang: LangInput) {
    saveIngredient(ingredient: $ingredient, lang: $lang) {
      id
      name
    }
  }
`;

export const SEARCH_SIZES = gql`
  query SearchSizes($search: String!) {
    searchSizes(search: $search) {
      id
      name
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: Int!) {
    getProduct(id: $id) {
      id
      ind_id
      status
      status_web
      category {
        id
        name
      }
      model
      price
      title
      description
      snoozed
      zone_id
      images {
        url
      }
      ingredients {
        id
        name
      }
      sizes {
        id
        name
        price
        dbId
      }
      options {
        id
        price_mod
        ingredient {
          id
          name
        }
      }
      has_size
      options {
        id
        ingredient {
          id
          name
        }
        price_mod
      }
      has_option
    }
  }
`;

export const GET_PRODUCT_COUNT = gql`
  query GetProductCount {
    getProductCount
  }
`;

export const GET_PRODUCT_LIST = gql`
  query GetProductsPaged($take: Int!, $skip: Int!) {
    getProducts(take: $take, skip: $skip) {
      id
      ind_id
      status
      status_web
      category {
        id
        name
      }
      price
      title
      snoozed
      zone_id
      images {
        url
      }
      sizes {
        id
        name
        price
      }
      has_size
    }
  }
`;

export const PRODUCT_IMAGE_UPLOAD = gql`
  mutation ProductImageUpload($file: Upload!, $productId: Int!) {
    productImageUpload(file: $file, productId: $productId) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

export const GET_ZONES = gql`
  query GetZones {
    getZones {
      id
      name
    }
  }
`;

export const CREATE_ZONE = gql`
  mutation CreateZone($name: String!) {
    createZone(name: $name) {
      id
      name
    }
  }
`;

export const SET_PRODUCT_AVAILABILITY = gql`
  mutation SetProductAvailability($data: AvailabilityInput!) {
    setProductAvailability(data: $data) {
      id
      snoozed
      status
      status_web
    }
  }
`;
