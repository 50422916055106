import React from "react";
import { Link } from "react-router-dom";

type CardProps = {
  link: string;
  color: string;
  icon: string;
  title: string;
  text: string;
};

export const CardButton: React.FC<CardProps> = ({
  link,
  color,
  icon,
  title,
  text,
}) => (
  <div className="card full_link_parent">
    <Link to={link} className="full_link"></Link>
    <div className={`icon_img ${color}`}>
      <i className={`${icon} fa-4x `}></i>
    </div>
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <p className="card-text">{text}</p>
    </div>
  </div>
);

type IconProps = {
  icon?: string;
  text?: string;
  id?: string;
  checked?: string;
  clickFun?: any;
  classes?: string;
};

export const IconButton: React.FC<IconProps> = ({
  icon,
  text,
  checked,
  clickFun,
  id,
  classes,
}) => (
  <button
    type="button"
    id={id}
    className={`btn btn-block border-0 text-left pl-0 text-capitalize ${classes}`}
    onClick={clickFun}
  >
    <span className="fa-stack fa-1x mr-1">
      <i className="far fa-square fa-stack-2x text_dark_gray"></i>
      <i
        className={`fab fas ${checked && "fa-check"} text-success fa-stack-1x`}
      ></i>
    </span>
    <span className="btn_txt">
      {" "}
      {icon && <i className={`fa-fw ${icon} mr-1`}></i>}
      {text}
    </span>
  </button>
);
