import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import { DELETE_SUBUSER, GET_SUBUSERS, RESEND_INVITE } from "./queries";
import { LoaderDots } from "../../../middleware/loaders";
import { Link } from "react-router-dom";
import { HeaderTitle } from "../common/titles";

const btn = {
  txt: "Invitar Usuario",
  icon: "fa fa-plus",
  link: "/users/invite"
};

type SubuserType = {
  id: number,
  subuser_id: number,
  name: string,
  email: string,
  lastname: string,
  role: string,
  privileges: number,
  status: string
};

type QuerySubusers = {
  getAllSubusers: SubuserType[]
}

type RowProps = {
  user: SubuserType
};

const UserRow: React.FC<RowProps> = ( { user }) => {
  const [resendInvite, { loading: sendingInvite }] = useMutation(RESEND_INVITE);
  const [deleteSubuser, { loading }] = useMutation(DELETE_SUBUSER, {
    refetchQueries: [
      { query: GET_SUBUSERS }
    ]
  });

  const callDelete = () => {
    deleteSubuser({ variables: { id: +user.id } });
  }

  return (
    <div className="list-group list-group-flush">
      <div className="list-group-item list-group-item-action flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between">
          <div>
            {user.status === "active" && <h5 className="mb-0">{`${user.name} ${user.lastname}`}</h5>}
            <p className="mb-1 my-0">{`${user.email} | ${user.role}`}</p>
          </div>
          {user.status === 'active' ? (
            <div>
              <Link
                to={`/users/edit/${user.subuser_id}`}
                className="btn btn-secondary">
                Editar
              </Link>
              <button
                disabled={loading}
                onClick={callDelete}
                className="btn btn-outline-secondary"
                type="button">
                  {loading ? 'Quitando accesso' : 'Quitar accceso'}
              </button>
            </div>)
            : (
            <div>
              <button
                disabled={sendingInvite}
                onClick={() => resendInvite({ variables: { id: user.subuser_id }})}
                className="btn btn-secondary">
                {sendingInvite ? 'Enviando' : 'Volver a enviar'}
              </button>
              <button
                disabled={loading}
                onClick={callDelete}
                className="btn btn-outline-secondary"
                type="button">
                  {loading ? 'Cancelando' : 'Cancelar'}
              </button>
            </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const UserList: React.FC = () => {
  const { loading, data: usersList } = useQuery<QuerySubusers>(GET_SUBUSERS, {
    fetchPolicy: 'cache-and-network'
  });

  if (loading || !usersList) {
    return <LoaderDots fullSize={true} />
  }

  return (
    <div className="row">
      <div className="col-12">
        <HeaderTitle title="Usuarios" btn={btn} />
      </div>
      <div className="col">
        <div className="col mt-5">
          <h3>Usuarios Activos</h3>
          {usersList.getAllSubusers.filter(user => user.status === "active").map(user => (
            <UserRow user={user} key={`user-${user.subuser_id}`}/>
          ))}
        </div> 
        {usersList.getAllSubusers.filter(user => user.status !== "active").length > 0 && (
          <div className="col mt-5">
            <h3>Invitaciones Pendientes</h3>
            {usersList.getAllSubusers.filter(user => user.status !== "active").map(user => (
              <UserRow user={user} key={`user-${user.subuser_id}`}/>
            ))}
          </div>
        )}
     </div>
    </div>
  );
};

export default UserList;