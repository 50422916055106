import React from "react";
import { NavLink } from "react-router-dom";

const SalesNav: React.FC = () => (
  <nav className="navbar navbar-expand navbar-light bg-light py-3 ">
    <NavLink className="navbar-brand sm_hidden" to="/sales">
      Ventas
    </NavLink>
    <div className="">
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className="nav-link " to="/sales">
            Resumen
          </NavLink>
        </li>
        <li className="nav-item ">
          <NavLink className="nav-link " to="/sales/orders">
            Órdenes
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/sales/products">
            Productos
          </NavLink>
        </li>
      </ul>
    </div>
  </nav>
);

export default SalesNav;
