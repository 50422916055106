import React from "react";
//@ts-ignore next line
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { lang } from "../../middleware/lang";
import { CashoutInput, CashoutMovesTypes } from "../../models/cashouts";

type CashoutsModalProps = {
  open: boolean;
  edit: boolean;
  cashoutIpunt: CashoutInput;
  toggle: () => void;
  className?: string;
  type?: CashoutMovesTypes;
  handeSave?: (ev: any) => void;
  handeType?: (ev: any) => void;
  handeDelete?: (ev: any) => void;
};

export const CashoutsModal: React.FC<CashoutsModalProps> = ({
  open,
  toggle,
  className,
  type = "in",
  handeSave,
  handeType,
  handeDelete,
  cashoutIpunt,
  edit,
}) => {
  const texts = renderMessages(type, edit);
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={className}
      autoFocus={false}
    >
      <ModalHeader toggle={toggle}>{texts.title}</ModalHeader>
      <ModalBody>
        <div className="cashout_data ">
          <div className="row align-content-center flex-column align-items-center ">
            <div className="col-md-8">
              <small className="text-danger display_none" id="cash_error_msg">
                Seleccione el tipo de movimiento
              </small>
              {type !== "closed" && type !== "started" ? (
                <div className="form-group my-2">
                  <label htmlFor="concept_txt">Concepto</label>
                  <input
                    onChange={handeType}
                    type="text"
                    data-type="concept"
                    className="form-control"
                    id="concept_txt"
                    value={cashoutIpunt.concept}
                    placeholder="Pago de Luz"
                    autoFocus
                  />
                </div>
              ) : null}
              <label htmlFor="cash_amount">Monto</label>
              <div className="input-group">
                <input
                  type="tel"
                  onChange={handeType}
                  data-type="amount"
                  value={cashoutIpunt.amount}
                  id="cash_amount"
                  className="form-control no_focus"
                  placeholder="Efectivo"
                  aria-label="Ingreso"
                  aria-describedby="button-addon2"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={handeSave} className={`btn ${texts.class} btn-block`}>
          {texts.btn}
        </button>
        {edit ? (
          <button
            onClick={handeDelete}
            className={`btn text-danger mt-4 btn-block`}
          >
            {lang("Borrar Movimiento", "Delete")}
          </button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

function renderMessages(type: CashoutMovesTypes, edit: boolean) {
  const action = edit ? lang("Editar ", "Edit ") : lang("Capturar ", "Add ");
  switch (type) {
    case "in":
      return {
        title: lang(`${action} Entrada`, `${action} Cash Entry`),
        btn: lang("Guardar Entrada", "Save"),
        class: "btn-success",
      };
    case "out":
      return {
        title: lang(`${action} Salida`, `${action} Cash Out`),
        btn: lang("Guardar Salida", "Save"),
        class: "btn-danger",
      };
    case "closed":
      return {
        title: lang("Hacer Corte de Caja", "Make Cash Out"),
        btn: lang("Guardar Corte de Caja", "Save"),
        class: "btn-danger",
      };
    case "started":
      return {
        title: lang("Inicio de Turno", "Begin Turn"),
        btn: lang("Empezar Turno", "Save"),
        class: "btn-primary",
      };
    default:
      return {
        title: lang(`${action} Entrada`, `${action} Cash Entry`),
        btn: lang("Guardar", "Save"),
        class: "btn-success",
      };
  }
}
