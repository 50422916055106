export type InputSize = {
  id?: string;
  name: string;
  price: number;
  dbId?: number;
};

export type InputIngredient = {
  id?: string;
  name: string;
};

export type InputOption = {
  id?: string;
  price_mod: number,
  ingredient: InputIngredient
};

export type InputCategory = {
  id?: string;
  name: string;
}

export interface ProductInput {
  title: string;
  model: string;
  price: number;
  description: string;
  category: InputCategory;
  zone_id: number;
  sizes: InputSize[];
  options: InputOption[];
  has_option: number;
  ingredients: InputIngredient[];
  snoozed: boolean;
  status: boolean;
  status_web: boolean;
}

export const initialProductState: ProductInput = {
  price: 0,
  title: '',
  model: '',
  description: '',
  category: { name: '' },
  zone_id: 0,
  has_option: 0,
  ingredients: [],
  options: [],
  sizes: [],
  snoozed: true,
  status: false,
  status_web: false,
};
