import firebase from "firebase/app";
import "firebase/messaging";

export type NotificationPayload = {
  show: boolean;
  title: string;
  body: string;
  type: "danger" | "success" | "warning" | "info";
  icon: string | null | undefined;
};

const firebaseConfig = {
  apiKey: "AIzaSyBMJPiLy7JpPvW7gBv3K4izEJGG4PhIHp0",
  authDomain: "foo-app-4eb99.firebaseapp.com",
  databaseURL: "https://foo-app-4eb99.firebaseio.com",
  projectId: "foo-app-4eb99",
  storageBucket: "foo-app-4eb99.appspot.com",
  messagingSenderId: "537954245401",
  appId: "1:537954245401:web:67ecdd887200660bfc4388",
};

firebase.initializeApp(firebaseConfig);

const messaging = "Notification" in window ? firebase.messaging() : false;

export const getToken = async (setTokenFound: (set: boolean) => void) => {
  if (!messaging) return;
  try {
    const currentToken = await messaging.getToken({
      vapidKey:
        "BKStKaTwWJ3IFpZwFX6mSAQyEFumHjo9kcg1T_pm808lMFavWvVd1Sx4eFsoy7GVZPAfZ4C6TAMPvyCaz4OCudg",
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);

      localStorage.setItem("ntt", currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
      // shows on the UI that permission is required
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (messaging && "Notification" in window) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    } else {
      reject("No Notification available on device");
    }
  });
