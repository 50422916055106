import React from "react";
import useSettings from "../../../../hooks/settings";
import { Settings } from "../../../../models/settings_model";
import { IconButton } from "../../common/buttons";

const Sales: React.FC = () => {
  const [settings, saveSettings] = useSettings();

  const updateSetting = (ev: { currentTarget: HTMLButtonElement }) => {
    const setting_type = ev.currentTarget.id as keyof Settings;
    const checked = !settings[setting_type];
    saveSettings(setting_type, checked);
  };
  return (
    <div className="row justify-content-center mt-3">
      <div className="col-md-7">
        <div className="card">
          <div className="card-body ">
            <h3 className="">Reportes de Ventas</h3>
            <p>
              Tu sistema en Foo para Empresas te puede ayudar a saber de mejor
              manera cuánto estás ganando realmente con el uso de servicios
              adicionales.
            </p>
            <div className="form-group row mx-auto">
              <label
                htmlFor="input_terminal"
                className="col-sm-3 col-htmlForm-label"
              >
                Terminal
              </label>
              <div className="col-sm-3">
                <input
                  type="tel"
                  className="form-control service_val"
                  id="input_terminal"
                  data-service="terminal"
                  placeholder="comisión"
                  value="3.60"
                />
                <span className="feedback_input text-muted">%</span>
              </div>
            </div>
            <button type="button" className="btn btn-success btn-block mt-4">
              Guardar
            </button>
          </div>{" "}
        </div>
      </div>
      <div className="col">
        <h3 className="">Otras Opciones</h3>
        <IconButton
          clickFun={updateSetting}
          text="Marcar pedido listo al cobrar"
          classes="btn-sm"
          checked={settings.avoid_fulfilled === true ? undefined : "true"}
          id="avoid_fulfilled"
        />
        <IconButton
          clickFun={updateSetting}
          text="Cobros a un click"
          classes="btn-sm"
          checked={settings.quick_charge === true ? undefined : "true"}
          id="quick_charge"
        />
        <IconButton
          clickFun={updateSetting}
          text="Mostar tipo de tarjeta al cobrar"
          classes="btn-sm"
          checked={settings.cards_type === true ? "true" : undefined}
          id="cards_type"
        />
      </div>
    </div>
  );
};

export default Sales;
