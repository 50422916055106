import React, { useEffect, useState } from "react";
import useSettings from "../../../../../hooks/settings";
import { LoaderDots } from "../../../../../middleware/loaders";
import { Branch } from "../../../../../models/partner_model";
import { User } from "../../../../../models/user_model";
import { useQuery, useReactiveVar } from "@apollo/client";
import { activeBranchVar } from "../../../../../middleware/cache";
import foo_logo from "../../../../assets/mascot_user.svg";
import { GET_BRANCHES, GET_PARTNER } from "../../queries";
import SocialInput from "./SocialInput";

const Account: React.FC = () => {
  // eslint-disable-next-line
  const [_, saveSettings] = useSettings();
  const activeBranch = useReactiveVar(activeBranchVar);
  const [branches, setBranches] = useState<Branch[]>([]);
  const { data } = useQuery(GET_BRANCHES);
  const { data: partnerData, loading } = useQuery(GET_PARTNER);
  const [description, setDescription] = useState("");
  const [about, setAbout] = useState("");

  useEffect(() => {
    if (data) {
      setBranches(data.getBranches);
    }
  }, [data]);

  useEffect(() => {
    if (partnerData) {
      setAbout(partnerData.getPartner.about);
      setDescription(partnerData.getPartner.short_desc);
    }
  }, [partnerData]);

  const changeBranch = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const branch_id = e.target.value;
    saveSettings("active_branch", branch_id);
    const selected_branch: Branch | undefined = branches.find(
      (b) => b.id === branch_id
    );
    if (!selected_branch) {
      return;
    }
    const set_user: User = JSON.parse(localStorage.getItem("user") || "{}");
    set_user.branch = selected_branch;
    localStorage.setItem("user", JSON.stringify(set_user));
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  if (loading || !partnerData) {
    return <LoaderDots />;
  }

  return (
    <div className="row justify-content-center mt-3">
      <div className="col">
        <div className="card mb-3">
          <div className="card-body ">
            <h3 className="">Sucursal Activa</h3>
            <div className="form-group">
              <select
                value={activeBranch}
                onChange={changeBranch}
                className="form-control border-0 active_branches"
              >
                {branches.map((branch) => (
                  <option value={branch.id} key={`branch.id_${branch.id}`}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form method="post">
              <h3 className="">Acerca de Demburger</h3>
              <p className="small">
                Esta información se muestra en{" "}
                <a
                  href="http://http:demburger.fooapp.mx.fooapp.mx"
                  target="_blank"
                  rel="noreferrer"
                >
                  la página de tu negocio
                </a>
              </p>
              <div className="form-group">
                <label htmlFor="short_desc">Descripción Corta</label>
                <textarea
                  className="form-control"
                  name="short_desc"
                  id="short_desc"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  rows={3}
                />
              </div>
              <div className="form-group">
                <label htmlFor="full_desc">Descripción Larga</label>
                <textarea
                  className="form-control"
                  name="full_desc"
                  id="full_desc"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <button className="btn btn-block btn-success">Guardar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="col mt-3 mt-md-0">
        <div className="card">
          <div className="card-body ">
            <h3 className="">Links y Redes Sociales</h3>
            <SocialInput
              name="foo"
              logo={
                <img
                  src={foo_logo}
                  style={{ height: "45px", marginRight: "5px" }}
                  alt="foologo"
                />
              }
              value={partnerData.getPartner.url_name}
              link={`http://${partnerData.getPartner.url_name}.fooapp.mx`}
            />
            <SocialInput
              name="facebook"
              logo={
                <i className="fab fa-facebook-square facebook_color fa-2x mr-2 fa-fw"></i>
              }
              value={partnerData.getPartner.fb_page}
              link={`https://facebook.com${partnerData.getPartner.fb_page}`}
              linkName={`@${partnerData.getPartner.fb_page}`}
            />
            <SocialInput
              name="instagram"
              logo={
                <i className="fab fa-instagram instagram_color fa-2x mr-2 fa-fw"></i>
              }
              value={partnerData.getPartner.instagram}
              link={`https://instagram.com${partnerData.getPartner.instagram}`}
              linkName={`@${partnerData.getPartner.instagram}`}
            />
            <SocialInput
              name="web"
              link={`${partnerData.getPartner.webpage}`}
              value={partnerData.getPartner.webpage}
              logo={
                <i className="fas fa-laptop web_color fa-2x mr-2 fa-fw"></i>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
