import React from "react";

import Head from "./head";
import OrderSummary from "./orderSummary";
import OrderActions from "./orderActions";

const Order = ({
  order,
  loading,
  productAsDone,
  payOut,
  updateOrder,
  print,
  changeTable,
  saveUser,
}) => (
  <div className="container">
    <Head order_id={order.id} status={order.status} />
    <div className="row">
      <OrderSummary
        loading={loading}
        order={order}
        productAsDone={productAsDone}
        changeTable={changeTable}
        saveUser={saveUser}
      />
      <OrderActions
        order={order}
        payOut={payOut}
        updateOrder={updateOrder}
        print={print}
      />
    </div>
  </div>
);

export default Order;
