export function groupProducts(products) {
  const return_products = {};

  products.forEach((order_product) => {
    const product = order_product.product;
    const arrapy_product = {
      id: order_product.id,
      status: order_product.status,
      price: order_product.price,
      ingredients_txt: order_product.ingredients_txt,
      comments: order_product.comments,
      qty: order_product.qty,
      qty_done: order_product.qty_done,
    };
    if (return_products[product.id]) {
      return_products[product.id].products.push(arrapy_product);
      return;
    }
    return_products[product.id] = {
      id: product.id,
      title: product.title,
      img: product.images[0] ? product.images[0].url.replace("ftp-foo.s3.amazonaws.com", "cdn.foopos.mx").replace("s3.amazonaws.com/ftp-foo", "cdn.foopos.mx") : null,
      products: [arrapy_product],
    };
    return;
  });
  ///return object as array
  return Object.values(return_products);
}

export function hasIngredient(ingredients, ing_id, return_index = false) {
  if (return_index) {
    return ingredients.findIndex((i) => i === ing_id);
  }
  return ingredients.findIndex((i) => i === ing_id) >= 0;
}
