import { gql } from "@apollo/client";
export const GETTABLES = gql`
  query getTables($branch: Int) {
    getTables(branch: $branch) {
      id
      branch_id
      partner_id
      is_active
      name
      order_id
    }
  }
`;

export const GETORDERS = gql`
  query getOrders($branch_id: Int, $limit: Int, $active: Boolean) {
    getOrders(branch_id: $branch_id, take: $limit, active: $active) {
      id
      type
      status
      status_color
      total
      creation
      creation_date
      modification
      resume_txt
      delivery_type
      payed
      payment_type
      user {
        name
      }
      table {
        id
        is_active
        name
      }
      branch {
        id
        name
      }
    }
  }
`;

export const GETORDERSPRODUCTS = gql`
  query getOrders($branch_id: Int) {
    getOrders(branch_id: $branch_id, active: true) {
      id
      type
      status
      status_color
      total
      creation
      creation_date
      modification
      resume_txt
      delivery_type
      payed
      payment_type
      user {
        name
      }
      table {
        id
        is_active
        name
      }
      branch {
        id
        name
      }
      products {
        id
        status
        size
        # ingredients
        qty
        qty_done
        ingredients_txt
        ingredients_opt
        name
        comments
        product {
          title
          category {
            singular
          }
          ingredients {
            id
          }
        }
      }
    }
  }
`;

export const GETPRODUCTS = gql`
  query getMenu {
    getProducts(status: 1) {
      id
      ind_id
      status
      status_web
      category {
        id
        name
      }
      price
      title
      snoozed
      zone_id
      images {
        url
      }
      ingredients {
        id
        name
        color
        icon
      }
      sizes {
        id
        name
        price
      }
      has_option
      has_size
      options {
        id
        id_ingredient
        price_mod
        ingredient {
          id
          name
          icon
          color
        }
      }
    }
    getCategories {
      id
      name
    }
  }
`;
