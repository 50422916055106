import React from "react";

import { lang } from "../../../middleware/lang";

type Props = {
  disabled: boolean;
  show: boolean;
  promptOrder: () => void;
  total: string;
};

const BottomCta: React.FC<Props> = (props) => {
  const buttonClasses = [
    "btn",
    "btn-raised",
    "btn-danger",
    "btn_lg",
    "btn-block",
    "ripple-effect",
    "btn_xs_lg",
  ];
  if (props.disabled) {
    buttonClasses.push("disabled", "disabled_danger");
  }
  if (props.show === false) {
    return null;
  }
  return (
    <div className="row p-3 px-sm-0 m-0 fixed_innav_bottom_mov_partner material-shadows_mov animate_all bottom d-md-none">
      <div className="col">
        <h3>
          Total: $<span className="order_totals">{props.total}</span>
        </h3>{" "}
        <button type="button" className="btn btn-link">
          <i className="far fa-edit"></i>
        </button>
      </div>
      <div className="col px-0 px-sm-2">
        <button
          type="button"
          className={buttonClasses.join(" ")}
          disabled={props.disabled}
          onClick={props.promptOrder}
        >
          {lang("Siguiente", "Next")}{" "}
          <i className="ml-3 fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default BottomCta;
