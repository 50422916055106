import React from "react";
import mascot from "../../../assets/mascot_user.svg";
import Calculator from "./calculator";
import { formatNumber } from "../../../../middleware/common-functions";
import { OrderType } from "../../../../models/order_model";
import { CalculatorItem } from "../../../../models/calculator_model";

type PayOutProps = {
  order: OrderType;
  addUp: any;
  calculator: CalculatorItem;
  payOutBack: any;
  payOrder: any;
  shake: boolean;
  setPaymentType: any;
};

const PayOut: React.FC<PayOutProps> = ({
  order,
  addUp,
  calculator,
  payOutBack,
  payOrder,
  shake,
  setPaymentType,
}) => (
  <div className="container-fluid py-3">
    <div className="row justify-content-center charge_div">
      <div className="col-md-6 col-xl-5 p-1">
        <div className="media text-muted" id="client_div_1">
          <img
            alt="32x32"
            className="mr-2 rounded"
            style={{ width: "32px", height: "32px" }}
            src={mascot}
            data-holder-rendered="true"
          />
          <p className="media-body m-0 small lh-125 ">
            <strong className="d-block text-gray-dark"></strong>
            <i className={order.delivery_type_icon}></i>
            {order.delivery_type_text}
          </p>
          <h5 className="fa-2x text_blue">
            Total: <b>${formatNumber(order.total)}</b>
          </h5>
          <p></p>
        </div>

        <hr />

        <h6 className="fa-1x text-center">Monto </h6>
        <h5 className="my-3 fa-3x text-center">
          $<span className="cash">{calculator.amount || 0}</span>
        </h5>
        {calculator.cash_diff ? (
          <h6
            className={`text-right ${
              calculator.cash_diff > 0 ? "text-muted" : "text-success"
            } ${shake ? "shake_anim" : ""}`}
          >
            <span id="cash_left_txt">
              {calculator.cash_diff > 0 ? "Restante" : "Cambio"}
            </span>
            : $
            <span className="cash_left">
              {formatNumber(
                calculator.cash_diff > 0
                  ? calculator.cash_diff
                  : -calculator.cash_diff
              )}
            </span>
          </h6>
        ) : null}
        <div className="alert alert-warning text-center d-none" role="alert">
          <h4 className="alert-heading">
            <i className="fas fa-exclamation"></i> Efectivo Insuficiente!
          </h4>
        </div>
      </div>
      <div className="col-md-4 p-1">
        <h5>Movimientos </h5>
      </div>
    </div>
    <Calculator
      addUp={addUp}
      payOutBack={payOutBack}
      payOrder={payOrder}
      type={order.payment_type}
      card_type={order.card_type}
      setPaymentType={setPaymentType}
      ready={
        calculator.amount
          ? (!calculator.cash_diff && calculator.cash_diff !== 0) ||
            calculator.cash_diff > 0
            ? false
            : true
          : true
      }
      charge_total={calculator.amount ? false : true}
    />
  </div>
);

export default PayOut;
