import React from "react";
import { Redirect, Route } from "react-router-dom";

import Nav from "../nav";
import Delivery from "./delivery";
import Tickets from "./tickets";
import Account from "./account";
import Sales from "./sales";
import Rappi from "./rappi";

const main_nav = [
  { name: { es: "Cuenta", en: "Overview" }, link: "/settings/account" },
  {
    name: { es: "Envíos a Domicilio", en: "Delivery" },
    link: "/settings/delivery",
  },
  {
    name: { es: "Mesas", en: "Tables" },
    link: "/settings/tables",
  },
  {
    name: { es: "Tickets", en: "Tickets" },
    link: "/settings/tickets",
  },
  {
    name: { es: "Ventas", en: "Sales" },
    link: "/settings/sales",
  },
  {
    name: { es: "Rappi", en: "Rappi" },
    link: "/settings/rappi",
  },
];

const Pref: React.FC = () => {
  return (
    <div className="pref_section">
      <Nav items={main_nav} />
      <Route path="/account/settings" exact={true}>
        <Redirect to="/account/settings/account" />
      </Route>
      <Route path="/account/settings/tickets" component={Tickets} />
      <Route path="/account/settings/delivery" component={Delivery} />
      <Route path="/account/settings/account" component={Account} />
      <Route path="/account/settings/sales" component={Sales} />
      <Route path="/account/settings/rappi" component={Rappi} />
    </div>
  );
};

export default Pref;
