import React from "react";
import Switch from "react-switch";
import useSettings from "../../../../hooks/settings";
import { Settings } from "../../../../models/settings_model";

const Delivery: React.FC = () => {
  const [settings, saveSettings] = useSettings();

  const updateSetting = (
    checked: boolean | string,
    setting_type: keyof Settings
  ) => {
    saveSettings(setting_type, checked);
  };

  return (
    <div className="row justify-content-start mt-3">
      <div className="col col-md-6">
        <div className="card mb-2">
          <div className="card-body ">
            <h4 className="">Envíos a domicilio</h4>
            <div className="form-group p_relative">
              <label htmlFor="product_categories ">
                <small className="text-muted">
                  Deja que tus clientes sepan si haces envíos a domicilio desde
                  Foo
                </small>
                <div className="ml-2 row justify-content-start align-items-center my-2">
                  <Switch
                    checked={settings.disable_delivery ? false : true}
                    onChange={(checked) =>
                      updateSetting(!checked, "disable_delivery")
                    }
                  />
                  <div className="ml-1">
                    {settings.disable_delivery
                      ? "No hago envíos a domicilio"
                      : "Hago envíos a domicilio"}
                  </div>
                </div>
              </label>
            </div>
            <div id="shipping_btn" className="mb-3">
              <h4 className="">Opciones</h4>
              <div className="form-group p_relative">
                <label htmlFor="product_categories ">
                  <small className="text-muted">
                    Las direcciones y costos de envíos son calculados con el
                    apoyo de Google Maps
                  </small>
                  <div className="ml-2 row justify-content-start align-items-center my-2">
                    <Switch
                      checked={settings.google_maps ? true : false}
                      onChange={(checked) =>
                        updateSetting(checked, "google_maps")
                      }
                    />
                    <div className="ml-1">
                      Apoyo de Google Maps para direcciones
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!settings.disable_delivery && (
        <div className="col">
          <div className="card mb-2 " id="shipping_costs">
            <div className="card-body">
              <h4>Costos de Envío</h4>
              <p>
                Puedes agregar tus propios costos de envío dentro de Foo en caso
                de que así los manejes.
              </p>
              <p>
                Los costos de envío se calculan dentro de la aplicación por
                distancia, así que debes cargar dentro de tu panel de
                preferencias los costos por rangos en distancia.
              </p>
            </div>
            <div id="delivery_costs">
              <div className="form-row ">
                <div className="col-5">
                  <label htmlFor="inputEmail4" className="text-muted">
                    Costo
                  </label>
                  <input
                    name="price"
                    type="text"
                    className="form-control"
                    placeholder="$25"
                    id="fist_price"
                    value="40.00"
                  />
                </div>
                <div className="col-5">
                  <label htmlFor="inputEmail4" className="text-muted">
                    Hasta
                  </label>
                  <input
                    name="distance"
                    type="text"
                    className="form-control"
                    placeholder="10 km"
                    value="3"
                  />
                </div>
                <div className="col-2">
                  <label htmlFor="inputEmail4" className="text-white">
                    add
                  </label>
                  <button
                    type="button"
                    className="btn p-0 py-1 m-0 display_none"
                  >
                    <i className="fas fa-plus-square fa-2x text-danger"></i>
                  </button>
                  <button type="button" className="btn p-0 py-1 m-0 ">
                    <i className="far fa-minus-square fa-2x text-muted"></i>
                  </button>
                </div>
              </div>
            </div>
            <button type="button" className="btn btn-success btn-block mt-4">
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Delivery;
