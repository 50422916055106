import React from "react";

import { CashoutsType } from "../../../models/cashouts";
import { formatNumber } from "../../../middleware/common-functions";

const SalesResume: React.FC<{ cashouts: CashoutsType }> = ({ cashouts }) => (
  <div className=" pt-3 border rounded justify-content-center">
    <h1
      className={`display-5 text-center ${
        cashouts.total < 0 ? "text-danger" : ""
      }`}
    >
      <b id="range_totals">${formatNumber(cashouts.total)}</b>
    </h1>
    <h5 className="text-center">Dinero en Caja</h5>
    <table className="table">
      <tbody>
        <tr>
          <th>Entradas</th>
          <td>
            <b id="range_cash_in_pos">${formatNumber(cashouts.in)}</b>
          </td>
        </tr>
        <tr>
          <th>Ventas en Efectivo</th>
          <td>
            <b id="range_totals_pos">${formatNumber(cashouts.sales_cash)}</b>
          </td>
        </tr>
        <tr>
          <th>Ventas con Terminal</th>
          <td>
            <b id="range_totals_pos">
              ${formatNumber(cashouts.sales_terminal)}
            </b>
          </td>
        </tr>
        <tr>
          <th>Salidas</th>
          <td>
            <b className="text-danger" id="range_totals_online">
              {cashouts.out > 0 ? "-" : ""}${formatNumber(cashouts.out)}
            </b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default SalesResume;
