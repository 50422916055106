import React from "react";
import { Link } from "react-router-dom";

import NoOrders from "./no_orders";
import { lang } from "../../../middleware/lang";
import { statusText, deliveryText } from "../../../lang/orders";
import { OrderType } from "../../../models/order_model";
import { toDate } from "../../../middleware/dates";

type Props = {
  hasOrders: boolean;
  orders: {
    active: OrderType[];
    past: OrderType[];
  };
};

const OrderListMob: React.FC<Props> = ({ orders, hasOrders }) => (
  <div className="list-group d-md-none" id="order_list_mob">
    {hasOrders ? (
      orders.active.map((item: OrderType) => (
        <List key={`mob_${item.id}`} item={item} />
      ))
    ) : (
      <NoOrders type="" />
    )}
    <hr className="hr_divider" />
    <h1 className="my-2 mt-5 my-lg-4 show_on_new  text-center text-md-left">
      {lang("Órdenes Anteriores", "Past Orders")}
    </h1>
    {orders.past
      ? orders.past.map((item) => <List key={`mob_${item.id}`} item={item} />)
      : null}
  </div>
);

type ListProps = {
  item: OrderType;
};

const List: React.FC<ListProps> = ({ item }) => {
  const delivery = deliveryText(item.delivery_type);
  return (
    <Link
      to={`/order/${item.id}`}
      className="list-group-item list-group-item-action flex-column align-items-start "
    >
      <div className="w-100">
        <h5 className="mb-1">
          <small>#{item.id}</small>
          <br />
          {item.resume_txt}
          <br />
          <small>
            <span className={`badge badge-secondary mr-2`}>
              {delivery.txt}{" "}
            </span>
            <span className={`badge badge-${item.status_color}`}>
              {statusText(item.status)}{" "}
            </span>
          </small>
        </h5>
        <small
          style={{
            position: "absolute",
            top: "2px",
            right: "15px",
          }}
        >
          <b className="display_3">${item.total}</b>
        </small>
      </div>
      <small>{toDate(item.creation_date)}</small>
    </Link>
  );
};

export default OrderListMob;
