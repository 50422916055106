import React from "react";

import { toDate } from "../../../middleware/dates";
import { lang } from "../../../middleware/lang";
import { groupProducts } from "../../../middleware/product_functions";
import UserBlock from "./userBlock";

const OrderSummary = ({
  order,
  loading,
  productAsDone,
  changeTable,
  saveUser,
}) => (
  <div className="col-md-8 px-0 mt-2 mt-md-0">
    <div className="row">
      <div className="col-12">
        <div className="material_card p-3 ">
          <div className="col-12 px-0 mb-2">
            <h1 className="mt-3 mb-0">
              {lang("Orden", "Order")} #{order.id}
              <small>
                <Helper
                  delivery={order.delivery_type}
                  table={order.table}
                  changeTable={changeTable}
                />
              </small>
            </h1>
            <div className=" ">
              <h1 className="">
                <small className="text-muted text_small">
                  {toDate(order.creation_date)}{" "}
                </small>
              </h1>
              <span className={`badge badge-${order.status_color}`}>
                {order.status_text}
              </span>{" "}
              {order.payed ? (
                <span className="badge badge-success">
                  <i className="fas fa-check"></i>
                  {lang(" Pagado", " Payed")}
                </span>
              ) : null}
            </div>
            {order.client_comments && (
              <Comments comment={order.client_comments} />
            )}
            {order.user_comments && (
              <Comments comment={order.user_comments} client />
            )}
            <hr />
          </div>

          <h4>{lang("Productos", "Products")}</h4>
          {loading ? null : renderProducts(order.products, productAsDone)}
        </div>
      </div>
      <UserBlock
        payed={order.payed}
        user={order.user}
        saveUser={saveUser}
        client={order.client}
        address={order.address}
        income={parseFloat(order.cash_income)}
        shipping={order.delivery ? parseFloat(order.delivery) : 0}
        payment_type={order.payment_type}
        card_type={order.card_type}
        branch={order.branch}
        delivery={order.delivery_type}
      />
    </div>
  </div>
);

function renderProducts(products, productAsDone) {
  const groupedProducts = groupProducts(products);

  const productsRows = groupedProducts.map((product) => {
    const productsList = product.products.map((item, key) => (
      <ProductLi
        key={`${product.id}_${key}`}
        product={item}
        index={key + 1}
        productAsDone={productAsDone}
      />
    ));
    return (
      <ProductRow key={product.id} product={product} products={productsList} />
    );
  });

  return productsRows;
}

const ProductRow = ({ product, products }) => {
  //random number from 1 to 4
  const random_img = Math.floor(Math.random() * 4) + 1;
  return (
    <div className="row my-2 product_row ">
      <div className="col-sm-3 pr-0 d-none d-sm-block">
        <img
          src={
            product.img ||
            `https://s3.amazonaws.com/ftp-foo/page_assets/no_photo_0${random_img}.jpg`
          }
          className="img-thumbnail "
          alt={product.title}
        />
      </div>

      <div className="col-12 d-sm-none">
        <div
          className="order_img d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${product.img})`,
          }}
        >
          <h2 className="txt_on_img text-center background_text">
            {product.title}
          </h2>
        </div>
      </div>
      <div className="col-sm-9 pl-sm-1">
        <h4 className="ml-2 font-weight-bold">{product.title}</h4>
        <div className="list-group">{products}</div>
      </div>
    </div>
  );
};

const ProductLi = ({ product, productAsDone, index }) => (
  <div
    className={`list-group-item list-group-item-action flex-column product_in_order align-items-start flat-top_mob  ${
      product.status !== "pending" ? "done" : ""
    }`}
    id="product_28243"
  >
    <button
      className="btn btn_full btn_invisible "
      data-product_id={product.id}
      onClick={productAsDone}
    ></button>
    <div className="row">
      <div className="col-9">
        <h5 className="mb-1">
          {" "}
          {index}.- {product.ingredients_txt || lang("Con Todo", "All the Way")}
          {product.qty > 1 ? ` x${product.qty} ` : " "}
          <Checkbox
            qty={product.qty}
            qty_done={product.qty_done}
            status={product.status}
            product_id={product.id}
          />
        </h5>
      </div>

      <div className="col-3 text-right">
        <small>${product.price}</small>
      </div>
      {product.comments && (
        <div className="col-12">
          <hr />
          <p className="">
            <small>Comentarios: </small> <br />
            {product.comments} - <small> </small>{" "}
            <i className="fas fa-comment text-danger" aria-hidden="true"></i>
          </p>
        </div>
      )}
    </div>
  </div>
);

const Helper = ({ delivery, table, changeTable }) => {
  let table_render = null;
  let help_text = {};
  if (table) {
    table_render = (
      <React.Fragment>
        <span className="badge badge-secondary ml-2">
          {lang("Mesa ", "Table ")}
          <span id="table_name">{table.name || `#${table.id}`}</span>
        </span>
        <button className="btn btn-link text-left" onClick={changeTable}>
          <i className="fas fa-edit"></i>
        </button>
      </React.Fragment>
    );
  }

  switch (delivery) {
    case "pickup":
      help_text.txt = "Para Recoger";
      help_text.icon = "fas fa-hand-point-up mr-2";
      break;
    case "delivery":
      help_text.txt = "Envío a Domicilio";
      help_text.icon = "fas fa-motorcycle mr-2";
      break;
    case "other_apps":
      help_text.txt = "Plataformas";
      help_text.icon = "fas fa-mobile-alt mr-2";
      break;

    default:
      help_text.txt = "En Comedor";
      help_text.icon = "far fa-circle";
      break;
  }
  return (
    <React.Fragment>
      <span className="badge badge-light mx-2">
        <i className={help_text.icon}></i> {help_text.txt}
      </span>
      {table_render}
    </React.Fragment>
  );
};

const Checkbox = ({ qty, product_id, qty_done }) => {
  const done_icon = "fa-check-square text-success big_entrance_anm fast";
  const pending_icon = "text-muted fa-square";
  const response = [];
  for (let i = 1; i <= qty; i++) {
    response.push(
      <i
        className={`far mr-1 ${i <= qty_done ? done_icon : pending_icon}`}
        key={`${product_id}_qty_${i}`}
        aria-hidden="true"
      ></i>
    );
  }
  return response;
};

const Comments = ({ comment, client }) => (
  <div className={`comments ${client ? "px-3 py-2 rounded bg-warning" : ""}`}>
    <h4 className="font-black">Comentarios{client ? " del Cliente:" : ":"}</h4>
    <p className={`${client ? "bg-light p-2 rounded m-0" : "my-0 ml-2"}`}>
      {comment}
    </p>
  </div>
);

export default OrderSummary;
