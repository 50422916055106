import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import { useMutation } from "@apollo/client";

import NoOrders from "./no_orders";
import { lang } from "../../../middleware/lang";
import { UPDATEPRODUCT, ALLPRODUCTSDONE } from "../order/queries";

import { statusText, deliveryText } from "../../../lang/orders";
import { OrderProduct, OrderType } from "../../../models/order_model";
import { toDate } from "../../../middleware/dates";

type Props = {
  hasOrders: boolean;
  orders: OrderType[];
  clickFun: (e: any) => void;
  refetchOrders: any;
};

const ProductViews: React.FC<Props> = ({
  orders,
  hasOrders,
  refetchOrders,
}) => {
  const [updateProductMutation] = useMutation(UPDATEPRODUCT);
  const [allProductsDoneMutation] = useMutation(ALLPRODUCTSDONE);

  const productAsDone = async (ev: any) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const order_id = ev.currentTarget.dataset.order_id;
    const set_order = orders.find((item) => item.id === order_id);
    if (!set_order) {
      return;
    }
    if (product_id === "all_products") {
      await allProductsDoneMutation({
        variables: {
          order_id,
        },
      });
      refetchOrders();

      return;
    }
    const product_index = set_order.products.findIndex(
      (p) => p.id === product_id
    );
    let status = set_order.products[product_index].status;
    let new_status;

    switch (status) {
      case "1":
        new_status = "pending";
        break;

      default:
        new_status = "1";
        break;
    }
    const productInput = {
      id: parseInt(product_id),
      status: new_status,
    };
    await updateProductMutation({
      variables: {
        product: productInput,
      },
    });
    refetchOrders();
    const qty_done = set_order.products[product_index].qty_done
      ? set_order.products[product_index].qty_done + 1
      : 1;
    set_order.products[product_index].qty_done = qty_done;
    if (
      set_order.products[product_index].qty ===
      set_order.products[product_index].qty_done
    ) {
      set_order.products[product_index].status = "1";
    }
  };
  if (!hasOrders) {
    return <NoOrders type="products" />;
  }
  return (
    <div className="">
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {orders
          ? orders.map((item, key) => {
              return (
                <OrderCard
                  key={`${key}_${item.id}`}
                  item={item}
                  productAsDone={productAsDone}
                />
              );
            })
          : null}
      </Masonry>
    </div>
  );
};

type OrderCardProps = {
  item: OrderType;
  productAsDone: (e: any) => void;
};

const OrderCard: React.FC<OrderCardProps> = ({ item, productAsDone }) => {
  const delivery = deliveryText(item.delivery_type);
  const start_at = new Date(parseInt(item.creation_date));
  const date_now = new Date();
  const difference_in_time = date_now.getTime() - start_at.getTime();
  const difference_in_sec = difference_in_time / 1000;
  const [timer, setTimer] = useState(difference_in_sec);

  useEffect(() => {
    setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  }, []);

  const active_products = item.products
    ? item.products.filter((p) => p.status === "0")
    : [];

  if (item.status === "idle" || active_products.length === 0) {
    return null;
  }

  return (
    <div className="card material_card mb-3">
      <div className="card-body">
        <p className="card-text mt-0 d-flex justify-content-between ">
          <span>
            <span className="badge  badge-light mr-2">
              <i className={delivery.icon}></i> {delivery.txt}
            </span>
            <span className={`badge badge-${item.status_color}`}>
              {statusText(item.status)}{" "}
            </span>
          </span>
          <small className="text-muted">{toDate(item.creation_date)}</small>
        </p>
        <h1
          className={`w-100 text-center display-5 ${
            timer > 600 ? "text-danger font-weight-bolder" : ""
          } `}
        >
          {new Date(timer * 1000).toISOString().substr(11, 5)}
        </h1>
        <h5 className="card-title d-flex justify-content-between">
          <Link to={`order/${item.id}`} className="text-dark font-weight-light">
            #{item.id}
          </Link>{" "}
          <b>${item.total}</b>
        </h5>
        <div className="list-group">
          {item.products &&
            item.products.map((product: OrderProduct) => (
              <ProductItem
                product={product}
                order_id={+item.id}
                key={`product_items_${product.id}`}
                productAsDone={productAsDone}
              />
            ))}
        </div>

        <button
          className="btn btn_xs_lg btn_xs_lg_mob btn-block btn-success my-2"
          onClick={productAsDone}
          data-order_id={item.id}
          data-product_id="all_products"
        >
          <i className="fa fa-check icon_fixed_l" aria-hidden="true"></i>
          Todo Listo
        </button>
      </div>
    </div>
  );
};

type ProductItemProps = {
  product: OrderProduct;
  productAsDone: (e: any) => void;
  order_id: number;
};

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  productAsDone,
  order_id,
}) => {
  if (product.qty === product.qty_done) {
    return null;
  }
  return (
    <div className="row my-2 product_row">
      <div className="col-sm-12 pl-sm-1">
        <h4 className="ml-2 font-weight-bold">{product.product.title}</h4>
        <div className="list-group">
          {productItems(product, productAsDone, order_id)}
        </div>
      </div>
    </div>
  );
};

const productItems = (
  product: OrderProduct,
  productAsDone: any,
  order_id: number
) => {
  const response = [];
  const total_left = product.qty - product.qty_done;
  for (let i = 0; i < total_left; i++) {
    response.push(
      <ProductListGroup
        product={product}
        productAsDone={productAsDone}
        order_id={order_id}
        index={i + 1}
        key={`product_group_${product.id}_${i}`}
      />
    );
  }
  return response;
};

type ProductListProps = {
  product: OrderProduct;
  index: number;
  order_id: number;
  productAsDone: (e: any) => void;
};

const ProductListGroup: React.FC<ProductListProps> = ({
  product,
  index,
  productAsDone,
  order_id,
}) => (
  <div
    className="list-group-item list-group-item-action flex-column product_in_order align-items-start flat-top_mob active_product bg_warning hover"
    onClick={productAsDone}
    data-order_id={order_id}
    data-product_id={product.id}
  >
    <h5 className="mb-1 text-capitalize">
      {index}.-{" "}
      {product.ingredients_txt
        ? product.ingredients_txt
        : product.product.ingredients
        ? lang("Con Todo", "All the way")
        : product.product.category.singular}
    </h5>

    {product.comments && (
      <p className="">
        <hr />
        <small>Comentarios: </small> <br />
        {product.comments}
        <i className="fas fa-comment text-danger" aria-hidden="true"></i>
      </p>
    )}
  </div>
);

export default ProductViews;
