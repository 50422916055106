import "rc-slider/assets/index.css";
import React, { useContext } from "react";
import Slider from "rc-slider";

import { ProductContext } from "./productContext/context";
import { ActionType } from "./productContext/actions";
import OptionsSearch from "./OptionsSearch";
import { InputOption } from "./productContext/state";

const Options: React.FC = () => {
  const { dispatch, state } = useContext(ProductContext);

  const removeOption = (option: InputOption) => {
    dispatch({ type: ActionType.RemoveOption, payload: option });
  };

  return (
    <div className="material_card p-3 mt-3">
      <h4 className="">Más Opciones</h4>
      <p className="form-text text-muted mb-4">
        Si este producto cuenta con opciones para el cliente.
      </p>
      <OptionsSearch />
      <div className="row">
        <div className="col-8 col-md-6">
          <div className="form-group p_relative">
            <table className="table table-hover table-sm mt-4">
              <thead>
                <tr>
                  <th scope="col">Ing/Op</th>
                  <th scope="col">
                    Precio Opción{" "}
                    <i className="far fa-question-circle text-muted"></i>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody id="ingredients_div_opt">
                {state.options.map((option, idx) => (
                  <tr key={`option-${idx}`}>
                    <td>{option.ingredient.name}</td>
                    <td>
                      <input
                        value={option.price_mod}
                        type="text"
                        onChange={(e) =>
                          dispatch({
                            type: ActionType.SetOptionPrice,
                            payload: { price: +e.target.value, idx },
                          })
                        }
                        className="form-control form-control-sm size_price"
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => removeOption(option)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col col-md-6">
          <label htmlFor="">Máximo a escoger: {state.has_option}</label>
          <Slider
            min={1}
            max={state.options.length}
            dots={true}
            onChange={(value) =>
              dispatch({ type: ActionType.SetHasOption, payload: value })
            }
            value={state.has_option}
            step={1}
          />
        </div>
      </div>
    </div>
  );
};

export default Options;
