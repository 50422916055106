import React from "react";
import ProductList from "./product_list";
import OrderResume from "./orderResume";

const SideUI = ({
  order,
  handleDelivery,
  removeProduct,
  changePrice,
  addComment,
  selectIng,
  promptMakeOrder,
}) => {
  const count =
    order.products && order.products.length > 0
      ? order.products
          .map((item) => item.qty || 1)
          .reduce((accumulator, item) => {
            return accumulator + item;
          })
      : 0;

  return (
    <React.Fragment>
      <ProductList
        products={order.products || []}
        remove={removeProduct}
        changePrice={changePrice}
        addComment={addComment}
        selectIng={selectIng}
      />
      <OrderResume
        count={count}
        promptMakeOrder={order.edited ? promptMakeOrder : null}
        total={order.total}
        delivery={order.delivery}
        addComment={addComment}
        click={handleDelivery}
      />
    </React.Fragment>
  );
};

export default SideUI;
