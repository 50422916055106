import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { lang_is } from "../../../middleware/lang";
import { NavItem } from "./models";

type Props = {
  items: NavItem[];
};

const Nav: React.FC<Props> = ({ items }) => {
  const location = useLocation();
  return (
    <ul className="nav text-muted">
      {items.map((item) => (
        <li className="nav-item" key={item.link}>
          <Link
            className={`nav-link pl-0 ${
              location.pathname.includes(item.link) ? "active" : null
            }`}
            to={"/account" + item.link}
          >
            {item.name["es"]}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Nav;
