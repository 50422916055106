import { gql } from "@apollo/client";
import { Branch, Hour } from "../../../models/partner_model";

export type GetBranches = {
  getBranches: [Branch];
};

export type GetBranch = {
  getBranch: Branch;
};

export type GetBranchVariables = {
  id: number;
};

export type SetSchedule = {
  setSchedule: Branch;
};

export type SetScheduleVariables = {
  data: {
    id: number;
    hours: Hour[];
  };
};

export const SET_SCHEDULE = gql`
  mutation setSchedule($data: ScheduleInput!) {
    setSchedule(data: $data) {
      id
      address
      name
      phone
      contact_name
      contact_phone
      is_main
      zip
      city
      state
      hours {
        weekday
        start_hour
        end_hour
        opens
      }
    }
  }
`;

export const GET_BRANCHES = gql`
  query GetBranches {
    getBranches {
      id
      address
      name
      phone
      is_main
    }
  }
`;

export const GET_BRANCH = gql`
  query GetBranch($id: Int!) {
    getBranch(id: $id) {
      id
      address
      name
      phone
      contact_name
      contact_phone
      is_main
      zip
      city
      state
      hours {
        weekday
        start_hour
        end_hour
        opens
      }
      location {
        latitude
        longitude
      }
    }
  }
`;

export type EditBranchVariables = {
  data: EditBranchInput;
};

export type EditBranchInput = {
  id: number;
  name: string;
  city: string;
  address: string;
  location: string;
  zip: string;
  state: number;
  phone: string;
  contact_name: string;
  contact_phone: string;
};

export type EditBranchResponse = {
  editBranch: Branch;
};

export const EDIT_BRANCH = gql`
  mutation EditBranch($data: EditBranchInput!) {
    editBranch(data: $data) {
      id
      address
      name
      phone
      contact_name
      contact_phone
      is_main
      zip
      city
      state
      location {
        latitude
        longitude
      }
    }
  }
`;

export type CreateBranchInput = {
  name: string;
  city: string;
  address: string;
  zip: string;
  state: number;
  phone: string;
  contact_name: string;
  contact_phone: string;
  location: string;
};

export type CreateBranchVariables = {
  data: CreateBranchInput;
};

export type CreateBranchResponse = {
  createBranch: Branch;
};

export const CREATE_BRANCH = gql`
  mutation CreateBranch($data: CreateBranchInput!) {
    createBranch(data: $data) {
      id
      address
      name
      phone
      contact_name
      contact_phone
      is_main
      zip
      city
      state
    }
  }
`;

export type DeleteBranch = {
  deleteBranch: {
    id: number;
  };
};

export type DeleteBranchVariables = {
  id: number;
};

export const DELETE_BRANCH = gql`
  mutation DeleteBranch($id: Int!) {
    deleteBranch(id: $id) {
      id
    }
  }
`;
