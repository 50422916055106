export function formatNumber(num, round = true) {
  if (!num) return 0;
  if (round) {
    num = num.toString();
    let decimals = num.split(".");
    if (decimals[1]) {
      decimals = decimals[1].length;
      decimals = decimals <= 2 ? 2 : decimals; /// force to 2 decimals min
      decimals = decimals >= 2 ? 2 : decimals; /// force to 3 decimals max
    } else {
      decimals = 0; /// no decimals
    }
    num = parseFloat(num);
    num = num.toFixed(decimals);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const checkIfFileExists = async (url) => {
  const result = await fetch(url, { method: "HEAD" });
  return result.ok;
};
