import React from "react";
import { Route } from "react-router";
import ProductList from "./productList";
import ProductPanel from "./productPanel";

const Products: React.FC = () => (
  <div className="container py-4">
    <Route path="/products/new" component={ProductPanel} />
    <Route path="/products/edit/:id" component={ProductPanel} />
    <Route exact path="/products" component={ProductList} />
  </div>
);

export default Products;
