import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { CREATE_ZONE, GET_ZONES } from "../queries";

const AddZone: React.FC = () => {
  const [invalid, setInvalid] = useState("");
  const [adding, setAdding] = useState(false);
  const [zone, setZone] = useState("");
  const [createZone, { loading, called, error }] = useMutation(CREATE_ZONE, {
    refetchQueries: [{ query: GET_ZONES }],
  });

  useEffect(() => {
    if (called && !error) {
      setAdding(false);
    }
  }, [called, error]);

  const saveZone = () => {
    if (zone.length > 0) {
      createZone({ variables: { name: zone } });
    } else {
      setInvalid("El nombre de la zona es requerido.");
    }
  };

  return (
    <>
      {!adding ? (
        <button
          type="button"
          className="btn btn-toggle-primary btn-block my-1"
          onClick={() => setAdding(true)}
        >
          <i className="fas text-muted fa-plus icon_fixed_l"></i> Agregar Zona
        </button>
      ) : (
        <div className="p-4">
          <input
            type="text"
            className="form-control"
            id="product_title"
            value={zone}
            placeholder="Nueva zona"
            onChange={(e) => setZone(e.target.value)}
            autoComplete="off"
          />
          {invalid.length > 0 && (
            <div className="invalid-feedback d-block">{invalid}</div>
          )}
          <button
            type="button"
            disabled={loading}
            className="btn btn-primary btn-block my-1"
            onClick={saveZone}
          >
            {loading ? "Guardando" : "Guardar Zona"}
          </button>
        </div>
      )}
    </>
  );
};

export default AddZone;
