import React from "react";
import { lang } from "../../../middleware/lang";
import { OrderViewType } from "../../../models/order_model";
const buttons = [
  {
    name: lang("Por Orden", "By Order"),
    icon: "far fa-clipboard",
    view: OrderViewType.order,
  },
  {
    name: lang("Por Mesas", "By Tables"),
    icon: "fas fa-tablets",
    view: OrderViewType.table,
  },
  {
    name: lang("Por Productos", "By Products"),
    icon: "fas fa-hamburger",
    view: OrderViewType.products,
  },
];

type Props = {
  selectView: (e: any) => void;
  active_view: OrderViewType;
};

const Head: React.FC<Props> = ({ selectView, active_view }) => (
  <div className="row align-items-baseline justify-content-between ">
    <div className="col-md-4">
      <h1 className="my-2 mt-3 my-lg-4 show_on_new  text-center text-md-left">
        {lang("Órdenes Activas", "Active Orders")}
      </h1>
    </div>
    <div className="col-md-8 text-md-right mb-3 mb-md-0">
      <div
        className="btn-group xs_hidden"
        role="group"
        aria-label="Basic example"
      >
        {buttons.map((item, key) => {
          const active = active_view === item.view ? "active_danger" : "";
          return (
            <button
              key={key}
              type="button"
              className={`btn btn_underlined ${active}`}
              onClick={() => selectView(item.view)}
            >
              <i className={`${item.icon} mr-3`}></i> {item.name}
            </button>
          );
        })}
        <button
          className="btn btn-link "
          data-toggle="modal"
          data-target="#search_modal"
        >
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>
);

export default Head;
