import React from "react";
import { Route } from "react-router-dom";

import UserList from "./userList";
import UserForm from "./userForm";

const UsersIndex: React.FC = () => {
  return (
    <main className="sales_page">
      <div className="container">
        <Route exact path="/users" component={UserList} />
        <Route path="/users/invite" component={UserForm} />
        <Route path="/users/edit/:id" component={UserForm} />
      </div>
    </main>
  ); 
};

export default UsersIndex;