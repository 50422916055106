import React from "react";
import { Link } from "react-router-dom";

export const HeaderTitle = (props) => (
  <div
    className={`row my-3 ${
      props.justify ? props.justify : "justify-content-between"
    }`}
  >
    <div className="col-6">
      <h1>{props.title} </h1>
    </div>
    {props.btn ? button(props.btn) : null}
    {props.children}
  </div>
);

const button = (btn) => (
  <div className="col-6 col-lg-4">
    <Link
      to={btn.link}
      className="btn btn-raised btn-primary btn_lg btn-block ripple-effect"
    >
      <i className={btn.icon + " mr-1"} aria-hidden="true"></i>
      {btn.txt}
    </Link>
  </div>
);
