import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Autocomplete from "react-autocomplete";

import { Ingredient } from "../../../../models/product_model";
import { SEARCH_SIZES } from "../queries";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";
import { InputSize } from "./productContext/state";

const menuStyle = {
  borderRadius: "3px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
  background: "rgba(255, 255, 255, 1)",
  padding: "2px 0",
  fontSize: "90%",
  position: "fixed",
  overflow: "auto",
  maxHeight: "50%",
  zIndex: 5000,
};

const SizesSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<InputSize[]>([]);
  const [searchSizes, { data }] = useLazyQuery(SEARCH_SIZES);
  const { dispatch, state } = useContext(ProductContext);

  useEffect(() => {
    if (data) {
      const filtered = data.searchSizes.filter(
        (i: InputSize) => !state.sizes.map((j) => j.id).includes(i.id)
      );
      if (filtered.length > 0) {
        setSearchResults(filtered);
      } else if (search.length > 2) {
        setSearchResults([{ name: search, id: "0", price: 0 }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state.sizes]);

  const set = (text: string) => {
    setSearch(text);
    getSizes();
  };

  const addSize = (size: InputSize) => {
    const payload = {
      id: size.id,
      name: size.name,
      price: 0,
    };
    dispatch({ type: ActionType.AddSize, payload });
    setSearch("");
  };

  const getSizes = useDebouncedCallback(async () => {
    if (search && search.length > 1) {
      searchSizes({ variables: { search } });
    }
  }, 400);

  return (
    <div className="form-group p_relative" id="ingredients_div">
      <Autocomplete
        getItemValue={(item: Ingredient) => item.name}
        //@ts-ignore
        menuStyle={menuStyle}
        items={searchResults}
        value={search}
        onChange={(_, value) => set(value)}
        onSelect={(_, size: InputSize) => addSize(size)}
        renderInput={(props) => (
          <input
            type="text"
            className="form-control dropdown-toggle text-capitalize"
            id="product_ingredients"
            placeholder="Buscar Presentaciones/Tamaños"
            {...props}
          />
        )}
        renderItem={(size: InputSize) => (
          <div
            key={`size-${size.id}`}
            className="dropdown-item py-3 text-capitalize"
          >
            <i className="fa fa-plus text-primary" aria-hidden="true"></i>{" "}
            {size.name}
          </div>
        )}
      />
    </div>
  );
};

export default SizesSearch;
