import React from "react";
import { Branch } from "../../models/partner_model";

type BranchProps = {
  branch: Branch;
};

const BranchName: React.FC<BranchProps> = ({ branch }) => (
  <div className="branch_name">Sucursal: {branch.name}</div>
);

export default BranchName;
