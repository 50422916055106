export type SalesColors = {
  delivery: string;
  pickup: string;
  for_here: string;
  online: string;
  card_online: string;
  card_pos: string;
  terminal: string;
  cash: string;
  other_apps: string;
};

export const sales_colors: SalesColors = {
  delivery: "#7ea3cc",
  pickup: "#3e72af",
  for_here: "#252525",
  online: "#f9bb29",
  card_online: "#5ec9d7",
  card_pos: "#5ec9d7",
  terminal: "#5ec9d7",
  cash: "#67d496",
  other_apps: "#f56144",
};
