import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { LoaderDots } from "../../../../middleware/loaders";
import { GetBranch, GetBranchVariables, GET_BRANCH } from "../queries";
import BranchGeneral from "./branchGeneral";
import BranchSchedule from "./branchSchedule";

type Params = {
  id?: string
};

const BranchView: React.FC = () => {
  const { id } = useParams<Params>();
  const [getBranch, { data: branch, loading: loadingBranch }] = useLazyQuery<GetBranch, GetBranchVariables>(GET_BRANCH);

  useEffect(() => {
  if (!!id) {
      getBranch({ variables: { id: +id }});
    }
  }, [getBranch, id]);

  if (loadingBranch) {
    return <LoaderDots />;
  }

  return (
    <div className="row">
      <div className="col-12">
			 <p className="xs_hidden">
         <Link to="/branches">
           <i className="fa fa-chevron-left" aria-hidden="true"></i> Sucursales
          </Link>
        </p>
        <h1>Agregar Sucursal </h1>
		 </div>
      <div className="col-lg-6">
        <BranchGeneral id={id} branch={branch?.getBranch} />
      </div>
      {!!branch && <div className="col-lg-6">
        <BranchSchedule branch={branch.getBranch} />
      </div>}
  </div>
  ); 
};

export default BranchView;