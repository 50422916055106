import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { SalesDBType } from "../../../models/sales_model";
import { formatNumber } from "../../../middleware/common-functions";

type SalesResumeProps = {
  sales_data: SalesDBType | null;
  selected: Date | null | undefined;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  onChange(
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
};

const SalesResume: React.FC<SalesResumeProps> = ({
  sales_data,
  startDate,
  endDate,
  onChange,
}) => {
  const [showCalendar, setShow] = useState<boolean>(false);
  return (
    <div className="row text-center  pt-lg-3 justify-content-around">
      {sales_data ? (
        <div className="col-md-8 bg-white material-shadows d-flex justify-content-around py-3 ">
          <div>
            <h5 className="m-0 mb-2">Ventas PoS</h5>
            <h1 className="m-0">
              <b id="range_totals_pos">
                {formatNumber(sales_data.posSales.count)}
              </b>
            </h1>
          </div>
          <div>
            <h5 className="m-0 mb-2">Ventas Online</h5>
            <h1 className="m-0">
              <b id="range_totals_online">
                {formatNumber(sales_data.onlineSales.count)}
              </b>
            </h1>
          </div>
          <div>
            <h5>Total</h5>
            <h1 className="display-5">
              <b id="range_totals">
                $
                {formatNumber(
                  sales_data.posSales.total + sales_data.onlineSales.total
                )}
              </b>
            </h1>
            <p className="card-text">
              <small className="text-muted">
                $<span id="range_com">0</span> pesos de comisiónes en <br />
                <a href="/es/partner/account/settings/sales">
                  Servicios Adicionales
                </a>
              </small>
            </p>
          </div>
        </div>
      ) : null}
      <div className="col-md-4 mb-lg-3 d-flex mt-3 justify-content-end">
        <button
          className="btn btn-outline-dark rounded px-3 d-md-none"
          onClick={() => {
            setShow(!showCalendar);
          }}
        >
          {startDate?.toLocaleDateString()}
          {endDate &&
          startDate &&
          startDate.toLocaleDateString() !== endDate.toLocaleDateString()
            ? ` - ${endDate.toLocaleDateString()}`
            : ""}
          <i className="ml-2 fas fa-calendar-day"></i>{" "}
        </button>
        <div className={`date_picker ${showCalendar ? "show" : ""}`}>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            selectsRange
            inline
          />
        </div>
      </div>
    </div>
  );
};

export default SalesResume;
