import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import Switch from "react-switch";
import { SET_PRODUCT_AVAILABILITY } from "../queries";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";
import { GETPRODUCTS } from "../../orders/queries";
import { has_access } from "../../../../middleware/privileges";

type Props = {
  product_id: number;
};

export const QuickActions: React.FC<Props> = ({ product_id }) => {
  const { state, dispatch } = useContext(ProductContext);
  const [setAvailability] = useMutation(SET_PRODUCT_AVAILABILITY);
  const [getProducts, { data: productsDb }] = useLazyQuery(GETPRODUCTS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (productsDb) {
      const categories = [...productsDb.getCategories].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      const menu_obj = {
        products: [...productsDb.getProducts],
        categories,
      };
      debugger;
      localStorage.setItem("menu_all", JSON.stringify(menu_obj));
    }
  }, [productsDb]);

  const setSnooze = (value: boolean) => {
    dispatch({ type: ActionType.SetSnoozed, payload: !value });
    saveChange(!value, state.status, state.status_web);
  };
  const setStatus = (value: boolean) => {
    dispatch({ type: ActionType.SetStatus, payload: value });
    saveChange(state.snoozed, value, state.status_web);
  };
  const setStatusWeb = (value: boolean) => {
    dispatch({ type: ActionType.SetStatusWeb, payload: value });
    saveChange(state.snoozed, state.status, value);
  };

  const saveChange = (
    snoozed: boolean,
    status: boolean,
    status_web: boolean
  ) => {
    if (product_id === 0) {
      return;
    }
    const data = {
      product_id,
      snoozed,
      status,
      status_web,
    };
    setAvailability({ variables: { data } });
    getProducts();
  };

  return (
    <div className="material_card p-3">
      <div className="row p-3">
        <div className="form-group p_relative">
          <label htmlFor="product_categories ">
            Disponibilidad <br />
            <small className="text-muted">
              Hay existencia de este producto?
            </small>
            <div className="ml-2 row justify-content-start align-items-center my-2">
              <Switch
                id="pos"
                checked={!state.snoozed}
                onChange={(checked) => setSnooze(checked)}
              />
              <div className="ml-1">Disponible</div>
            </div>
          </label>
        </div>
        {has_access(256) ? (
          <div className="form-group p_relative">
            <label htmlFor="product_categories ">
              Canales de Venta <br />
              <small className="text-muted">
                Dónde está visible este producto?
              </small>
            </label>
            <div className="ml-2 row justify-content-start align-items-center my-2">
              <Switch
                id="pos"
                checked={state.status_web}
                onChange={(checked) => setStatusWeb(checked)}
              />
              <div className="ml-1">Página Web y Móvil</div>
            </div>
            <div className="ml-2 row justify-content-start align-items-center">
              <Switch
                id="pos"
                checked={state.status}
                onChange={(checked) => setStatus(checked)}
              />
              <div className="ml-1">Punto De Venta</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
