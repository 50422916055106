import React, { useContext } from "react";
import { Link } from "react-router-dom";

import navItems from "../../../data/sidenav.json";
import { AuthContext } from "../../../context/auth-context";
import { has_access } from "../../../middleware/privileges";

const DashNav = () => {
  const auth_context = useContext(AuthContext);
  const logOutHandle = () => {
    auth_context.logoutFun();
  };

  return (
    <div className="container login_div">
      <div className="row">
        <div className="col-lg-12 min_vh_5 " id="page_content">
          <div className="container-fluid my-2 mx-0 px-0">
            <div className="list-group list-group-flush">
              {Object.keys(navItems).map((key) => {
                const item = navItems[key];
                if (!has_access(item.priv)) {
                  return null;
                }
                return (
                  <Link
                    className={`list-group-item list-group-item-action  ${
                      item.ready ? "" : "disabled opacity_6"
                    }`}
                    to={item.link}
                  >
                    <i className={item.icon + " fa-fw"} aria-hidden="true"></i>{" "}
                    {item.text_es}
                  </Link>
                );
              })}
            </div>
            <button
              className="btn btn-block btn-danger mt-4"
              onClick={logOutHandle}
            >
              Cerrar sesión
            </button>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default DashNav;
