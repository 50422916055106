import { DateTime } from "luxon";
import { format } from "timeago.js";

export enum NumericType {
  Numeric = "numeric",
  Digit = "2-digit",
}
export enum StringType {
  Long = "long",
  Short = "short",
}

export type ConfigType = {
  year?: NumericType;
  month?: StringType;
  day?: NumericType;
  hour?: NumericType;
  minute?: NumericType;
  hour12?: boolean;
};

const dfault_config: ConfigType = {
  year: NumericType.Numeric,
  month: StringType.Short,
  day: NumericType.Numeric,
  hour: NumericType.Numeric,
  minute: NumericType.Numeric,
  hour12: true,
};

export function toDate(
  date: string | number | undefined | null | false,
  config?: ConfigType,
  format?: "short" | "long" | "date" | "hour"
): string {
  if (date === false) {
    date = DateTime.now().toMillis();
  } else if (!date) return "-";
  date = Number(date);
  const local_date = getLocalDate(date, config);
  const clean = JSON.stringify(local_date).slice(1, -1);
  const parsed = DateTime.fromISO(clean);
  const now = DateTime.local();
  const diffInDays = now.diff(parsed, "days").toObject();
  if ((diffInDays.days && diffInDays.days > 1) || format === "long") {
    return parsed.toLocaleString({
      locale: "es",
      weekday: "short",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (format === "date") {
    return parsed.toLocaleString({
      locale: "es",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  } else if (format === "hour") {
    return parsed.toLocaleString({
      locale: "es",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return parsed.toLocaleString(DateTime.TIME_SIMPLE);
}

function getLocalDate(date: number, config?: ConfigType) {
  const system_Date = new Date(date);
  ////if date has no time, return date as is
  if (
    system_Date.getHours() === 0 &&
    system_Date.getMinutes() === 0 &&
    system_Date.getSeconds() === 0
  ) {
    return new Date(date).toLocaleDateString("en", config || dfault_config);
  }
  return DateTime.fromMillis(date, { zone: "UTC" });
}
///making a change for re run github action
export function timeAgo(date: string | undefined | false): string {
  if (date === false) {
    date = DateTime.now().toSQL();
  } else if (!date) return "-";
  return format(DateTime.fromSQL(date, { zone: "UTC" }).toLocal().toString());
}

export function getWeekDates(
  weekNumber: number,
  yearNumber: number = 2021
): string {
  const dt = DateTime.fromObject({
    weekYear: yearNumber,
    weekNumber: weekNumber,
  });

  const dateFromStr = dt.startOf("week");
  const dateToStr = dt.endOf("week");
  return `${dateFromStr.toLocaleString({
    month: "short",
    day: "numeric",
  })} - ${dateToStr.toLocaleString({ month: "short", day: "numeric" })}`;
}
