import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Autocomplete from "react-autocomplete";

import { Category } from "../../../../models/product_model";
import { SEARCH_CATEGORIES } from "../queries";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";

const menuStyle = {
  borderRadius: "3px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
  background: "rgba(255, 255, 255, 1)",
  padding: "2px 0",
  fontSize: "90%",
  position: "fixed",
  overflow: "auto",
  maxHeight: "50%",
  zIndex: 5000,
};

const CategoriesSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Category[]>([]);
  const [searchCategories, { data }] = useLazyQuery(SEARCH_CATEGORIES);
  const { dispatch, state } = useContext(ProductContext);

  useEffect(() => {
    if (data) {
      setSearchResults(data.searchCategories);
    }
  }, [data]);

  useEffect(() => {
    if (state.category) {
      setSearch(state.category.name);
    }
  }, [state.category]);

  const set = (text: string) => {
    setSearch(text);
    getCategories();
  };

  const setCategory = (category: Category) => {
    const payload = {
      id: category.id,
      name: category.name,
    };
    dispatch({ type: ActionType.SetCategory, payload });
  };

  const getCategories = useDebouncedCallback(async () => {
    if (search && search.length > 0) {
      searchCategories({ variables: { search } });
    }
  }, 400);

  return (
    <div className="form-group p_relative" id="ingredients_div">
      <label htmlFor="product_ingredients">Tipo de producto</label>
      <small id="emailHelp" className="form-text text-muted">
        Selecciona el tipo de producto
      </small>
      <Autocomplete
        getItemValue={(item: Category) => item.name}
        items={searchResults}
        value={search}
        onChange={(_, value) => set(value)}
        onSelect={(_, category: Category) => setCategory(category)}
        //@ts-ignore
        menuStyle={menuStyle}
        renderInput={(props) => (
          <input
            type="text"
            className="form-control dropdown-toggle text-capitalize"
            id="product_ingredients"
            placeholder="Buscar tipos de producto"
            {...props}
          />
        )}
        renderItem={(category: Category) => (
          <div
            key={`ingredient-${category.id}`}
            className="dropdown-item py-3 text-capitalize"
          >
            <i className="fa fa-plus text-primary" aria-hidden="true"></i>{" "}
            {category.name}
          </div>
        )}
      />
    </div>
  );
};

export default CategoriesSearch;
