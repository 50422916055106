import React from "react";
import { lang } from "../../../middleware/lang";
import { formatNumber } from "../../../middleware/common-functions";
import { Link } from "react-router-dom";
import { Button } from "../../common/buttons";

const OrderActions = ({ order, payOut, updateOrder, print }) => (
  <div className="col-md-4 mt-4 mt-md-0">
    <div className="p-3">
      <h2 className="my-0 text-center display-4">
        <b className="text-dark invert">${formatNumber(order.total)}</b>
      </h2>
      <h5 className="mb-0 text-center text-muted">
        <small>Total</small>
      </h5>
      <p className="my-1 text-right">
        Subtotal: ${formatNumber(order.subtotal)}
      </p>
      <p className="my-1 text-right">
        I.V.A.: ${formatNumber(order.total - order.subtotal)}
      </p>
    </div>
    <div className="sticky-top">
      {renderCta(order, payOut, updateOrder)}
      <hr />
      <Button
        text={lang("Re-imprimir Ticket Cliente", "Print Customer Ticket")}
        classes="btn btn_xs_lg btn_xs_lg_mob btn-block btn-default text-muted py-4 my-2 mt-4 "
        fun={() => print(order, "client")}
      />
      <Button
        text={lang("Re-imprimir Comanda", "Print Ticket")}
        classes="btn btn_xs_lg btn_xs_lg_mob btn-block btn-default text-muted py-4 my-2 mt-4 "
        fun={() => print(order, "restaurant", true)}
      />
    </div>
    <p className="text-danger small display_none mt-5" id="printing_logs">
      {" "}
    </p>
  </div>
);

function renderCta(order, payOut, updateOrder) {
  const returnRender = [];
  if (!order.payed && order.status !== "placed") {
    returnRender.push(
      <Button
        fun={payOut}
        key="payedbtn"
        priv="8"
        text={lang("Cobrar", "Charge")}
        icon="far fa-money-bill-alt icon_fixed_l"
        classes="btn btn_xs_lg btn_xs_lg_mob btn-block btn-cart my-2 py-4"
      />
    );
  }

  if (order.status === "placed") {
    returnRender.push(
      <Button
        fun={() => {
          updateOrder("in_progress");
        }}
        key="readybtn"
        text={lang("Aceptar Pedido", "Accept Order")}
        icon="fa fa-check icon_fixed_l"
        classes="btn btn_xs_lg btn_xs_lg_mob btn-block btn-cart my-2 "
      />,
      <Button
        fun={() => {
          updateOrder("cancelled");
        }}
        text={lang("Rechazar Pedido", "Reject ORder")}
        key="cancelbtn"
        icon="fa fa-ban text-danger icon_fixed_l"
        classes="btn btn-block btn-default my-2 mt-5 "
      />
    );
  } else if (order.status !== "fulfilled" && order.status !== "cancelled") {
    returnRender.push(
      <Button
        fun={() => {
          if (order.type !== "online" || order.status === "ready") {
            updateOrder("fulfilled");
          } else {
            updateOrder("ready");
          }
        }}
        key="readybtn"
        text={
          order.type !== "online" || order.status === "ready"
            ? lang("Cerrar Pedido", "Close Order")
            : lang("Marcar Pedido Listo", "Order Ready")
        }
        icon={
          order.type !== "online" || order.status === "ready"
            ? "fa fa-check icon_fixed_l"
            : "fas fa-mobile-alt icon_fixed_l"
        }
        classes={`btn btn_xs_lg btn_xs_lg_mob btn-block my-2 py-4  ${
          order.type !== "online" || order.status === "ready"
            ? "btn-danger"
            : "btn-success"
        }`}
      />,
      <Link
        to={`/point_of_sale/${order.id}`}
        className="btn btn-block btn btn_xs_lg btn_xs_lg_mob mt-5 d-md-none btn-info"
        key="editbtn"
      >
        <i className="far fa-edit " aria-hidden="true"></i>{" "}
        {lang("Modificar Pedido", "Edit Order")}
      </Link>,
      <Button
        fun={() => {
          updateOrder("cancelled");
        }}
        priv="512"
        text={lang("Cancelar Pedido", "Cancel")}
        key="cancelbtn"
        icon="fa fa-ban text-danger icon_fixed_l"
        classes="btn btn-block btn-default my-2 mt-5 "
      />
    );
  } else {
    returnRender.push(
      <Button
        fun={() => {
          updateOrder("in_progress");
        }}
        text={lang("Re activar", "Reactivate")}
        key="reactivatebtn"
        priv="512"
        icon="fa fa-history icon_fixed_l"
        classes="btn btn_xs_lg btn_xs_lg_mob btn-block btn-default my-2 mt-4 "
      />
    );
  }

  return returnRender;
}

export default OrderActions;
