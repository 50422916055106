import { gql } from "@apollo/client";

export const GETCASHOUTS = gql`
  query cashOut($branch_id: Int, $startDate: String, $endDate: String) {
    getCashout(
      branch_id: $branch_id
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      amount
      concept
      creation
      creation_date
      branch_id
      type
    }
    getSales(branch_id: $branch_id, startDate: $startDate, endDate: $endDate) {
      orders {
        id
        type
        total
        creation
        creation_date
        delivery
        cash_income
        resume_txt
        delivery_type
        payment_type
        status
        payed
        table {
          id
          name
        }
      }
      posSales {
        count
        total
      }
      onlineSales {
        count
        total
      }
    }
  }
`;

export const SAVECASHOUT = gql`
  mutation saveCashout($cashoutIpunt: CashoutInput!) {
    createCashlog(log: $cashoutIpunt) {
      amount
      concept
      creation
      branch_id
    }
  }
`;

export const UPDATECASHOUT = gql`
  mutation updateCashLog($cashoutIpunt: CashoutInput!) {
    updateCashLog(log: $cashoutIpunt) {
      amount
      concept
      creation
      branch_id
    }
  }
`;

export const DELETECASHOUT = gql`
  mutation deleteCashLog($id: Int!) {
    deleteCashLog(id: $id) {
      amount
      concept
      creation
      branch_id
    }
  }
`;
