import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_ZONES } from "../queries";
import { ProductContext } from "./productContext/context";
import { Product } from "../../../../models/product_model";
import ProductImage from "./ProductImage";
import { ActionType } from "./productContext/actions";
import AddZone from "./AddZone";
import useSettings from "../../../../hooks/settings";
import CategoriesSearch from "./CategoriesSearch";

type Zone = {
  id: number;
  name: string;
};
type Props = {
  product: Product;
};

const ProductInfo: React.FC<Props> = ({ product }) => {
  const [settings] = useSettings();
  const { state, dispatch } = useContext(ProductContext);
  const { data: zones, loading } = useQuery(GET_ZONES);
  const [model_edited, setModelEdited] = useState(false);

  const setTitle = (value: string) => {
    dispatch({ type: ActionType.SetTitle, payload: value });
  };

  const setModel = useCallback(
    (value: string, autogenerated = false) => {
      const model = value.toLocaleUpperCase().split(" ").join("-");
      setModelEdited(!autogenerated);
      dispatch({ type: ActionType.SetModel, payload: model });
    },
    [dispatch, setModelEdited]
  );

  const setDescription = (value: string) => {
    dispatch({ type: ActionType.SetDescription, payload: value });
  };

  useEffect(() => {
    if (!state.title) {
      setModel("", true);
      return;
    }
    if (model_edited) {
      return;
    }
    const chunks = state.title.toLocaleUpperCase().split(" ");
    const modelChunks = [];
    if (chunks.length === 1) {
      modelChunks.push(chunks[0]);
    } else {
      modelChunks.push(chunks[0].slice(0, 3));
      if (chunks.length === 2) {
        modelChunks.push(chunks[1].slice(0, 3));
      } else {
        modelChunks.push(chunks[1].slice(0, 2));
        modelChunks.push(chunks[2].slice(0, 3));
      }
    }
    setModel(modelChunks.join("-"), true);
  }, [state.title, model_edited, setModel]);

  const handleAutogenerate = () => {
    setModelEdited(false);
  };

  return (
    <div className="material_card p-3">
      <h4 className="mb-4">Información de Producto</h4>
      <div className="row">
        <div className="product_imgs col-lg-6 mb-3">
          <ProductImage images={product.images} productId={+product.id} />
        </div>
        <div className="col-lg-8">
          <div className="form-group">
            <label htmlFor="product_title">Título del producto</label>
            <input
              type="text"
              className="form-control"
              data-type="title"
              value={state.title}
              onChange={(e) => setTitle(e.target.value)}
              autoComplete="off"
            />
            <small className="form-text text-muted">
              Máximo 150 caracteres{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="product_sku" onClick={handleAutogenerate}>
              SKU
              {!product.id ? (
                model_edited || state.model.length === 0 ? (
                  <small className="ml-2 text-primary hover_pointer">
                    Autogenerar
                  </small>
                ) : (
                  <small className="ml-2 text-muted">Autogenerado</small>
                )
              ) : null}
            </label>
            <input
              type="text"
              className="form-control text-uppercase"
              data-type="sku"
              placeholder="PROD-01"
              disabled={!!product.id}
              value={state.model}
              onChange={(e) => setModel(e.target.value)}
              autoComplete="new-password"
            />
            <small className="form-text text-muted">
              Este es el identificador único de tu producto para los reportes de
              ventas.
            </small>
            {!product.id ? (
              <small className="form-text text-danger">
                Una vez guardado el producto ya no lo podrás editar
              </small>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="">Descripción larga</label>
            <textarea
              value={state.description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              name="desc"
              rows={3}
            />
            <small className="form-text text-muted">
              Cuéntales porque este producto es lo mejor que pueden probar{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="product_price">Precio</label>
            {state.sizes.length > 0 ? (
              <div
                className="alert alert-info"
                role="alert"
                id="single_size_text"
              >
                <i className="fas fa-exclamation-triangle"></i>{" "}
                <small>Precio definido por tamaños</small>
              </div>
            ) : (
              <>
                {" "}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    data-type="price"
                    name="product_price"
                    aria-label="Amount (to the nearest dollar)"
                    onChange={(e) =>
                      dispatch({
                        type: ActionType.SetPrice,
                        payload: +e.target.value,
                      })
                    }
                    value={state.price}
                  />
                </div>
                <small className="form-text text-muted">
                  {settings.printer_iva
                    ? "Incluir IVA"
                    : "Tus precios no incluyen IVA"}
                </small>
              </>
            )}
          </div>
          <div className="form-group">
            <CategoriesSearch />
          </div>
          <div className="form-group p_relative">
            <label htmlFor="product_categories ">
              Zona de Producto
              <br />
              <small className="text-muted">
                Separa los productos en la comanda
              </small>
            </label>
            {!loading && zones.getZones && (
              <>
                <select
                  className="form-control"
                  value={state.zone_id}
                  onChange={(e) =>
                    dispatch({
                      type: ActionType.SetZone,
                      payload: +e.target.value,
                    })
                  }
                >
                  <option value={0}>Sin Zona</option>
                  {zones.getZones.map((zone: Zone) => (
                    <option key={`zone-${zone.id}`} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
                <AddZone />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
