import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATESETTINGS } from "./queries";
import { Settings, SettingsProps } from "../models/settings_model";
import { activeBranchVar } from "../middleware/cache";

type ReturnedSettings = () => [
  settings: Settings,
  saveSettings: (pref_key: SettingsProps, pref_value: any) => Promise<void>
];

const useSettings: ReturnedSettings = () => {
  const local_settings: Settings = JSON.parse(
    localStorage.getItem("settings") || "{}"
  );
  const [updateSettingsMutation] = useMutation(UPDATESETTINGS);
  const [settings, setSettings] = useState({
    active_view: "order",
    ...local_settings,
  });

  useEffect(() => {
    if (settings.active_branch) {
      activeBranchVar(settings.active_branch);
    }
    if (settings.nightmode) {
      document.body.classList.add("night");
    } else {
      document.body.classList.remove("night");
    }
    if (!settings.active_view) {
      const set_settings = { ...settings };
      set_settings.active_view = "order";
      setSettings({ ...set_settings });
    }
  }, [settings]);

  const saveSettings = async (pref_key: SettingsProps, pref_value: any) => {
    if (!pref_key) {
      return;
    }

    const set_settings = { ...settings };
    if (!set_settings[pref_key]) {
      set_settings[pref_key] = {};
    }
    set_settings[pref_key] = pref_value;
    localStorage.setItem("settings", JSON.stringify(set_settings));
    setSettings({ ...set_settings });
    await updateSettingsMutation({
      variables: { key: pref_key, value: String(pref_value) },
    });
    return;
  };

  return [settings, saveSettings];
};

export default useSettings;
