import React from "react";
import { lang } from "../../../middleware/lang";

const ProductsRow = ({
  selectedCat,
  title,
  products,
  addFunction,
  tiggerAdded,
}) => (
  <div
    className={`material_card card_xs_none mb-3 py-3 row pos_products_row ${
      selectedCat === null ? "" : selectedCat ? "" : "d-none"
    }`}
  >
    <div className="col-12 mb-1">
      <h2>{title}</h2>
    </div>
    <div className="col-12  flex-wrap d-flex">
      {Object.keys(products).map((key, index) => {
        let res = [];
        res.push(
          <Product
            key={key}
            info={products[key]}
            addFunction={addFunction}
            tiggerAdded={tiggerAdded}
          />
        );
        return res;
      })}
    </div>
  </div>
);

const Product = ({ info, addFunction, tiggerAdded }) => (
  <div
    className={`card product_card full_link_parent  ${
      info.snoozed ? "opacity_6 hover_blocked" : "hover_pointer"
    }`}
    data-product_id={+info.id}
    onClick={info.snoozed ? undefined : addFunction}
  >
    {tiggerAdded === info.id && (
      <div className="added_card">
        <i className="fas fa-check"></i>
      </div>
    )}
    <div className="img_price">
      <img
        alt={info.title}
        src={
          info.images && info.images.length > 0
            ? info.images[0].url
            : "https://s3.amazonaws.com/ftp-foo/page_assets/no_photo_02.jpg"
        }
        data-holder-rendered="true"
        className="w-100"
      />
      <h5 className="price_tag">
        {info.has_size
          ? lang("Precio por Tamaño", "Price by Size")
          : `$${info.price}`}
      </h5>
    </div>
    <h5 className="my-2" id="product_title_1090">
      {info.title}
    </h5>

    {info.snoozed ? (
      <h6 className="my-2">
        <small className={`text-danger float-lg-right `}>
          <i className="fas fa-stopwatch" aria-hidden="true"></i>Agotado
        </small>
      </h6>
    ) : null}
  </div>
);

export default ProductsRow;
