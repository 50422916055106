import { gql } from "@apollo/client";
import { AccessType, UserRole } from "../../../models/subuser_model";

export type SubuserInput = {
  email: string;
  role: UserRole;
  permissions: AccessType[];
  quickPass: string;
};

export type EditSubuserInput = {
  id: number;
  email: string;
  role: UserRole;
  permissions: AccessType[];
  quickPass: string;
};

export type createSubuserVariables = {
  data: SubuserInput;
};

export type editSubuserVariables = {
  data: EditSubuserInput;
};

export const RESEND_INVITE = gql`
  mutation ResendInvite($id: Int!) {
    resendInvite(id: $id) {
      id
      subuser_id
      name
      lastname
      email
      status
      role
      permissions
      privileges
      branch {
        name
        id
      }
    }
  }
`;

export const GET_SUBUSER = gql`
  query GetSubuser($id: Int!) {
    getSubuser(id: $id) {
      id
      subuser_id
      name
      lastname
      email
      status
      role
      permissions
      privileges
      quickPass
      branch {
        name
        id
      }
    }
  }
`;

export const DELETE_SUBUSER = gql`
  mutation DeleteSubuser($id: Int!) {
    deleteSubuser(id: $id) {
      name
      branch {
        id
      }
      subuser_id
      lastname
      role
      status
      permissions
    }
  }
`;

export const GET_SUBUSERS = gql`
  query GetSubusers {
    getAllSubusers {
      id
      subuser_id
      status
      name
      email
      lastname
      role
      privileges
      branch {
        name
        id
      }
    }
  }
`;

export const CREATE_SUBUSER = gql`
  mutation CreateSubuser($data: SubuserInput!) {
    createSubuser(data: $data) {
      name
      branch {
        id
      }
      subuser_id
      lastname
      role
      permissions
    }
  }
`;

export const EDIT_SUBUSER = gql`
  mutation editSubuser($data: EditSubuserInput!) {
    editSubuser(data: $data) {
      name
      branch {
        id
      }
      subuser_id
      lastname
      role
      permissions
    }
  }
`;
