import React from "react";
//@ts-ignore next line
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type DeleteModalProps = {
  open: boolean;
  children: any;
  toggle: () => void;
  title: string;
  handleConfirm: (ev: any) => void;
  className?: string;
  confirm_txt?: string;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  title,
  toggle,
  className,
  handleConfirm,
  children,
  confirm_txt,
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={className}
      autoFocus={false}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <button onClick={handleConfirm} className={`btn btn-danger btn-block`}>
          {confirm_txt || "Confirmar"}
        </button>
      </ModalFooter>
    </Modal>
  );
};
