import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Autocomplete from "react-autocomplete";

import { Ingredient } from "../../../../models/product_model";
import { SAVE_INGREDIENT, SEARCH_INGREDIENTS } from "../queries";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";
import { InputIngredient } from "./productContext/state";

const menuStyle = {
  borderRadius: "3px",
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
  background: "rgba(255, 255, 255, 1)",
  padding: "2px 0",
  fontSize: "90%",
  position: "fixed",
  overflow: "auto",
  maxHeight: "50%",
  zIndex: 5000,
};

const OptionsSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Ingredient[]>([]);
  const [searchIngredients, { data }] = useLazyQuery(SEARCH_INGREDIENTS, {
    fetchPolicy: "no-cache",
  });
  const [saveNewIngredient, { data: new_ing }] = useMutation<{
    saveIngredient: InputIngredient;
  }>(SAVE_INGREDIENT);
  const { dispatch, state } = useContext(ProductContext);

  useEffect(() => {
    if (data) {
      const filtered = data.searchIngredients.filter(
        (i: InputIngredient) =>
          !state.ingredients.map((j) => j.id).includes(i.id)
      );
      if (filtered.length > 0) {
        setSearchResults(filtered);
      } else if (search.length > 2) {
        setSearchResults([{ name: search, name_en: search, id: "0" }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, state.options]);

  useEffect(() => {
    if (new_ing) {
      addOption(new_ing.saveIngredient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_ing]);

  const set = (text: string) => {
    setSearch(text);
    getIngredients();
  };

  const addOption = (ingredient: InputIngredient) => {
    if (!ingredient.id || ingredient.id === "0") {
      ///save new ingredient
      saveNewIngredient({
        variables: {
          ingredient: ingredient.name,
          lang: "en",
        },
      });
      return;
    }
    const option = {
      ingredient,
      price_mod: 0,
    };
    dispatch({ type: ActionType.AddOption, payload: option });
    setSearch("");
  };

  const getIngredients = useDebouncedCallback(async () => {
    if (search && search.length > 1) {
      searchIngredients({ variables: { search } });
    }
  }, 400);

  return (
    <div className="form-group p_relative" id="ingredients_div">
      <Autocomplete
        getItemValue={(item: Ingredient) => item.name}
        //@ts-ignore
        menuStyle={menuStyle}
        items={searchResults}
        value={search}
        onChange={(_, value) => set(value)}
        onSelect={(_, ingredient: Ingredient) => addOption(ingredient)}
        renderInput={(props) => (
          <input
            type="text"
            className="form-control dropdown-toggle text-capitalize"
            id="product_ingredients"
            placeholder="Buscar opciones"
            {...props}
          />
        )}
        renderItem={(ingredient: Ingredient) => (
          <div
            key={`ingredient-${ingredient.id}`}
            className="dropdown-item py-3 text-capitalize"
          >
            <i className="fa fa-plus text-primary" aria-hidden="true"></i>{" "}
            {ingredient.name}
          </div>
        )}
      />
    </div>
  );
};

export default OptionsSearch;
