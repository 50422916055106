import { InMemoryCache, makeVar, ReactiveVar } from "@apollo/client";

export const activeBranchVar: ReactiveVar<number> = makeVar(0);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        activeBranch: {
          read() {
            return activeBranchVar();
          },
        },
      },
    },
  },
});
