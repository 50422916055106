import { User } from "./user_model";
import { Address } from "./address_model";
import { Product } from "./product_model";
import { Branch } from "./partner_model";

export interface OrderType {
  id: string;
  type: "pos" | "online";
  client: Client;
  status: OrderStatus;
  status_color: string;
  subtotal: number;
  total_real: number;
  total: number;
  creation_date: string;
  creation: string;
  modification_date: string;
  modification: string;
  user_comments: string | boolean;
  client_comments: string | boolean;
  delivery: number;
  cash_income: number;
  resume_txt: string;
  address: Address | boolean;
  delivery_type: "for_here" | "pickup" | "delivery" | "other_apps";
  payment_type: PaymentType;
  card_type: CardType;
  branch: Branch;
  payed: boolean;
  user: User;
  table: Table;
  products: OrderProduct[];
  createdProducts?: number[];
  delivery_type_icon?: string;
  delivery_type_text?: string;
}

export type OrderProduct = {
  id: string;
  dbId?: string;
  client_id: number;
  order_id: number;
  status: string;
  price: number;
  size: number;
  ingredients: string;
  qty: number;
  qty_done: number;
  ingredients_txt: string;
  ingredients_opt: string;
  name: string;
  comments: string;
  product: Product;
};

export type Client = {
  id: string;
  full_name: string;
  name: string;
  lastname: string;
  email: string;
  phone_verified: boolean;
  profile_pic: string;
  username: string;
  phone: string;
};

export type OrderStatus =
  | "fulfilled"
  | "in_progress"
  | "pending"
  | "cancelled"
  | "ongoing"
  | "placed"
  | "new"
  | "ready"
  | "idle";

export type PaymentType =
  | "cash"
  | "online"
  | "card_pos"
  | "terminal"
  | "other_apps";

export type CardType = "debit" | "credit" | "services";

export enum OrderViewType {
  order = "order",
  table = "table",
  products = "products",
}

export type Table = {
  id: string;
  order_id: number;
  partner_string: number;
  branch_string: number;
  is_active: boolean;
  name: string;
};
