import React from "react";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { LOGOUT } from "./queries";
import { FullPageLoader } from "../../../middleware/loaders";

const LoguoutPage: React.FC = () => {
  const [logoutMutation] = useMutation(LOGOUT);
  useEffect(() => {
    const token = localStorage.getItem("ntt");
    if (token) {
      logoutMutation({ variables: { token } });
    }
    setTimeout(() => {
      logoutFun();
    }, 1500);
    //eslint-disable-next-line
  }, []);

  const logoutFun = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return <FullPageLoader show />;
};

export default LoguoutPage;
