//@ts-ignore
import cloneDeep from "clone-deep";
//@ts-ignore
import omitDeep from "omit-deep";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router";
import { LoaderDots } from "../../../../middleware/loaders";
import { Product } from "../../../../models/product_model";
import {
  CREATE_PRODUCT,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "../queries";
import Header from "./Header";
import Ingredients from "./Ingredients";
import Options from "./Options";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";
import { productReducer } from "./productContext/reducer";
import { initialProductState } from "./productContext/state";
import ProductInfo from "./ProductInfo";
import { QuickActions } from "./QuickActions";
import { has_access } from "../../../../middleware/privileges";
import ProductInfoPlain from "./ProductInfoPlain";

type Params = {
  id?: string;
};

///TODO: Validate that product has these fields before showing on FooApp or APIs
// const requiredFieldsToLive = [
//   "category",
//   "price",
//   "title",
//   "model",
//   "description",
// ];

const ProductPanel: React.FC = () => {
  const { id } = useParams<Params>();
  const [product, setProduct] = useState<Product>({} as Product);

  const history = useHistory();
  const [
    createProduct,
    { loading: saving, error, called, data: createdProduct },
  ] = useMutation(CREATE_PRODUCT);
  const [updateProduct, { loading: updating, called: calledUpdateProduct }] =
    useMutation(UPDATE_PRODUCT);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const [getProduct, { data: product_db, loading }] = useLazyQuery(
    GET_PRODUCT,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [state, dispatch] = useReducer(productReducer, initialProductState);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    if (called && !error && createdProduct) {
      history.push(`/products/edit/${createdProduct.createProduct.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, saving, called, createdProduct, history, calledUpdateProduct]);

  useEffect(() => {
    if (id) {
      getProduct({ variables: { id: +id } });
    }
  }, [id, getProduct]);

  useEffect(() => {
    if (product_db?.getProduct) {
      const {
        title,
        price,
        model,
        description,
        zone_id,
        sizes,
        ingredients,
        snoozed,
        status,
        status_web,
        options,
        has_option,
        category,
      } = product_db.getProduct;
      setProduct(product_db.getProduct);
      dispatch({
        type: ActionType.LoadProduct,
        payload: {
          price,
          title,
          model,
          description,
          category,
          has_option,
          sizes,
          ingredients,
          zone_id,
          snoozed,
          status,
          status_web,
          options,
        },
      });
    }
  }, [product_db]);

  if (loading) {
    return <LoaderDots fullSize />;
  }

  const onSubmit = () => {
    const cleanState = omitDeep(cloneDeep(state), "__typename");
    if (!id) {
      createProduct({ variables: { data: cleanState } });
    } else {
      updateProduct({ variables: { data: { id, ...cleanState } } });
    }
  };

  const promtDeleteProduct = async () => {
    await deleteProduct({ variables: { product_id: id } });
    history.push(`/products`);
  };

  return (
    <ProductContext.Provider value={{ state, dispatch }}>
      <div className="row">
        <Header product={product} />
        <div className="col-lg-8 order-1 order-md-0 ">
          {has_access(256) ? (
            <>
              <ProductInfo product={product} />
              <Ingredients />
              <Options />
              <div className="material_card p-3 mt-3">
                <button
                  disabled={saving || updating || loading}
                  className="btn btn-success btn-block"
                  type="submit"
                  onClick={onSubmit}
                >
                  <i className="fa fa-floppy-o" aria-hidden="true"></i> Guardar
                  Cambios
                </button>
                <button
                  className="btn btn-default btn-block my-3 text-danger"
                  disabled={saving || updating || loading}
                  onClick={promtDeleteProduct}
                  type="button"
                >
                  <i className="fas fa-trash" aria-hidden="true"></i> Eliminar
                  Producto
                </button>
              </div>
            </>
          ) : (
            <ProductInfoPlain product={product} />
          )}
        </div>
        <div className="col-lg-4 order-0 order-md-1">
          <QuickActions product_id={id ? +id : 0} />
        </div>
      </div>
    </ProductContext.Provider>
  );
};

export default ProductPanel;
