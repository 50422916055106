import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { lang } from "../../middleware/lang";
import useSettings from "../../hooks/settings";
import { hasIngredient } from "../../middleware/product_functions";

export const ModalIng = ({
  open,
  toggle,
  className,
  product,
  click,
  handleSum,
  handleLess,
  handleQty,
  addProduct,
}) => (
  <Modal isOpen={open} toggle={toggle} className={className}>
    <ModalHeader toggle={toggle}>{product.title}</ModalHeader>
    <ModalBody>
      {product.ingredients.length > 0 ? (
        <ChooseIngredients
          ingredients={product.ingredients}
          click={click}
          product={product}
          text={
            product.text_ing && product.text_ing.length
              ? "Sin " + product.text_ing.join(", ")
              : "Con Todo"
          }
        />
      ) : (
        <h5 className="text-center">Sin Ingredientes a Escoger</h5>
      )}
      <Quantytiy
        handleSum={handleSum}
        handleLess={handleLess}
        handleQty={handleQty}
        qty={product.qty}
      />
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn btn-success btn-block btn_xs_lg"
        onClick={addProduct}
      >
        Listo
      </Button>
    </ModalFooter>
  </Modal>
);

const Ingredient = ({ info, click, active }) => (
  <button
    type="button"
    className={`btn btn-flat ripple-effect btn-toggle-primary px-1 ingredient_btn ${
      active ? "active" : null
    }`}
    data-ing={+info.id}
    onClick={click}
  >
    <i className={info.icon}></i>
    <br />
    <small>{info.name}</small>
  </button>
);

const Quantytiy = (props) => (
  <div className="product_quantity">
    <hr />
    <div className="input-group mb-3 input-group-lg">
      <div className="input-group-prepend" id="product_quantity_cont">
        <button
          className="btn btn-lg btn-transparent text-center  text-muted"
          id="minus_button"
          type="button"
          onClick={props.handleLess}
        >
          <i className="fas fa-minus"></i>
        </button>
      </div>

      <input
        type="tel"
        className="form-control text-center border-0"
        aria-label="Product Quantity"
        onChange={props.handleQty}
        aria-describedby="product_quantity_cont"
        value={props.qty}
      />

      <div className="input-group-append" id="button-addon4">
        <button
          className="btn btn-lg btn-transparent text-center "
          type="button"
          onClick={props.handleSum}
        >
          <i className="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
);

export const ModalOptions = (props) => {
  const { product } = props;
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      className={props.className}
    >
      <ModalHeader toggle={props.toggle}>
        {product.title}
        {product.has_option > 1 ? (
          <p className="my-1">
            Escoge hasta <b>{product.has_option}</b> opciones
          </p>
        ) : null}
      </ModalHeader>
      <ModalBody>
        <div className="d-sm-block w-100 justify-content-between">
          <div className="" data-toggle="buttons" id="modal_options">
            {product.options.map((option, key) => (
              <button
                className={`btn btn-outline-dark btn_xs_lg btn-block product_size ${
                  option.active ? "active" : ""
                }`}
                data-ing={option.id}
                key={`option_id${option.id}`}
                onClick={props.click}
              >
                {option.ingredient.name}
                {option.price_mod ? (
                  <b className="text-danger"> | + ${option.price_mod}</b>
                ) : null}
              </button>
            ))}
          </div>
        </div>
      </ModalBody>
      {product.has_options > 1 ? (
        <ModalFooter>
          <button className="btn btn-block btn-danger" onClick={props.next}>
            Continuar
          </button>
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export const ModalSizes = (props) => {
  const { sizes } = props;
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      className={props.className}
    >
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>
        <div className="d-sm-block w-100 justify-content-between">
          <div className="" data-toggle="buttons" id="modal_options">
            {Object.keys(sizes).map((key) => (
              <button
                className="btn btn-outline-dark btn_xs_lg btn-block product_size"
                data-ing={key}
                key={key}
                onClick={props.click}
              >
                {sizes[key].name}
                {sizes[key].price ? <b> | ${sizes[key].price}</b> : null}
              </button>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export const ModalTables = ({
  open,
  toggle,
  makeOrder,
  tables,
  active_table,
}) => {
  console.log(tables);
  console.log(active_table);
  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {lang("Selecciona Mesa", "Select Table")}
      </ModalHeader>
      <ModalBody>
        <div className="d-sm-block w-100 justify-content-between">
          <div className="" data-toggle="buttons" id="modal_options">
            {tables.map((table) => (
              <button
                className={`btn btn_helper rounded_plus p-5 m-1 my-3 btn-outline-dark ${
                  table.is_active || active_table === +table.id ? "active" : ""
                }`}
                data-table_id={table.id}
                key={`table-${table.id}`}
                onClick={makeOrder}
              >
                {table.name || table.id}
              </button>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export const ModalChangePrice = (props) => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      size="lg"
      autoFocus={false}
    >
      <ModalHeader toggle={props.toggle}>
        {lang("Modificar Precio", "Chance Price")}
      </ModalHeader>
      <ModalBody>
        <div className="d-sm-block w-100 justify-content-between">
          <div className="" data-toggle="buttons">
            <div className="input-group mb-3 input-group-lg">
              <input
                type="tel"
                className="form-control text-center border-0 display_10"
                placeholder=""
                aria-label="Product Quantity"
                aria-describedby="product_price_ch_cont"
                onChange={props.inputFun}
                value={props.price}
                autoFocus
              />
            </div>
          </div>
          <h2
            className={`price_result text-secondary text-right animate_all ${
              props.price_calc ? "opacity_7" : "opacity_0"
            }`}
          >
            $<span>{props.price_calc}</span>
          </h2>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-danger btn-block btn_xs_lg"
          onClick={props.click}
        >
          Aceptar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export const ModalComments = (props) => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      size="lg"
      autoFocus={false}
    >
      <ModalHeader toggle={props.toggle}>
        {lang("Comentarios", "Add Comments")}
      </ModalHeader>
      <ModalBody>
        <div className="input-group mb-3 input-group-lg">
          <textarea
            type="text"
            className="form-control"
            aria-label="Product Comments"
            aria-describedby="product_comment_cont"
            spellCheck="false"
            onChange={props.inputFun}
            autoFocus
            value={props.comments}
          ></textarea>
        </div>
        <div className="row">
          <div className="col">
            <small className="form-text text-muted">
              {lang("Máximo 150 caracteres", "Max 150 Ch")}
            </small>
          </div>
          <div className="col text-right">
            {props.comments ? (
              <small className="form-text text-muted">
                Quedan {150 - props.comments.length}
              </small>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-danger btn-block btn_xs_lg"
          onClick={props.click}
        >
          {lang("Guardar", "Save")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export const ModalOrder = (props) => {
  const [settings] = useSettings();
  let count = 0;
  if (!props.order.products) {
    return <>...</>;
  }
  props.order.products.map((item, key) => (count = item.qty + count));
  return (
    <div
      className={`modal-cover container- px-0 px-sm-2 ${
        props.show ? "" : "display_none"
      }`}
    >
      <div className="container py-3 px-sm-2">
        <div className="doughnut fixed"></div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="row align-items-center">
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-block min_width_xs_1 text-muted"
                  onClick={props.back}
                >
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>{" "}
                </button>
              </div>
              <div className="col-10">
                <h2>Resumen del Pedido</h2>
              </div>
            </div>
            <div className="material_card pos_panel p-3 scrolleable">
              <div className="row my-2">
                <div className="col-8">Producto</div>
                <div className="col-4">Precio</div>
              </div>
              <div className="order_summary fix_vh_40 ">
                {props.order.products.map((item, key) => (
                  <ListMov
                    key={key}
                    product={item}
                    index={key}
                    removeProduct={props.removeProduct}
                    changePrice={props.changePrice}
                    addComment={props.addComment}
                    openOptions={props.openOptions}
                  />
                ))}
              </div>
              <div className="col-12">
                <hr />
                <div className="row">
                  <div className="col-7">
                    <p className="media-body m-0 small lh-125">
                      Productos <span className="products_num">{count}</span>
                    </p>
                  </div>

                  <div className="col-5 p-0">
                    {" "}
                    <button
                      type="button"
                      // onClick="add_comments_promt('all')"
                      className="btn btn-transparent min_with_1 btn-block m-0 text-right"
                    >
                      <i className="far fa-comments-alt text-muted ordercomments"></i>
                    </button>
                  </div>
                </div>

                <hr />
              </div>

              <div className="col">
                <h3 className="text-right">
                  Total: $
                  <span className="order_totals">{props.order.total}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-0 px-sm-0 m-0 fixed_bottom_mov material-shadows_mov d-lg-none align-items-baseline">
        <div className="col ">
          <Delivery type="pickup" click={props.makeOrder} />
        </div>
        {settings.disable_delivery ? null : (
          <div className="col ">
            <Delivery type="delivery" click={props.makeOrder} />
          </div>
        )}
        <div className="col mt-2">
          <Delivery type="for_here" click={props.makeOrder} />
        </div>
        <div className="col mt-2">
          <Delivery type="other_apps" click={props.makeOrder} />
        </div>
      </div>

      <div className="modal-footer xs_hidden sm_hidden">
        <button
          type="button"
          className="btn btn-raised btn-outline-dark ripple-effect"
          data-dismiss="modal"
          // onClick="make_order(false)"
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i> Atrás
        </button>
        <button
          type="button"
          className="btn btn-raised btn-danger btn-block ripple-effect btn_xs_lg un_disabled"
          id="make_order_btn_tab"
          // onClick="confirm_order()"
        >
          Hacer Pedido
        </button>
      </div>
    </div>
  );
};

export const ProductActions = ({
  open,
  toggle,
  selectIng,
  removeProduct,
  changePrice,
  addComment,
  product_key,
}) => {
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>Opciones</ModalHeader>
      <ModalBody>
        <div className="d-sm-block w-100 justify-content-between">
          <button
            type="button"
            className="btn btn-block btn_xs_lg btn-outline-dark btn-sm text-center ch_price"
            data-product_key={product_key}
            onClick={selectIng}
          >
            <i className="fas fa-pen icon_fixed_l "></i> Ingredientes
          </button>
          <button
            type="button"
            className="btn btn-block btn_xs_lg btn-outline-dark btn-sm text-center ch_price"
            data-product_key={product_key}
            onClick={changePrice}
          >
            <i className="fas icon_fixed_l fa-dollar-sign"></i> Cambiar Precio
          </button>
          <button
            type="button"
            className="btn btn-block btn_xs_lg btn-outline-dark btn-sm text-center"
            data-product_key={product_key}
            data-type="product"
            onClick={addComment}
          >
            <i className="far icon_fixed_l fa-comment"></i> Agregar Comentario
          </button>
          <button
            type="button"
            className="btn btn-block btn_xs_lg btn-outline-danger btn-sm text-center"
            data-product_key={product_key}
            onClick={removeProduct}
          >
            <i className="fas icon_fixed_l fa-trash"></i> Quitar Producto
          </button>
        </div>
      </ModalBody>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-link btn-block btn_xs_lg"
          onClick={toggle}
        >
          Cancelar
        </button>
      </div>
    </Modal>
  );
};

const ListMov = ({ product, openOptions, index }) => {
  const byX =
    product.qty > 1 ? (
      <div className="col text-right">
        <p className="my-0">
          {" "}
          x{product.qty} | ${product.price / product.qty} c/u
        </p>
      </div>
    ) : null;

  return (
    <button
      className="list-group-item list-group-item-action flex-column align-items-start pb-0 hover_cta_cont"
      data-product_key={index}
      onClick={openOptions}
    >
      <div className="row py-1">
        <div className="col-9">
          <h5 className="mb-1">{product.title}</h5>
        </div>
        <div className="col-3 text-right">
          <h4>
            <b>${product.price}</b>
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="my-0">{product.text ? product.text : "Con Todo"} </p>
        </div>
        {byX}
      </div>
    </button>
  );
};

const Delivery = ({ type, checked, click }) => {
  let info = null;
  switch (type) {
    case "for_here":
      info = {
        text: "En Comedor",
        icon: "fas fa-stop-circle",
      };
      break;

    case "pickup":
      info = {
        text: "Para Recoger",
        icon: "far fa-hand-point-up",
      };
      break;
    case "delivery":
      info = {
        text: "A Domicilio",
        icon: "fa fa-motorcycle",
      };
      break;
    case "other_apps":
      info = {
        text: "Plataformas",
        icon: "fas fa-mobile-alt",
      };
      break;

    default:
      info = {
        text: "En Comedor",
        icon: "fas fa-stop-circle",
      };
      break;
  }
  return (
    <button
      className={`btn btn-outline-dark btn_xs_lg btn-block ${
        checked ? "active" : null
      } btn_check_delivery`}
      onClick={click}
      data-type={type}
    >
      <i className={info.icon} aria-hidden="true"></i> {info.text}
    </button>
  );
};

const ChooseIngredients = ({ ingredients, text, click, product }) => (
  <div>
    <div className="ing_cont">
      {ingredients
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((ingredient) => {
          return (
            <Ingredient
              info={ingredient}
              key={`ingredient_set_${ingredient.id}`}
              active={hasIngredient(product.ingredientsSel, ingredient.id)}
              click={click}
            />
          );
        })}
    </div>
    <h3 className="text-center">{text}</h3>
  </div>
);
