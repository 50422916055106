import React from "react";
import { SalesDelivery, SalesPayment } from "../../../models/sales_model";
import { formatNumber } from "../../../middleware/common-functions";

type TableProps = {
  data: SalesDelivery[] | SalesPayment[];
};

const SalesTable: React.FC<TableProps> = ({ data }) => (
  <table
    className="table table-borderless table-hover mt-3 data_tables"
    id="delivery_table"
  >
    <thead>
      <tr>
        <th scope="col">Tipo</th>
        <th scope="col">Órdenes</th>
        <th scope="col">Ingreso</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item: SalesDelivery | SalesPayment, key: number) => {
        console.log("table.tsx:22 | item", item);
        if (item.amount === 0) {
          return null;
        }
        return (
          <tr key={`${item.type}_${key}`}>
            <td className="position-relative pl-5  text-left">
              <div className="reports-square absolute"></div>
              {item.type_txt}
            </td>{" "}
            <td>{formatNumber(item.amount)}</td>
            <td>${formatNumber(item.total)}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default SalesTable;
