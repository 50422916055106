import React, { useEffect, useState } from "react";
import useSettings from "../../../../hooks/settings";
import { PrinterTypes } from "../../../../models/printer_model";
import { Settings } from "../../../../models/settings_model";
import { IconButton } from "../../common/buttons";

const Tickets: React.FC = () => {
  const [settings, saveSettings] = useSettings();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    return () => {
      if (updated) {
        window.location.reload();
      }
    };
  }, [updated]);

  const updateSetting = async (ev: { currentTarget: HTMLButtonElement }) => {
    const setting_type = ev.currentTarget.id as keyof Settings;
    const checked = !settings[setting_type];
    await saveSettings(setting_type, checked);
    setUpdated(true);
  };

  const updatePrinterType = (ev: { currentTarget: HTMLButtonElement }) => {
    const printer_type = ev.currentTarget.id as PrinterTypes;
    saveSettings("printer_type", printer_type);
    setUpdated(true);
  };

  return (
    <div className="tickets_section">
      <div className="row">
        <div className="col-md-7">
          <PrinterSection
            settings={settings}
            updateSetting={updateSetting}
            updatePrinterType={updatePrinterType}
          />
        </div>
        <div className="col">
          <RightSide />
        </div>
      </div>
    </div>
  );
};
type PrinterSectionProps = {
  settings: Settings;
  updateSetting: any;
  updatePrinterType: any;
};
const PrinterSection: React.FC<PrinterSectionProps> = ({
  settings,
  updateSetting,
  updatePrinterType,
}) => (
  <div className="card">
    <div className="card-body ">
      <h3 className="">Imprimir Tickets</h3>
      <IconButton
        clickFun={updateSetting}
        text="Imprimir tickets"
        checked={settings.price_ticket ? "true" : undefined}
        id="price_ticket"
      />
      <hr />
      <h5 className="mb-1">Tipo de Impresora</h5>
      <IconButton
        clickFun={updatePrinterType}
        text="USB"
        icon="fab fa-usb"
        classes="btn-sm"
        checked={settings.printer_type === "usb" ? "true" : undefined}
        id="usb"
      />
      <IconButton
        clickFun={updatePrinterType}
        text="Bluetooth"
        icon="fab fa-bluetooth-b"
        classes="btn-sm"
        checked={settings.printer_type === "bluetooth" ? "true" : undefined}
        id="bluetooth"
      />
      <IconButton
        clickFun={updatePrinterType}
        text="Por Red"
        icon="fas fa-project-diagram"
        classes="btn-sm"
        checked={settings.printer_type === "network" ? "true" : undefined}
        id="network"
      />
      <IconButton
        clickFun={updatePrinterType}
        text="Cloud Printing"
        icon="fas fa-cloud"
        classes="btn-sm"
        checked={settings.printer_type === "mqtt" ? "true" : undefined}
        id="mqtt"
      />
      <hr />
      <h5 className="mb-1">Opciones</h5>
      <IconButton
        clickFun={updateSetting}
        text="Imprimir ticket cliente al hacer pedido"
        classes="btn-sm"
        checked={settings.print_upfront ? "true" : undefined}
        id="print_upfront"
      />
      <IconButton
        clickFun={updateSetting}
        text="Imprimir Copia Restaurant al hacer pedido"
        classes="btn-sm"
        checked={settings.restaurant_copy ? "true" : undefined}
        id="restaurant_copy"
      />
      <IconButton
        clickFun={updateSetting}
        text="Abrir Caja de Monedas al Cobrar"
        classes="btn-sm"
        checked={settings.open_drawer ? "true" : undefined}
        id="open_drawer"
      />
      <IconButton
        clickFun={updateSetting}
        text="Información Fiscal en Ticket"
        classes="btn-sm"
        checked={settings.printer_fiscal ? "true" : undefined}
        id="printer_fiscal"
      />
      <IconButton
        clickFun={updateSetting}
        text="Imprimir IVA en Tickets"
        classes="btn-sm"
        checked={settings.printer_iva ? "true" : undefined}
        id="printer_iva"
      />
      <IconButton
        clickFun={updateSetting}
        text="Agregar Propina Sugerida en Tickets (15%)"
        classes="btn-sm"
        checked={settings.suggested_tip ? "true" : undefined}
        id="suggested_tip"
      />
      <IconButton
        clickFun={updateSetting}
        text="Imprimir precios en Comandas"
        classes="btn-sm"
        checked={settings.price_ticket ? "true" : undefined}
        id="price_ticket"
      />
      <IconButton
        clickFun={updateSetting}
        text="Imprimir Tickets en Consola (Sporte Técnico)"
        classes="btn-sm"
        checked={settings.print_console ? "true" : undefined}
        id="print_console"
      />
    </div>
  </div>
);

const RightSide: React.FC = () => {
  const [settings, saveSettings] = useSettings();
  const [ticketInfo, setTicketInfo] = useState({
    txt: settings.ticket_txt || "",
    logo: settings.ticket_logo || "",
  });
  const maxLenght = 150;
  const saveText = async () => {
    saveSettings("ticket_txt", ticketInfo.txt);
    // validate if file exists
    // const exists = await checkIfFileExists(ticketInfo.logo);
    // console.log("tickets.tsx:144 | exists", exists);
    // if (exists) {
    //   console.log("tickets.tsx:144 | checkIfFileExists");
    //   return;
    // }
    saveSettings("ticket_logo", ticketInfo.logo);
  };

  const handleText = (e: { target: HTMLTextAreaElement }) => {
    const new_text = e.target.value;
    if (new_text.length >= maxLenght) {
      return;
    }
    const set_info = { ...ticketInfo };
    set_info.txt = new_text;
    setTicketInfo(set_info);
  };

  const handleLogo = (e: { target: HTMLInputElement }) => {
    const new_text = e.target.value;
    const set_info = { ...ticketInfo };
    set_info.logo = new_text;
    setTicketInfo(set_info);
  };
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="">Personaliza tus Tickets</h3>
        <div className="form-group">
          <label>Agrega tu Logo</label>
          <input
            type="text"
            className="form-control"
            placeholder="Agrega la URL de tu logo"
            value={ticketInfo.logo}
            onChange={handleLogo}
          />
          <small id="emailHelp" className="form-text text-muted">
            Para mayor velocidad tu logo debe estar en blanco y negro
          </small>
        </div>
        <div className="form-group">
          <label>Texto en Ticket</label>
          <textarea
            className="form-control"
            placeholder="Agrega un texto personalizado al final de tus tickets"
            id="ticket_info"
            rows={3}
            value={ticketInfo.txt}
            onChange={handleText}
          />
          <small id="emailHelp" className="form-text text-muted">
            {ticketInfo
              ? `Quedan ${maxLenght - ticketInfo.txt.length} caracteres.`
              : ""}{" "}
            Máximo {maxLenght} caracteres.
          </small>
        </div>
        <button
          className="btn btn-success btn-block"
          type="button"
          onClick={saveText}
        >
          <i className="fas fa-scroll"></i> Guardar
        </button>
        <span
          className="text-success small ml-3 display_none"
          id="saved_ticket"
        >
          <i className="fas fa-check"></i> Información guardada
        </span>
      </div>
    </div>
  );
};

export default Tickets;
