import { gql } from "@apollo/client";

export const GET_BRANCHES = gql`
  query GetBranches {
    getBranches {
      id
      address
      name
      phone
      is_main
    }
  }
`;

export const GET_PARTNER = gql`
  query GetPartner {
    getPartner {
      id
      about
      short_desc
      fb_page
      instagram
      webpage
      url_name
    }
  }
`;