import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';

type MarkerProps ={
  lat: number,
  lng: number
}
const Marker: React.FC<MarkerProps> = () => {
  return <div className="fa fa-map-marker fa-3x" style={{ color: 'red'}}></div>
}

type Props = {
  lat: number,
  lng: number
  updateMarker: (data: any) => void
};

const GoogleMap: React.FC<Props> = ({ lat, lng, updateMarker }) => {
  
  const [center, setCenter] = useState({ lat: 0, lng: 0});

  useEffect(() => {
    if (lat && lng) {
      setCenter({
        lat,
        lng
      });
    }
  }, [lat, lng]);

  if (!lat || !lng) {
    return <div></div>;
  }

  const updateCoords = (e : any) => {
    setCenter({
      lat: e.center.lat(),
      lng: e.center.lng()
    })
  }

  const update = () => {
    updateMarker(center);
  }

  return (
    <div>
      <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "" }}
          defaultCenter={center}
          defaultZoom={18}
          onDragEnd={e => updateCoords(e)}
        >
          <Marker
            lat={center.lat}
            lng={center.lng}
          />
        </GoogleMapReact>
      </div>
      <button className="btn btn-primary" onClick={update}>
        Guardar ubicación
      </button>
   </div>
  );
}

export default GoogleMap;