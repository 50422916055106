import React from "react";
import { Link } from "react-router-dom";
import { lang } from "../../../middleware/lang";

const WelcomePage: React.FC = () => (
  <main>
    <div className="text-center my-3">
      <h1>{lang("Bienvenido a Foo", "Welcome")}</h1>
      <p>
        {lang(
          "Bienvenido a los primeros pasos para mejorar tu flujo de trabajo",
          "This is your first step into improving your workflow"
        )}
        <br />
        {lang("Completa los siguientes pasos", "Complete the next steps")}
      </p>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="list-group">
          <LiItem
            title={lang("Agrega tus productos", "Add products")}
            description={lang(
              "Agrega al menos un producto de tu menú para comenzar a vender",
              "Add at least one product from your menu to start selling"
            )}
            complete={false}
            to="/products/new"
          />
          <LiItem
            title={lang("Agrega tu sucursal", "Add a branch")}
            description={lang(
              "Deja que tus clientes sepan en donde te encuentras",
              "Let your customers know where you are"
            )}
            complete={false}
            to="/branches/create"
          />
          <LiItem
            title={lang(
              "Cuéntanos mas de tu negocio",
              "Tell us more about your business"
            )}
            description={lang("Identifica tu marca", "Stand your brand")}
            complete={false}
            to="account/settings/account"
          />
        </div>
      </div>
    </div>
  </main>
);

type LiItemProps = {
  title: string;
  description: string;
  to: string;
  complete: boolean;
};

const LiItem: React.FC<LiItemProps> = ({
  title,
  description,
  complete,
  to,
}) => (
  <Link to={to} className="list-group-item list-group-item-action py-3">
    <div className="d-flex w-100 align-items-center">
      <i
        className={`far fa-${
          complete ? "check-square text-success" : "square text-muted"
        } mr-3 fa-2x`}
      ></i>
      <div>
        <h5 className="m-0">{title}</h5>
        <p className="m-0">{description}</p>
      </div>
    </div>
  </Link>
);

export default WelcomePage;
