import React from "react";
import { useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import SyncLoader from "react-spinners/SyncLoader";
import foo_logo from "../components/assets/foo_mascot_chef_hat@2x.svg";
import tips from "../data/tips.json";

type FullPageLoaderProps = {
  show: boolean;
};

type LoaderDotsProps = {
  size?: number;
  color?: string;
  delay?: number | false;
  fullSize?: boolean;
};

export const FullPageLoader: React.FC<FullPageLoaderProps> = ({ show }) => {
  const random = Math.floor(Math.random() * tips.length);
  const tip = tips[random];
  return (
    <main className="full_page_loader">
      <img src={foo_logo} alt="Company Logo" />
      <h1>Tip</h1>
      <p>{tip.es}</p>
      <BarLoader color={"#fff"} loading={show} />
    </main>
  );
};

export const LoaderDots: React.FC<LoaderDotsProps> = ({
  size = 50,
  color = "#bdbdbd",
  delay = false,
  fullSize = false,
}) => {
  const inital_show = delay ? false : true;
  const [show, setShow] = useState(inital_show);
  if (delay) {
    setTimeout(() => {
      setShow(true);
    }, delay * 1000);
  }
  if (!show) {
    return null;
  }
  if (fullSize) {
    return (
      <div className="full_loader">
        <SyncLoader size={size} color={color} />
      </div>
    );
  }
  return <SyncLoader size={size} color={color} />;
};
