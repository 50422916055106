import React, { useState } from "react";
import Switch from "react-switch";
import rappi_logo from "../../../assets/rappi.png";

const Rappi: React.FC = () => {
  const [hasRappi, setHasRappi] = useState(false);

  return (
    <div className="row justify-content-start mt-3">
      <div className="col col-md-6">
        <div className="card mb-2">
          <div className="card-body ">
            <div className="row align-items-center px-2 pb-2">
              <img
                src={rappi_logo}
                style={{ height: "50px", width: "auto" }}
                alt="sociallogo"
              />
              <h4 className="ml-2">Configuración de Rappi</h4>
            </div>
            <div className="form-group p_relative">
              <label htmlFor="product_categories ">
                <small className="text-muted">
                  Recibe órdenes, da de alta tu menú y realiza entregas con
                  Rappi desde Foo
                </small>
                <div className="ml-2 row justify-content-start align-items-center my-2">
                  <Switch
                    id="pos"
                    checked={hasRappi}
                    onChange={(checked) => setHasRappi(checked)}
                  />
                  <div className="ml-1">
                    {hasRappi
                      ? "Conectar mi punto de venta con Rappi"
                      : "No uso Rappi"}
                  </div>
                </div>
              </label>
            </div>
            <div id="shipping_btn" className="mb-3">
              <h4 className="">Opciones</h4>
              <button
                type="button"
                className="btn btn-primary btn-block mt-4"
                disabled={!hasRappi}
              >
                Sincronizar mi menú con Rappi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rappi;
