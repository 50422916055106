import React from "react";

const Header = ({ sideShow }) => (
  <header className="fixed-top">
    <div className="row justify-content-end bg_partner">
      <div className={`col-lg-${sideShow ? 10 : 11}`}></div>
    </div>
  </header>
);

export default Header;
