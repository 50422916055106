import { useMutation } from "@apollo/client";
import React, { useReducer } from "react";
import { Branch, Hour } from "../../../../models/partner_model";
import { SetSchedule, SetScheduleVariables, SET_SCHEDULE } from "../queries";

type HourEntry = {
  startHour: string,
  endHour: string
};

type DayInput = {
  label: string
  hours: HourEntry[]
  value: number
  opens: boolean
};

const initialState: DayInput[] = [
  { label: 'Lunes', hours: [{ startHour: '', endHour: ''}], value: 1, opens: false },
  { label: 'Martes', hours: [{ startHour: '', endHour: ''}], value: 2, opens: false },
  { label: 'Miércoles', hours: [{ startHour: '', endHour: ''}], value: 3, opens: false },
  { label: 'Jueves', hours: [{ startHour: '', endHour: ''}], value: 4, opens: false },
  { label: 'Viernes', hours: [{ startHour: '', endHour: ''}], value: 5, opens: false },
  { label: 'Sábado', hours: [{ startHour: '', endHour: ''}], value: 6, opens: false },
  { label: 'Domingo', hours: [{ startHour: '', endHour: ''}], value: 7, opens: false },
];

enum ActionTypes {
  reset = 'reset',
  setStartHour = 'setStartHour',
  setEndHour = 'setEndHour',
  setOpen = 'setOpens',
  addEntry = 'addEntry',
  deleteEntry = 'deleteEntry'
};

type Payload = {
  day: number,
  entry?: number,
  value?: string,
  opens?: boolean
};

type Action = {
  type: ActionTypes
  payload: Payload
};

const reducer = (state: DayInput[], action: Action ) => {
  const { type, payload } = action;
  switch(type) {
    case ActionTypes.reset: {
      return initialState;
    }
    case ActionTypes.setStartHour: {
      let day = state.find(d => d.value === payload.day);
      if (day) {
        const hours = [...day.hours];
        if(typeof payload.entry !== "undefined" && payload.value)
          hours[payload.entry] = {...hours[payload.entry], startHour: payload.value };
          day.hours = hours;
      }
      return [...state];
    }
    case ActionTypes.setEndHour: {
      let day = state.find(d => d.value === payload.day);
      if (day) {
        const hours = [...day.hours];
        if(typeof payload.entry !== "undefined" && payload.value)
          hours[payload.entry] = {...hours[payload.entry], endHour: payload.value };
          day.hours = hours;
      }
      return [...state];
    }
    case ActionTypes.setOpen: {
      let day = state.find(d => d.value === payload.day);
      if (day) {
        day.opens = payload.opens || false;
      }
      return [...state];  
    }
   case ActionTypes.addEntry: {
      let day = state.find(d => d.value === payload.day);
      if (day) {
        day.hours = [...day.hours, { startHour: '', endHour: ''}];
      }
      return [...state];  
    }
    case ActionTypes.deleteEntry: {
      let day = state.find(d => d.value === payload.day);
      if (day) {
        day.hours = day.hours.slice(0, -1);
      }
      return [...state];  
    }
  }
}

type DayProps = {
  day: DayInput,
  dispatch: React.Dispatch<Action>
};


const DaySchedule: React.FC<DayProps> = ({ day, dispatch }) => {
  const setOpen = (e: any) => {
    const payload: Payload = {
      day: day.value,
      opens: e.target.checked  
    }
    dispatch({ type: ActionTypes.setOpen, payload });
  }

  const addEntry = () => {
    dispatch({ type: ActionTypes.addEntry, payload: { day: day.value }});
  }

  const deleteEntry = () => {
    dispatch({ type: ActionTypes.deleteEntry, payload: { day: day.value }});
  }

  const changeStartHour = (index: number, e: any) => {
    const payload = {
      day: day.value,
      value: e.target.value,
      entry: index
    };
    dispatch({ type: ActionTypes.setStartHour, payload });
  }

  const changeEndHour = (index: number, e: any) => {
    const payload = {
      day: day.value,
      value: e.target.value,
      entry: index
    };
    dispatch({ type: ActionTypes.setEndHour, payload });
  }

  return (
    <div className="row mb-3">
      <div className="col-12">
        <input className="form-check-input m-0 position-static"
          checked={day.opens}
          id={`dias-${day.value}`}
          onChange={e => setOpen(e)}
          type="checkbox" />
        <label className="ml-2 form-radio-label" htmlFor={`dias-${day.value}`}>
          {day.label}
        </label>
      </div>
      <div className="col-sm-8">
        {day.hours.map((entry, idx) => (
          <div className="row no-gutters" key={`schedule-${day.value}-${idx}`}>
            <div className="col-sm-5">
              <input disabled={!day.opens}
                type="time"
                placeholder="Hora inicio"
                className="form-control"
                value={entry.startHour}
                onChange={e => changeStartHour(idx, e)}
              />
            </div>
            <div className="col-sm-2 justify-content-center d-none d-sm-flex align-items-center"> <i className="fas fa-minus text-secondary "></i>
            </div>
            <div className="col-sm-5">
              <input
                disabled={!day.opens}
                type="time" placeholder="Hora fin"
                onChange={e => changeEndHour(idx, e)}
                className="form-control" value={entry.endHour} />
            </div>
          </div>
        ))}
     </div>
      <div className="col-sm-3">
        <button 
          type="button"
          disabled={!day.opens}
          onClick={addEntry}
          className="my-2 ml-2 px-2 btn btn-sm btn-outline-secondary float-right" >
          <i className="fas fa-plus"></i>
        </button>
        {day.hours.length > 1 && (
          <button
            disabled={!day.opens}
            type="button"
            onClick={deleteEntry}
            className="my-2 ml-2 px-2 btn btn-sm btn-outline-secondary float-right">
            <i className="fas fa-minus"></i>
          </button>
        )}
      </div>
    </div>
  );
};

type Props = {
  branch: Branch
};

const initSchedule = (hours: Hour[]) => {
  const newDays = JSON.parse(JSON.stringify(initialState));
  if (hours.length) {
    hours.forEach(day => {
      newDays[day.weekday - 1].opens = day.opens;
      if (newDays[day.weekday -1].hours.length && newDays[day.weekday -1].hours[0].startHour) {
        newDays[day.weekday - 1].hours.push({ startHour: day.start_hour.slice(0, -3), endHour: day.end_hour.slice(0, -3) });
      } else {
        newDays[day.weekday - 1].hours = [{ startHour: day.start_hour.slice(0, -3), endHour: day.end_hour.slice(0, -3) }];
      }
    })
    return newDays;
  } else {
    return initialState;
  }
}

const mapToBackend = (state: DayInput[]) => {
  let result: any = [];
  state.forEach(day => {
    const curr = day.hours.map(hour => ({
      weekday: day.value,
      opens: day.opens,
      start_hour: hour.startHour,
      end_hour: hour.endHour
    })); 
    result = [...result, ...curr]
  })
  return result;
}

const BranchSchedule: React.FC<Props> = ({ branch }) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => initSchedule(branch.hours));
  const [setSchedule] = useMutation<SetSchedule, SetScheduleVariables>(SET_SCHEDULE);

  const updateSchedule = () => {
    const data = {
      id: +branch.id,
      hours: mapToBackend(state)
    };
    setSchedule({ variables: { data }});
  }

  return (
    <div className="material_card p-3">
      <div className="row">
        <div className="col-lg-10">
          <h4>Horarios</h4>
          {state.map((day: DayInput) => <DaySchedule key={`schedule-${day.value}`} day={day} dispatch={dispatch} />)}
          <div className="form-group">
            <button type="button" className="btn btn-primary" onClick={updateSchedule}>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BranchSchedule;