import { useMutation } from "@apollo/client";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Branch } from "../../../../models/partner_model";
import {
  EditBranchVariables,
  EditBranchResponse,
  EDIT_BRANCH,
  GET_BRANCHES,
  CreateBranchResponse,
  CreateBranchVariables,
  CREATE_BRANCH,
  DELETE_BRANCH,
  DeleteBranch,
  DeleteBranchVariables,
} from "../queries";
import GoogleAutocomplete from "../../../common/GoogleAutocomplete";
import GoogleMap from "./GoogleMap";
import { DeleteModal } from "../../../modals/deletes";

type Props = {
  id: string | undefined;
  branch: Branch | undefined;
};

const BranchGeneral: React.FC<Props> = ({ id, branch }) => {
  const history = useHistory();
  const [adjusting, setAdjusting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("0");
  const [contact, setContact] = useState("");
  const [privatePhone, setPrivatePhone] = useState("");
  const [deleteBranch, { called: deleted, error: deleteError }] = useMutation<
    DeleteBranch,
    DeleteBranchVariables
  >(DELETE_BRANCH, {
    refetchQueries: [{ query: GET_BRANCHES }],
  });
  const [editBranch, { called, error }] = useMutation<
    EditBranchResponse,
    EditBranchVariables
  >(EDIT_BRANCH, {
    refetchQueries: [{ query: GET_BRANCHES }],
  });
  const [createBranch, { called: createCalled, error: createError }] =
    useMutation<CreateBranchResponse, CreateBranchVariables>(CREATE_BRANCH, {
      refetchQueries: [{ query: GET_BRANCHES }],
    });

  useEffect(() => {
    if (
      (called && !error) ||
      (createCalled && !createError) ||
      (deleted && !deleteError)
    ) {
      history.push("/branches");
    }
  }, [history, called, error, createCalled, createError, deleted, deleteError]);

  useEffect(() => {
    if (!!branch) {
      const stringLocation = `${branch.location.latitude},${branch.location.longitude}`;
      const current = branch;
      setLocation(stringLocation);
      setName(current.name);
      setPhone(current.phone);
      setZip(current.zip);
      setCity(current.city);
      setAddress(current.address);
      setState(current.state);
      setPrivatePhone(current.contact_phone);
      setContact(current.contact_name);
    }
  }, [branch]);

  const saveBranch = () => {
    let data = {
      id: 0,
      name,
      phone,
      address,
      city,
      zip,
      location,
      state: +state,
      contact_name: contact,
      contact_phone: privatePhone,
    };
    if (!!id) {
      data.id = +id;
      editBranch({ variables: { data } });
    } else {
      const { id, ...rest } = data;
      createBranch({ variables: { data: rest } });
    }
  };

  const updateData = (data: any) => {
    if (data.location) {
      setLocation(data.location);
    }
    if (data.address) {
      setAddress(data.address);
    }
    if (data.city) {
      setCity(data.city);
    }
  };

  const sendDeletePromt = () => {
    setShowDelete(true);
  };

  const sendDelete = () => {
    if (branch) {
      deleteBranch({ variables: { id: +branch.id } });
    }
  };

  const updateMarker = (coords: any) => {
    setLocation(`${coords.lat},${coords.lng}`);
    setAdjusting(false);
  };

  return (
    <div className="material_card p-3">
      <div className="row">
        <div className="col-lg-8">
          <h4>Datos Públicos</h4>
          <div className="form-group">
            <label htmlFor="product_title">Nombre de la sucursal</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_name"
              placeholder="Sucursal Centro"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <small className="form-text text-muted">
              Máximo 100 caracteres{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="product_sku">Teléfono al público</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <small className="form-text text-muted">
              A qué número pueden marcar tus clientes?{" "}
            </small>
          </div>
          <div className="form-group">
            <GoogleAutocomplete updateData={updateData} />
          </div>
          {!!location && !adjusting && (
            <>
              <StaticGoogleMap
                zoom={18}
                size="400x400"
                className="img-fluid"
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <Marker location={location} color="red" label="S" />
              </StaticGoogleMap>
              <button
                className="btn btn-primary"
                onClick={() => setAdjusting(true)}
              >
                Ajustar marcador
              </button>
            </>
          )}
          {!!location && adjusting && (
            <GoogleMap
              lat={+location.split(",")[0]}
              lng={+location.split(",")[1]}
              updateMarker={updateMarker}
            />
          )}
          <div className="form-group">
            <label htmlFor="product_sku">Dirección</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="product_sku">Ciudad</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="product_sku">Código postal</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="product_sku">Estado</label>
            <br />
            <select
              className="custom-select form_required"
              id="branch_state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="0">-</option>
              <option value="1">Aguascalientes</option>
              <option value="2">Baja California</option>
              <option value="3">Baja California Sur</option>
              <option value="4">Campeche</option>
              <option value="5">Coahuila de Zaragoza</option>
              <option value="6">Colima</option>
              <option value="7">Chiapas</option>
              <option value="8">Chihuahua</option>
              <option value="9">Distrito Federal</option>
              <option value="10">Durango</option>
              <option value="11">Guanajuato</option>
              <option value="12">Guerrero</option>
              <option value="13">Hidalgo</option>
              <option value="14">Jalisco</option>
              <option value="15">México</option>
              <option value="16">Michoacán de Ocampo</option>
              <option value="17">Morelos</option>
              <option value="18">Nayarit</option>
              <option value="19">Nuevo León</option>
              <option value="20">Oaxaca</option>
              <option value="21">Puebla</option>
              <option value="22">Querétaro</option>
              <option value="23">Quintana Roo</option>
              <option value="24">San Luis Potosí</option>
              <option value="25">Sinaloa</option>
              <option value="26">Sonora</option>
              <option value="27">Tabasco</option>
              <option value="28">Tamaulipas</option>
              <option value="29">Tlaxcala</option>
              <option value="30">Veracruz de Ignacio de la Llave</option>
              <option value="31">Yucatán</option>
              <option value="32">Zacatecas</option>
              <option value="33">Ciudad de México</option>
            </select>
          </div>
          <hr />
          <h4>
            Datos Privados{" "}
            <small className="text-muted">
              {" "}
              <i className="fas fa-exclamation-triangle "></i> Solo Foo puede
              ver y usar estos datos
            </small>
          </h4>
          <div className="form-group">
            <label htmlFor="branch_contact">Nombre de Contacto</label>
            <input
              type="text"
              className="form-control form_required"
              id="branch_contact"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
            <small className="form-text text-muted">
              Con quién nos podemos dirigir en caso de alguna duda o aclaración?{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="contact_phone">Teléfono del Contacto</label>
            <input
              type="tel"
              className="form-control form_required"
              id="contact_phone"
              value={privatePhone}
              onChange={(e) => setPrivatePhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          {!!id && (
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={sendDeletePromt}
            >
              Borrar Sucursal
            </button>
          )}
          <button
            className="btn btn-primary"
            type="button"
            id="save_branch_btn"
            onClick={saveBranch}
          >
            <i className="far fa-save"></i> Guardar
          </button>
        </div>
      </div>
      <DeleteModal
        open={showDelete}
        title={`¿Borrar sucursal ${name}?`}
        toggle={() => {
          setShowDelete(false);
        }}
        handleConfirm={sendDelete}
        confirm_txt={`Borrar sucursal ${name}`}
      >
        <p>Esta acción no se puede revertir</p>
      </DeleteModal>
    </div>
  );
};

export default BranchGeneral;
