import { gql } from "@apollo/client";

export const GETSALES = gql`
  query getSales($branch_id: Int, $startDate: String, $endDate: String) {
    getSales(branch_id: $branch_id, startDate: $startDate, endDate: $endDate) {
      posSales {
        count
        total
      }
      onlineSales {
        count
        total
      }
      fees
      orders {
        id
        type
        total
        creation
        creation_date
        delivery
        cash_income
        resume_txt
        delivery_type
        payment_type
        status
        payed
        table {
          id
          name
        }
        products {
          id
          qty
          price
          ingredients
          ingredients_txt
          ingredients_opt
          size
          product {
            id
            title
            model
          }
        }
      }
    }
  }
`;
