import React from "react";

import { Product } from "../../../../models/product_model";

import ProductImage from "./ProductImage";

type Props = {
  product: Product;
};

const ProductInfoPlain: React.FC<Props> = ({ product }) => {
  return (
    <div className="material_card p-3">
      <h4 className="mb-4">Información de Producto</h4>
      <div className="row">
        <div className="product_imgs col-lg-6 mb-3">
          <ProductImage images={product.images} productId={+product.id} />
        </div>
        <div className="col-lg-8">
          <div className="form-group">
            <label htmlFor="product_title">Título del producto</label>
            <input
              type="text"
              className="form-control"
              data-type="title"
              value={product.title}
              disabled={true}
              autoComplete="off"
            />
            <small className="form-text text-muted">
              Máximo 150 caracteres{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="product_sku">SKU</label>
            <input
              type="text"
              className="form-control text-uppercase"
              data-type="sku"
              placeholder="PROD-01"
              disabled={true}
              value={product.model}
              autoComplete="new-password"
            />
            <small className="form-text text-muted">
              Este es el identificador único de tu producto para los reportes de
              ventas.
            </small>
            {!product.id ? (
              <small className="form-text text-danger">
                Una vez guardado el producto ya no lo podrás editar
              </small>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="">Descripción larga</label>
            <textarea
              value={product.description}
              disabled={true}
              className="form-control"
              name="desc"
              rows={3}
            />
            <small className="form-text text-muted">
              Cuéntales porque este producto es lo mejor que pueden probar{" "}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="product_price">Precio</label>
            {product?.sizes?.length > 0 ? (
              <div
                className="alert alert-info"
                role="alert"
                id="single_size_text"
              >
                <i className="fas fa-exclamation-triangle"></i>{" "}
                <small>Precio definido por tamaños</small>
              </div>
            ) : (
              <>
                {" "}
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    data-type="price"
                    name="product_price"
                    aria-label="Amount (to the nearest dollar)"
                    disabled={true}
                    value={product.price}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoPlain;
