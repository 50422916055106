import React from "react";
export type CtasProps = {
  btns: btnAction[];
};

export type btnAction = {
  txt: string;
  icon?: string;
  classes?: string;
  type?: "closed" | "started" | "in" | "out";
  fun?: (ev?: any) => void;
};

const Ctas = ({ btns }: CtasProps) => {
  return (
    <>
      {btns.map((btn, key) => (
        <button
          key={`Ctas_key_${key}`}
          className={`btn py-5 btn-block btn-${btn.classes}`}
          onClick={btn.fun}
          data-type={btn.type}
        >
          <i className={`mr-2 ${btn.icon}`}></i>
          {btn.txt}
        </button>
      ))}
    </>
  );
};

export default Ctas;
