import React, { useEffect, useState } from "react";
import { lang } from "../../../middleware/lang";
import { LoaderDots } from "../../../middleware/loaders";
import { HeaderTitle } from "../common/titles";
import { GET_PRODUCT_COUNT, GET_PRODUCT_LIST } from "./queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Product } from "../../../models/product_model";
import { useHistory } from "react-router-dom";
import Pagination from "./pagination";
import { GETPRODUCTS } from "../orders/queries";
import { has_access } from "../../../middleware/privileges";

const PAGE_SIZE = 20;

const btn = {
  txt: lang("Nuevo Producto", "New Product"),
  icon: "fa fa-plus",
  link: "/products/new",
};

const ProductList: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data: productsDb } = useQuery(GETPRODUCTS, {
    fetchPolicy: "no-cache",
  });
  const { data: getProductCount } = useQuery(GET_PRODUCT_COUNT, {
    fetchPolicy: "no-cache",
  });
  const [fetchProducts, { data }] = useLazyQuery(GET_PRODUCT_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const numToSkip = (page - 1) * PAGE_SIZE;
    fetchProducts({ variables: { take: PAGE_SIZE, skip: numToSkip } });
    //eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    if (productsDb) {
      const categories = [...productsDb.getCategories].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      const menu_obj = {
        products: [...productsDb.getProducts],
        categories,
      };
      localStorage.setItem("menu_all", JSON.stringify(menu_obj));
    }
  }, [productsDb]);

  if (!data) {
    return <LoaderDots fullSize />;
  }

  return (
    <>
      <HeaderTitle
        title={lang("Productos", "Products")}
        btn={has_access(256) ? btn : null}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="material_card p-3 table-responsive">
            <table className="table table-hover product_table vertical_middle">
              <thead className="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Producto</th>
                  <th scope="col">Precio</th>
                  <th scope="col">Tipo</th>
                </tr>
              </thead>
              <tbody>
                {data.getProducts.map((product: Product) => (
                  <tr
                    className="filter_list  cat_Hamburguesa"
                    onClick={() => history.push(`/products/edit/${product.id}`)}
                  >
                    <th scope="row">#{product.id}</th>
                    <td>
                      <img
                        src={
                          product.images.length
                            ? product.images[0].url
                                .replace(
                                  "ftp-foo.s3.amazonaws.com",
                                  "cdn.foopos.mx"
                                )
                                .replace(
                                  "s3.amazonaws.com/ftp-foo",
                                  "cdn.foopos.mx"
                                )
                            : "https://s3.amazonaws.com/ftp-foo/page_assets/no_photo_04.jpg"
                        }
                        className="img-thumbnail xs_hidden mr-1"
                        alt="Hamburguesa"
                      />
                      {product.title}
                    </td>
                    <td>{`$${product.price}`}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link text-left text-capitalize w-100"
                      >
                        {product.category?.name}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            onClick={(p) => setPage(p)}
            pageSize={PAGE_SIZE}
            page={page}
            totalCount={
              getProductCount ? getProductCount.getProductCount : null
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
