export enum DeliveryType {
 delivery = "delivery",
 pickup = "pickup",
 other_apps = "other_apps",
 for_here = "for_here"
};

export type PaymentType =
  | "cash"
  | "online"
  | "card_pos"
  | "terminal"
  | "other_apps";
