import { gql } from "@apollo/client";

export const MAKEORDER = gql`
  mutation newOrder(
    $order: OrderInput
    $printTicket: Boolean
    $setAsReady: Boolean
  ) {
    makeOrder(
      order: $order
      printTicket: $printTicket
      setAsReady: $setAsReady
    ) {
      id
      status
      status_color
      subtotal
      total
      creation
      modification
      user_comments
      client_comments
      resume_txt
      delivery_type
      delivery
      payment_type
      payed
      createdProducts
      branch {
        id
        name
      }
      client {
        id
        name
        lastname
        phone
      }
      user {
        id
        name
        lastname
      }
      table {
        id
        name
      }
      products {
        id
        status
        price
        ingredients_txt
        comments
        qty
        qty_done
        product {
          id
          title
          images {
            url
          }
        }
      }
    }
  }
`;

export const GETORDER = gql`
  query getOrder($order_id: Int!) {
    getOrder(id: $order_id) {
      id
      type
      status
      status_color
      subtotal
      total
      creation
      creation_date
      modification
      user_comments
      client_comments
      resume_txt
      delivery_type
      payment_type
      payed
      address {
        id
      }
      branch {
        id
        name
      }
      client {
        id
        name
        lastname
        phone
      }
      user {
        id
        name
        lastname
      }
      table {
        id
        name
      }
      products {
        id
        status
        price
        ingredients_txt
        comments
        qty
        ingredients
        ingredients_opt
        product {
          id
          title
          images {
            url
          }
        }
      }
    }
  }
`;

export const GETCLIENTS = gql`
  query getClients($limit: Int, $search: String, $skip: Int) {
    getClients(take: $limit, skip: $skip, search: $search) {
      id
      name
      lastname
      email
      profile_pic
      full_name
      phone
    }
  }
`;

export const GETADDRESS = gql`
  query getUserAddress($client_id: Int!, $branch_id: Int!) {
    getAddresses(user: $client_id, branch_id: $branch_id) {
      id
      zip
      city
      name
      status
      is_main
      address
      comments
      shipping_cost
      in_delivery_area
      state {
        short
      }
    }
  }
`;

export const SAVEADDRESS = gql`
  mutation saveUserAddress($address: AddressInput!) {
    setUserAddress(address: $address) {
      id
      zip
      city
      name
      status
      is_main
      address
      comments
      shipping_cost
      in_delivery_area
      state {
        short
      }
    }
  }
`;
export const SAVEUSER = gql`
  mutation saveUser($user: UserInput!, $order_id: Int) {
    saveUser(user: $user, order_id: $order_id) {
      id
      full_name
      name
      lastname
      email
      phone_verified
      profile_pic
      username
      phone
    }
  }
`;
