import React from "react";
import Autocomplete from "react-google-autocomplete";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoaderDots } from "../../middleware/loaders";

import { lang } from "../../middleware/lang";

export const AddUserModal = ({
  open,
  toggle,
  className,
  keyupFun,
  users,
  selectClient,
}) => (
  <Modal isOpen={open} toggle={toggle} className={className}>
    <ModalHeader toggle={toggle}>
      {lang("Buscar Cliente", "Find User")}
    </ModalHeader>
    <ModalBody>
      <div className="input-group">
        <input
          type="tel"
          className="form-control form-control-lg"
          placeholder="Nombre o Teléfono del Cliente"
          autoComplete="false"
          onKeyUp={keyupFun}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-primary py-3 action_button"
            onClick={selectClient}
          >
            Buscar
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-1 text-danger display_none">
          {" "}
          <p className="text-danger text-right">
            <i className="fa fa-exclamation-triangle"></i>
          </p>
        </div>
        <div className="col-11 text-danger display_none">
          {" "}
          <p className="text-danger">
            <span>Agrega el teléfono del cliente</span>
          </p>
        </div>
      </div>

      <h2 className="mt-3">Clientes Recientes</h2>
      <div className="material_card pos_panel p-3 scrolleable">
        {users
          ? renderClients(users, selectClient)
          : lang("Sin clientes recientes", "No register buyer")}
      </div>
    </ModalBody>
    <ModalFooter></ModalFooter>
  </Modal>
);

export const AddUserDataModal = ({
  open,
  toggle,
  className,
  nextFun,
  buyer,
  handleType,
  save = false,
}) => (
  <Modal isOpen={open} toggle={toggle} className={className}>
    <ModalHeader toggle={toggle}>
      {lang("Agregar Cliente", "Add Client")}
    </ModalHeader>
    <ModalBody>
      <p className="display_none">
        <i className="fas fa-exclamation-circle text-warning"></i> Confirmar
        Información del cliente
      </p>
      <h6 className="mt-3">Nombre del Cliente</h6>
      <div className="form-row mb-3">
        <div className="col">
          <input
            onChange={handleType}
            data-type="name"
            type="text"
            className="form-control"
            placeholder="Nombre"
            value={buyer.name}
            autoComplete="nope"
          />
        </div>
        <div className="col">
          <input
            onChange={handleType}
            data-type="lastname"
            type="text"
            className="form-control"
            placeholder="Apellido"
            value={buyer.lastname}
            autoComplete="nope"
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="inputAddress">
          Email Cliente <small>Opcional</small>
        </label>
        <input
          onChange={handleType}
          data-type="email"
          type="email"
          autoComplete="nope"
          className="form-control"
          value={buyer.email}
        />
      </div>
      {/* <p className="text-danger">
        <i className="fa fa-exclamation-triangle"></i> Favor de agregar nombre y
        apellido del cliente
      </p> */}
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className="btn btn-block btn-raised btn_xs_lg btn-block ripple-effect action_button btn-primary"
        onClick={nextFun}
      >
        {save ? "Guardar" : "Siguiente"}
      </button>
    </ModalFooter>
  </Modal>
);

export const AddAddressModal = ({
  open,
  toggle,
  className,
  modalHandler,
  clientAddress,
  loadingAddress,
  selectClientAddress,
}) => {
  console.log("userInput.js:140 | clientAddress", clientAddress);
  return (
    <Modal isOpen={open} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>
        {lang("Dirección del Cliente", "Client Address")}
      </ModalHeader>
      <ModalBody>
        {loadingAddress && <LoaderDots />}
        {clientAddress && clientAddress.length > 0 ? (
          clientAddress.map((item) => (
            <AddressCard
              key={`address_card_${item.id}`}
              address={item}
              selectClientAddress={selectClientAddress}
            />
          ))
        ) : (
          <div className="">
            {lang("Sin direcciones guardadas", "No saved Address")}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-block btn-raised btn_xs_lg btn-block ripple-effect action_button btn-info"
          onClick={() => modalHandler("addnewuseraddress")}
        >
          Agregar Dirección Nueva
        </button>
      </ModalFooter>
    </Modal>
  );
};

export const AddNewAddressModal = ({
  open,
  toggle,
  className,
  handleNewAddress,
  hadleAddressValue,
  saveUserAddress,
  address,
  loading,
  country = "mx",
  google_maps,
}) => (
  <Modal isOpen={open} toggle={toggle} className={className} autoFocus={false}>
    <ModalHeader toggle={toggle}>
      {lang("Nueva Dirección", "New Client Address")}
    </ModalHeader>
    <ModalBody>
      <div className="initial ">
        <p className="display_none">
          <i className="fas fa-exclamation-circle text-warning"></i> Confirmar
          Información del cliente
        </p>
        <div>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputAddress">Dirección</label>
              {google_maps ? (
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handleNewAddress}
                  data-type="address"
                  onChange={hadleAddressValue}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country },
                  }}
                  placeholder={lang(
                    "Dirección del cliente",
                    "Customer address"
                  )}
                  className="form-control"
                  autoFocus
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  placeholder="Calle, número y colonia"
                  value={address.address}
                  onChange={hadleAddressValue}
                  data-type="address"
                  autoComplete="nope"
                />
              )}
              <div className="invalid-feedback ">
                <span>Ingresa la dirección válida del cliente</span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="inputCity">Comentarios Adicionales</label>
            <textarea
              className="form-control"
              autoComplete="off"
              name="comments"
              value={address.comments}
              onChange={hadleAddressValue}
              data-type="comments"
              placeholder="Color de la casa? Cuántos pisos es? Tiene barandal?"
            ></textarea>
            <small className="form-text text-muted">
              Ayuda al repartidor a encontrar el lugar más fácil.
            </small>
          </div>
        </div>

        <p className="text-danger d-none">
          <i className="fa fa-exclamation-triangle"></i> Favor de agregar nombre
          y apellido del cliente
        </p>
      </div>

      <div className="show_error text-center display_none">
        <span className="fa-stack fa-4x">
          <i className="fas fa-map-marked-alt fa-stack-1x"></i>
          <i className="fas fa-ban fa-stack-2x"></i>
        </span>
        <p>
          Esta dirección está fuera del rango de reparto <br />
          Invita al cliente a pasar a recoger su pedido
        </p>
        <button type="button" className="btn btn-outline-info my-3">
          Seleccionar de igual forma
        </button>
        <button className="btn btn-block btn-outline-dark py-4">
          Para Llevar
        </button>
        <button className="btn btn-block btn-outline-secondary py-3">
          Cambiar dirección
        </button>
        <button className="btn btn-block btn-link" data-dismiss="modal">
          Cancelar
        </button>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className="btn btn-block btn-raised btn_xs_lg btn-block ripple-effect action_button btn-secondary btn_lg"
        onClick={saveUserAddress}
        disabled={loading}
      >
        {loading
          ? lang("Guardando...", "Saving...")
          : lang(
              "Guardar Dirección y Levantar Pedido ",
              "Save Address and Make Order"
            )}
      </button>
    </ModalFooter>
  </Modal>
);

export const ShippingPriceModal = ({
  open,
  toggle,
  className,
  saveUserAddress,
  address,
  hadleAddressValue,
}) => (
  <Modal isOpen={open} toggle={toggle} className={className} autoFocus={false}>
    <ModalHeader toggle={toggle}>
      {lang("Costo de Envío", "Shipping Cost")}
    </ModalHeader>
    <ModalBody>
      <div class="modal-body">
        <div class="d-sm-block w-100 justify-content-between">
          <div class="" data-toggle="buttons">
            <div class="input-group mb-3 input-group-lg">
              <input
                type="tel"
                class="form-control text-center border-0 display_10"
                aria-label="Product Quantity"
                data-type={"shipping_cost"}
                value={address.shipping_cost}
                onChange={hadleAddressValue}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className={`${
          address && address.shipping_cost ? "btn-danger" : "btn-secondary"
        } btn btn-block btn-raised btn_xs_lg btn-block ripple-effect action_button  btn_lg`}
        onClick={saveUserAddress}
      >
        {lang("Hacer Pedido ", "Make Order")}
      </button>
    </ModalFooter>
  </Modal>
);

const ClientLi = ({ client, selectClient, client_key }) => (
  <div className="col-12 full_link_parent pb-2">
    <button
      className="btn full_link w-100"
      type="button"
      data-client_key={client_key}
      onClick={selectClient}
    ></button>
    <div className="media text-muted">
      <p className="media-body m-0 small lh-125 border-bottom border-gray">
        <strong className="d-block text-gray-dark">{client.full_name}</strong>
        {client.phone}
      </p>
    </div>
  </div>
);

function renderClients(clients, selectClient) {
  console.log("userInput.js:79 | clients", clients);
  return Object.keys(clients).map((key) => {
    const client = clients[key];
    return (
      <ClientLi
        key={`client_key_${client.id}`}
        client={client}
        client_key={key}
        selectClient={selectClient}
      />
    );
  });
}

const AddressCard = ({ address, selectClientAddress }) => (
  <div className="card m-2 p-2 text-left">
    <h4 className="mt-2 mb-0">
      <i className="fa fa-star" aria-hidden="true"></i> {address.name}
    </h4>
    <p className="mt-1">
      <span>
        {address.address}
        <br />
        {address.city} , {address.state.short} {address.zip}
      </span>

      <br />
      {address.comments && (
        <small>
          <i className="far fa-comment" aria-hidden="true"></i>{" "}
          <span>{address.comments}</span>
        </small>
      )}
    </p>
    <button
      type="button"
      className="btn btn-primary btn-block"
      data-address_id={address.id}
      onClick={selectClientAddress}
    >
      + ${address.shipping_cost} de Envío
    </button>
  </div>
);
