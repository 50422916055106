import React from "react";
import { Route } from "react-router";
import BranchForm from "./branchForm";
import BranchList from "./branchList";

const Branches: React.FC = () => {
  return (
    <div className="container py-4">
      <Route exact path="/branches" component={BranchList} />
      <Route path="/branches/edit/:id" component={BranchForm} />
      <Route path="/branches/create" component={BranchForm} />
    </div>
  );
};

export default Branches;
