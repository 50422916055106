import React from "react";

type Props = {
  title: string;
  selectedCat: string;
  click: () => void;
  items: any[];
};

const Groups: React.FC<Props> = ({ title, selectedCat, click, items }) => (
  <div className="p_groups">
    <h3 className="mt-4 mb-0"> {title}</h3>
    <div className="row px-2">
      {items &&
        Object.keys(items).map((item: any) => {
          const category = items[item];
          const classes = [
            "btn",
            "btn-outline-info",
            "btn-block",
            "btn_xs_lg",
            "btn_groups",
          ];

          if (selectedCat === category.id) {
            classes.push("active");
          }
          return (
            <div className="col my-2 px-1" key={category.id}>
              <button
                type="button"
                className={classes.join(" ")}
                data-cat_id={category.id}
                onClick={click}
              >
                {category.name}
              </button>
            </div>
          );
        })}
    </div>
  </div>
);

export default Groups;
