import React from "react";

type Props = {
  name: string;
  value: string;
  link: string;
  linkName?: string;
  logo: React.ReactNode;
};

const SocialInput: React.FC<Props> = ({
  value,
  name,
  link,
  linkName,
  logo,
}) => {
  return (
    <div className="media text-muted pt-3">
      {logo}
      <div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
        <div className="d-flex justify-content-between align-items-center w-100 position-relative">
          <strong className="text-gray-dark text-capitalize">{name}</strong>
          <button
            type="button"
            className="btn btn-link btn-sm text-capitalize text-primary"
          >
            Cambiar
          </button>
          <input
            className="form-control form-control-sm form-absolute display_none "
            value={value}
            id="form_foo"
          />
        </div>
        <span className="d-block">
          <a
            className="text-muted form_foo"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {linkName || link}
          </a>
        </span>
      </div>
    </div>
  );
};

export default SocialInput;
