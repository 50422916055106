import React from "react";
import { Link } from "react-router-dom";
import { deliveryText, getTableName, paymentText } from "../../../lang/orders";
import { formatNumber } from "../../../middleware/common-functions";
import { toDate } from "../../../middleware/dates";
import { OrderType } from "../../../models/order_model";
import { SalesDBType } from "../../../models/sales_model";

type SalesOrdersProps = {
  sales_data: SalesDBType;
};

const SalesOrders: React.FC<SalesOrdersProps> = ({ sales_data }) => {
  return <OrderGroup orders={sales_data.orders} />;
};

type GroupProps = {
  orders: OrderType[];
};

const OrderGroup: React.FC<GroupProps> = ({ orders }) => (
  <div className="row mt-3">
    <div className="col-12 ">
      <table className="table table-hover light_table  show_on_new ">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha</th>
            <th scope="col">Hora</th>
            <th scope="col">Productos</th>
            <th scope="col" className="sm_hidden">
              Pago
            </th>
            <th scope="col" className="sm_hidden">
              Mesa
            </th>
            <th scope="col" className="sm_hidden">
              Tipo
            </th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody id="order_list">
          {orders
            ? orders.map((item, key) => (
                <List key={`${key}_${item.id}`} item={item} />
              ))
            : null}
        </tbody>
      </table>
    </div>
  </div>
);

type ListProps = {
  item: OrderType;
};

const List: React.FC<ListProps> = ({ item }) => {
  const delivery = deliveryText(item.delivery_type);
  const payment = paymentText(item.payment_type);
  const table_name = getTableName(item.table);
  return (
    <tr data-order_id={item.id}>
      <td className="order_id_row">
        <b>
          <Link to={`/order/${item.id}`}>{item.id}</Link>
        </b>
      </td>
      <td>{toDate(item.creation_date, {}, "date")}</td>
      <td>{toDate(item.creation_date, {}, "hour")}</td>
      <td> {item.resume_txt}</td>
      <td className="sm_hidden">{payment.txt}</td>
      <td className="table_row_id sm_hidden">{table_name}</td>
      <td className="sm_hidden">{delivery.txt}</td>
      <td>
        <b className="">${formatNumber(item.total)}</b>
      </td>
    </tr>
  );
};

export default SalesOrders;
