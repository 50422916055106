import React, { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

type Props = {
  updateData: (data: any) => void;
};

const GoogleAutocomplete: React.FC<Props> = ({ updateData }) => {
  const [show, setShow] = useState(false);

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      debounce: 700,
      options: {
        types: ["address"],
        componentRestrictions: { country: "mx" },
      },
    });

  const sendDetails = (place: any) => {
    const location = place.geometry.location;
    const data = {
      city: "",
      location: `${location.lat()},${location.lng()}`,
      address: "",
    };
    place.address_components.forEach((c: any) => {
      if (c.types.includes("route")) {
        data.address = c.long_name;
      }
      if (c.types.includes("locality")) {
        data.city = c.long_name;
      }
    });
    updateData(data);
  };

  const loadDetails = (item: any) => {
    setShow(false);
    placesService?.getDetails({ placeId: item.place_id }, (placeDetails: any) =>
      sendDetails(placeDetails)
    );
  };

  const renderItem = (item: any) => {
    return (
      <button onClick={() => loadDetails(item)} key={item.place_id}>
        <span>{item.description}</span>
      </button>
    );
  };

  return (
    <div className="google_autocomplete">
      <label htmlFor="product_title">Buscar dirección</label>
      <input
        id="product_title"
        className="form-control form_required"
        placeholder="Buscar la dirección"
        onChange={(evt: any) => {
          setShow(true);
          getPlacePredictions({ input: evt.target.value });
        }}
      />
      <div className="predictions material-shadows">
        {show && placePredictions.map((item) => renderItem(item))}
      </div>
    </div>
  );
};

export default GoogleAutocomplete;
