import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { LoaderDots } from "../../../middleware/loaders";
import { Branch } from "../../../models/partner_model";
import { HeaderTitle } from "../common/titles";
import { GetBranches, GET_BRANCHES } from "./queries";

const btn = {
  txt: "Agregar Sucursal",
  icon: "fa fa-plus",
  link: "/branches/create"
};

type Props = {
  branch: Branch
};

const BranchBlock: React.FC<Props> = ({ branch }) => {
  return (
    <div className="col-md-6">
      <div className="card flex-md-row mb-4 box-shadow h-md-250">
        <div className="card-body d-flex flex-column align-items-start">
          <strong className="d-inline-block mb-2 text-primary">{branch.name}<small>{branch.is_main ? ' Principal' : ' Sucursal'}</small></strong>
          <h3 className="mb-0"> 
            <Link className="text-dark" to={`/branches/edit/${branch.id}`}>
              <small><i className="fas fa-map-marker"></i></small> {branch.address}
            </Link>
          </h3>
          <p className="card-text mb-2">
            <small><i className="fas fa-phone"></i></small>{branch.phone}
          </p>
          <div className="flex justify-content-between align-items-center">
            <Link to={`/branches/edit/${branch.id}`}>
              Modificar horarios o datos<i className="far fa-edit pl-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const BranchList: React.FC = () => {
  const { data: branches, loading } = useQuery<GetBranches>(GET_BRANCHES, {
    fetchPolicy: 'cache-and-network'
  });

  if (loading || !branches) {
    return <LoaderDots />;
  }

  return (
    <>
      <HeaderTitle title="Sucursales" btn={btn} />
      <div className="row mb-2">
        {branches.getBranches.map(branch => <BranchBlock branch={branch} key={`branch-${branch.id}`} />)}        
      </div>
    </>
  )
}

export default BranchList;
