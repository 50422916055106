import React from "react";
import { ProductActions } from "./actions";
import { initialProductState, ProductInput } from "./state";

export const ProductContext = React.createContext<{
  state: ProductInput;
  dispatch: React.Dispatch<ProductActions> 
}>({
  state: initialProductState,
  dispatch: () => undefined
});

