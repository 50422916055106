import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { SIGNUPMUTATION } from "./queries";
import foo_logo from "../../assets/foo_mascot_chef_hat@2x.svg";
import { ErrorType } from "../../../models/errors";
import { Settings } from "../../../models/settings_model";

const SignupPage = () => {
  const [error, setError] = useState<ErrorType | false>(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    company_name: "",
  });
  const [signupUser, { loading, error: signupError }] =
    useMutation(SIGNUPMUTATION);

  const perks = [
    "Reporte de ventas en tiempo real",
    "Cobros a distancia",
    "Vende en línea",
    "Menú Digital",
  ];

  useEffect(() => {
    if (signupError) {
      console.log("index.tsx:33 | signupError", JSON.stringify(signupError));
      setError({
        message: signupError.graphQLErrors[0]?.message,
      });
    }
  }, [signupError]);

  const handleType = (e: { target: HTMLInputElement }) => {
    const type = e.target.id;
    const value = e.target.value;
    setForm({ ...form, [type]: value });
  };

  const signupHandler = async () => {
    setError(false);
    const { firstName, lastName, email, password, phone, company_name } = form;
    ///validate string as email
    const email_regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.length < 6 || !email_regex.test(email)) {
      setError({ message: "El correo no es válido, favor de verificarlo" });
      return;
    }
    if (password.length < 6) {
      setError({ message: "La contraseña debe ser del al menos 6 dígitos" });
      return false;
    }
    if (company_name.length < 2) {
      setError({ message: "Favor de agregar el nombre de su negocio" });
      return false;
    }
    if (firstName.length < 2 || lastName.length < 2) {
      setError({ message: "Favor de agregar su nombre y apellidos" });
      return false;
    }
    if (phone.length < 2) {
      setError({ message: "Favor de agregar su número de telefono" });
      return false;
    }
    try {
      const { data: new_user_data } = await signupUser({
        variables: {
          email,
          password,
          company_name,
          name: firstName,
          lastname: lastName,
          phone,
        },
      });
      if (new_user_data.signUp) {
        const token = new_user_data.signUp.token;
        const user = { ...new_user_data.signUp.user };
        const settings = { ...new_user_data.signUp.settings };
        const settings_obj: Settings = {};
        Object.keys(settings).forEach((setting_key) => {
          const setting = settings[setting_key];
          const pref_key = setting.pref_key as keyof Settings;
          delete setting.pref_key;
          if (setting.pref_value === "true" || setting.pref_value === "false") {
            setting.pref_value = setting.pref_value === "true" ? true : false;
          } else if (!isNaN(setting.pref_value)) {
            setting.pref_value = parseInt(setting.pref_value);
          }
          settings_obj[pref_key] = setting.pref_value;
        });
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("settings", JSON.stringify(settings_obj));
        localStorage.setItem("token", token);
        window.location.href = "/welcome";
      }
    } catch (error) {
      console.log("index.tsx:105 | error", error);
    }
  };

  const enterPressed = (ev: KeyboardEvent) => {
    var code = ev.code || ev.key;
    if (code === "13") {
      //13 is the enter keycode
      signupHandler();
    }
  };

  return (
    <main>
      <div className="container signup_div">
        <div className="row justify-content-between align-items-center min-vh-100">
          <div className="col-md-4 text-center">
            <div className="row justify-content-center">
              <div className="col-6">
                <img src={foo_logo} className="img-fluid" alt="Foo Logo" />
              </div>
            </div>
            <h1>Comienza con tu cuenta de Socio</h1>
            <p>
              Consigue nuevos clientes y haz más felices a los que ya tienes.
            </p>
            <ul className="list-group list-group-flush text-left px-0">
              {perks.map((item, key) => (
                <li
                  className="list-group-item px-0"
                  key={`list-group-item${key}`}
                >
                  <i className="far fa-check-circle mr-1 text-success"></i>
                  {item}
                </li>
              ))}
              <li className="list-group-item px-0">
                <i className="far fa-check-square mr-1 text-success"></i>Y mucho
                más!
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-6">
                {" "}
                <FormGroup
                  enterPressed={enterPressed}
                  handleType={handleType}
                  name={"firstName"}
                  value={form.firstName}
                  label="Tu Nombre"
                />
              </div>
              <div className="col-6">
                <FormGroup
                  enterPressed={enterPressed}
                  handleType={handleType}
                  name={"lastName"}
                  value={form.lastName}
                  label="Tu Apellido"
                />
              </div>
            </div>
            <FormGroup
              enterPressed={enterPressed}
              handleType={handleType}
              name={"company_name"}
              value={form.company_name}
              label="Nombre de tu negocio"
            />
            <FormGroup
              enterPressed={enterPressed}
              handleType={handleType}
              name={"email"}
              value={form.email}
              label="Email"
            />
            <FormGroup
              enterPressed={enterPressed}
              handleType={handleType}
              name={"phone"}
              value={form.phone}
              placeholder="Incluye el código de área"
              help="A qué número te podemos contactar? No lo compartiremos con nadie. Mas delante pediremos la información pública de tu negocio"
              label="Número de Contacto"
            />
            <FormGroup
              enterPressed={enterPressed}
              handleType={handleType}
              name={"password"}
              type="password"
              value={form.password}
              label="Password"
            />
            {error ? (
              <div className="alert alert-warning">{error.message}</div>
            ) : null}
            <button
              type="button"
              className="btn btn-raised btn-primary btn-block btn_xs_lg "
              onClick={signupHandler}
              disabled={loading}
            >
              {loading ? "Estámos Creando tu Cuenta..." : "Crear Cuenta"}
            </button>
            <p>
              <small className="text-dark">
                Ya tienes cuenta? <Link to="/signup">Inicia Sesión Aquí</Link>
              </small>
            </p>

            <br />
            <br />
            <br />
            <a
              href="https://www.fooapp.mx/es/pages/signup"
              className="btn btn-sm btn-block btn-link"
            >
              <i className="fas fa-undo-alt text-primary"></i> Log in Para
              Usuarios
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

type FormGroupProps = {
  type?: "text" | "password";
  name: string;
  label: string;
  placeholder?: string;
  help?: string;
  value: string;
  handleType: any;
  enterPressed: any;
};
const FormGroup: React.FC<FormGroupProps> = ({
  handleType,
  enterPressed,
  type = "text",
  name,
  label,
  placeholder,
  help,
  value,
}) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <input
      type={type}
      name={name}
      id={name}
      className="form-control"
      value={value}
      placeholder={placeholder}
      onChange={handleType}
      onKeyPress={enterPressed}
    />
    {help ? <small className="text-secondary">{help}</small> : null}
  </div>
);

export default SignupPage;
