import React from "react";

import { useKeyPress } from "../../../../hooks/keyboard";
import { lang } from "../../../../middleware/lang";
import useSettings from "../../../../hooks/settings";

const Calculator = ({
  addUp,
  payOutBack,
  payOrder,
  charge_total,
  ready,
  type,
  setPaymentType,
  card_type,
}) => {
  const [settings] = useSettings();
  return (
    <div className="calulcator_numbers animate_all">
      <div className="row calc_container justify-content-between">
        <div className="col-md-12">
          <div className="row h-100 align-items-stretch">
            {settings.cards_type ? (
              <>
                <PaymentBtn
                  setPaymentType={setPaymentType}
                  selected={type === "terminal" && card_type === "debit"}
                  type="terminal"
                  card_type="debit"
                  text="Tarjeta de débito"
                  text_xs="TD"
                  icon="fas fa-credit-card"
                />
                <PaymentBtn
                  setPaymentType={setPaymentType}
                  selected={type === "terminal" && card_type === "credit"}
                  type="terminal"
                  card_type="credit"
                  text="Tarjeta de crédito"
                  text_xs="TC"
                  icon="fas fa-credit-card"
                />
              </>
            ) : (
              <PaymentBtn
                setPaymentType={setPaymentType}
                selected={type === "terminal"}
                type="terminal"
                text="Pagado con Terminal"
                text_xs="Términal"
                icon="fas fa-credit-card"
              />
            )}
            <PaymentBtn
              setPaymentType={setPaymentType}
              selected={type === "cash"}
              type="cash"
              text="Efectivo"
              text_xs="Efectivo"
              icon="fas fa-money-bill-wave"
            />
            {/* setCardType */}
          </div>
        </div>
        <div className="col-md-3 align-items-stretch xs_hidden">
          <button
            type="button"
            className="btn btn-link btn-block py-3 py-xl-5 btn_xs_lg_mob h-100"
            onClick={payOutBack}
          >
            {" "}
            {lang("Cancelar", "Cancel")}
          </button>
        </div>

        <div className="col-md-5">
          <div className="row">
            {renderButtons(addUp)}
            <Button num="." addUp={addUp} secondary />
            <Button num="0" addUp={addUp} />
            <Button icon="fas fa-caret-left" addUp={addUp} secondary />
          </div>
        </div>

        <div className="col-md-3 payout_div">
          <button
            type="button"
            className={`btn btn-block py-3 py-xl-5 btn_xs_lg_mob h-100 border_btn ${
              ready ? "btn-danger" : "btn-outline-primary"
            }`}
            onClick={ready ? payOrder : () => {}}
          >
            {charge_total
              ? lang("Cobrar Total", "Charge Total")
              : ready
              ? lang("Cobrar", "Charge")
              : lang("Monto incompelto", "Split")}
          </button>
        </div>
      </div>
    </div>
  );
};

const Button = ({ addUp, icon, num, secondary }) => {
  const display = icon ? <i className={icon}></i> : num;
  const classes = ["btn btn-outline-dark btn-block py-3 py-xl-4"];
  const amount = icon ? "rest" : num;
  const keyNames = num ? [`${num}`] : ["Delete", "Backspace"];
  useKeyPress(keyNames, () => addUp(amount));

  if (secondary) {
    classes.push("btn_secondary");
  }

  return (
    <div className="col-4 p-1 m-0">
      <button
        type="button"
        className={classes.join(" ")}
        onClick={() => addUp(amount)}
      >
        {display}
      </button>
    </div>
  );
};

function renderButtons(addUp) {
  const buttons = [];
  for (let index = 1; index <= 9; index++) {
    buttons.push(<Button addUp={addUp} key={index} num={index} />);
  }
  return buttons;
}

const PaymentBtn = ({
  setPaymentType,
  selected,
  type,
  card_type,
  text,
  text_xs,
  icon,
}) => (
  <div className="col  pl-0 pr-1 py-1 m-0">
    <button
      type="button"
      className={`btn ${
        selected ? "btn-dark" : ""
      } btn-block py-3 py-xl-5 btn_xs_lg_mob h-100`}
      data-type={type}
      data-card={card_type}
      onClick={setPaymentType}
    >
      {" "}
      <i className={`mr-2 ${icon}`}></i>{" "}
      <span className="xs_hidden">{text}</span>
      <span className="d-sm-none">{text_xs}</span>
    </button>
  </div>
);

export default Calculator;
