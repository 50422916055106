import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

import useSettings from "../../../hooks/settings";
import { LoaderDots } from "../../../middleware/loaders";
import NavMob from "../../nav/headerMob";
import OrderPage from "./order";
import PayOut from "./payout/payout";
import { PrinterContext } from "../../../context/printer-context";
import {
  GETORDER,
  PAYORDER,
  UPDATEORDER,
  UPDATEPRODUCT,
  UPDATETABLE,
} from "./queries";
import { GETCLIENTS, SAVEUSER } from "../pos/queries";
import { ModalTables, AddUserModal, AddUserDataModal } from "../../modals";

const Order = ({ history }) => {
  let timer = null;
  const tables = JSON.parse(localStorage.getItem("tables"));

  const [settings] = useSettings();
  const [modals, setModals] = useState({});
  const [loading, setLoading] = useState(true);
  const [shake, setShake] = useState(true);
  const [calculator, setCalculator] = useState({});
  const [status, setStatus] = useState(null);
  const [order, setOrder] = useState({});
  const [payOrderMutation] = useMutation(PAYORDER);
  const [updateOrderMutation] = useMutation(UPDATEORDER);
  const [updateTableMutation] = useMutation(UPDATETABLE);
  const [saveUserMutation] = useMutation(SAVEUSER);
  const [buyer, setBuyer] = useState(null);
  const [updateProductMutation] = useMutation(UPDATEPRODUCT);
  const printer = useContext(PrinterContext);
  const [getClients, { data: clients }] = useLazyQuery(GETCLIENTS, {
    fetchPolicy: "no-cache",
  });
  const { order_id } = useParams();
  const order_id_int = parseInt(order_id);
  const { data: order_db, loading: order_loading } = useQuery(GETORDER, {
    variables: { order_id: order_id_int },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    ///update localStorage orders
    const local_orders = JSON.parse(localStorage.getItem("all_orders") || "[]");
    const order_index = local_orders.findIndex((o) => o.id === order_id);
    local_orders[order_index] = { ...order };
    localStorage.setItem("all_orders", JSON.stringify(local_orders));
  }, [order, order_id]);

  useEffect(() => {
    if (order_db) {
      setOrder(order_db.getOrder);
      setLoading(false);
    }
  }, [order_db]);

  useEffect(() => {
    if (shake) {
      setTimeout(() => {
        setShake(false);
      }, 500);
    }
  }, [shake]);

  const payOut = () => {
    setStatus("payout");
  };

  const payOutBack = () => {
    setStatus(null);
  };

  const addUp = (add) => {
    const set_calculator = { ...calculator };
    let amount = set_calculator.amount || "";
    if (isNaN(add) && add !== ".") {
      amount = amount.slice(0, -1);
    } else {
      amount = amount + add;
    }
    set_calculator.cash_diff = order.total - parseFloat(amount);
    set_calculator.amount = amount;
    setCalculator({ ...set_calculator });
  };

  const setPaymentType = async (ev) => {
    const set_order = { ...order };
    const type = ev.currentTarget.dataset.type;
    const card_type = ev.currentTarget.dataset.card;
    set_order.payment_type = type;
    set_order.card_type = card_type || undefined;
    setOrder({ ...set_order });
  };

  const payOrder = async (ev) => {
    const set_calculator = { ...calculator };
    const set_order = { ...order };
    if (set_calculator.cash_diff > 0 && set_order.payment_type === "cash") {
      setShake(true);
    }
    let total_income = set_calculator.amount || set_order.total;
    const paymentInput = {
      order_id: parseInt(order_id),
      cash: total_income ? parseFloat(total_income) : set_order.total,
      type: set_order.payment_type,
      card_type: set_order.card_type,
    };
    try {
      const { data: updatedOrder } = await payOrderMutation({
        variables: { payment: paymentInput },
      });
      set_order.payed = updatedOrder.payOrder.payed;
      set_order.cash_income = updatedOrder.payOrder.cash_income;
      setOrder({ ...set_order });
      setStatus(null);
      ///print customer ticket
      if (set_order.payed) {
        if (settings.printer) {
          printer.print(set_order, "client");
        }
        if (!settings.avoid_fulfilled) {
          updateOrder("fulfilled");
        }
        history.push("/");
      }
    } catch (error) {
      console.log("index.js:74 | error", error);
    }
  };

  const handleBuyerType = async (ev) => {
    const current_buyer = { ...buyer };
    const type = ev.target.dataset.type;
    const value = ev.target.value;

    current_buyer[type] = value;
    setBuyer(current_buyer);
  };

  const saveUserPromt = async (ev) => {
    getClients({
      variables: {
        limit: 5,
      },
    });
    modalHandler("adduser");
  };

  const saveUser = async (ev) => {
    const set_order = { ...order };
    try {
      const userInput = {
        id: buyer.id || null,
        name: buyer.name,
        lastname: buyer.lastname,
        phone: buyer.phone,
        email: buyer.email || null,
      };
      const new_client = await saveUserMutation({
        variables: { user: userInput, order_id: parseInt(order_id) },
      });
      set_order.client = new_client.data.saveUser;
      setOrder({ ...set_order });
      modalHandler("adduserdata");
    } catch (error) {
      console.log("index.js:74 | error", error);
    }
  };

  const selectClient = (ev) => {
    let client_key = ev.currentTarget.dataset.client_key;
    if (!client_key) {
      client_key = 0;
    } else {
      const selBuyer = clients.getClients[client_key];
      setBuyer({ ...selBuyer });
    }
    modalHandler("adduserdata");
  };

  const searchUserPromt = (input) => {
    clearTimeout(timer);
    const text = input.target.value;
    const current_buyer = { ...buyer };
    current_buyer.phone = text;
    setBuyer(current_buyer);
    timer = setTimeout(() => {
      getClients({
        variables: {
          search: text,
          limit: 5,
        },
      });
    }, 500);
  };

  const updateOrder = async (status) => {
    if (!status) return;
    try {
      const updateOrderInput = {
        order_id: parseInt(order.id),
        status,
      };
      await updateOrderMutation({ variables: { order: updateOrderInput } });

      history.push("/");
    } catch (error) {
      console.log("index.js:90 | error", error);
    }
  };

  const modalHandler = (type) => {
    const curreent_modals = { ...modals };
    const new_modals = {};
    new_modals[type] = !curreent_modals[type];
    setModals({ ...new_modals });
  };

  const promtChangeTable = () => {
    setModals({
      tables: true,
    });
  };

  const changeTable = async (ev) => {
    const table_id = ev.currentTarget.dataset.table_id;
    const selected_table = tables.find((t) => t.id === table_id);

    try {
      const updateOrderInput = {
        order_id: parseInt(order.id),
        table_id: +table_id,
      };
      await updateTableMutation({ variables: { order: updateOrderInput } });
      const set_order = { ...order };

      set_order.table.id = table_id;
      set_order.table.name = selected_table.name || `#${table_id}`;
      setOrder({ ...set_order });
      setModals({});
    } catch (error) {
      console.log("index.js:105 | error", error);
    }
  };

  const productAsDone = (ev) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const set_order = { ...order };
    const product_index = set_order.products.findIndex(
      (p) => p.id === product_id
    );
    let status = set_order.products[product_index].status;
    let new_status;

    switch (status) {
      case "1":
        new_status = "pending";
        break;

      default:
        new_status = "1";
        break;
    }
    const productInput = {
      id: parseInt(product_id),
      status: new_status,
    };
    updateProductMutation({
      variables: {
        product: productInput,
      },
    });
    const qty_done = set_order.products[product_index].qty_done
      ? set_order.products[product_index].qty_done + 1
      : 1;
    set_order.products[product_index].qty_done = qty_done;
    if (
      set_order.products[product_index].qty ===
      set_order.products[product_index].qty_done
    ) {
      set_order.products[product_index].status = 1;
    }
    setOrder({ ...set_order });
  };

  const renderUI = () => {
    switch (status) {
      case "payout":
        return (
          <PayOut
            order={order}
            addUp={addUp}
            calculator={calculator}
            payOutBack={payOutBack}
            payOrder={payOrder}
            shake={shake}
            setPaymentType={setPaymentType}
          />
        );

      default:
        return (
          <OrderPage
            loading={loading}
            order={order}
            payOut={payOut}
            updateOrder={updateOrder}
            saveUser={saveUserPromt}
            changeTable={promtChangeTable}
            productAsDone={productAsDone}
            print={printer.print}
          />
        );
    }
  };

  if (order_loading) {
    return <OrderLoader />;
  }

  return (
    <div>
      {isMobile && status === null ? (
        <NavMob text="Órdenes Activas" link="/" />
      ) : null}
      <div className="container-fluid py-md-3">
        <div className="row">
          <div className="col-lg-12 min_vh_5 " id="page_content">
            {order ? renderUI() : <p>Loading</p>}
          </div>
        </div>
      </div>
      {modals.tables && (
        <ModalTables
          open={modals.tables}
          tables={tables}
          toggle={() => modalHandler("tables")}
          makeOrder={changeTable}
          active_table={order.table ? order.table.id : null}
        />
      )}
      {modals.adduser && (
        <AddUserModal
          open={modals.adduser}
          toggle={() => modalHandler("adduser")}
          keyupFun={searchUserPromt}
          selectClient={selectClient}
          users={clients ? clients.getClients : []}
        />
      )}
      {modals.adduserdata && (
        <AddUserDataModal
          open={modals.adduserdata}
          modalHandler={modalHandler}
          buyer={buyer}
          nextFun={saveUser}
          save
          toggle={() => modalHandler("adduserdata")}
          handleType={handleBuyerType}
        />
      )}
    </div>
  );
};

const OrderLoader = () => (
  <div className="min_vh_9 d-flex justify-content-center align-items-center">
    <LoaderDots size="20" color="#b5bcc2" delay="2" />
  </div>
);

export default Order;
