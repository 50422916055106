import React from "react";

import { lang } from "../../../middleware/lang";
import { formatNumber } from "../../../middleware/common-functions";
import {
  statusText,
  deliveryText,
  paymentText,
  getTableName,
} from "../../../lang/orders";
import NoOrders from "./no_orders";
import { OrderType } from "../../../models/order_model";
import { toDate } from "../../../middleware/dates";
import { has_access } from "../../../middleware/privileges";

type Props = {
  hasOrders: boolean;
  loadPastOrders: () => void;
  orders: {
    active: OrderType[];
    past: OrderType[];
  };
  clickFun: (e: any) => void;
};

const OrderList: React.FC<Props> = ({
  orders,
  clickFun,
  hasOrders,
  loadPastOrders,
}) => (
  <React.Fragment>
    {hasOrders ? (
      <OrderGroup clickFun={clickFun} orders={orders.active} />
    ) : (
      <NoOrders type="" />
    )}
    {has_access(32768) ? (
      <PastOrders
        orders={orders.past}
        clickFun={clickFun}
        loadPastOrders={loadPastOrders}
      />
    ) : null}
  </React.Fragment>
);

type PastOrdersProps = {
  orders: OrderType[];
  loadPastOrders: () => void;
  clickFun: (e: any) => void;
};
const PastOrders: React.FC<PastOrdersProps> = ({
  orders,
  clickFun,
  loadPastOrders,
}) => (
  <React.Fragment>
    <hr className="hr_divider sm_hidden" />
    <h1 className="my-2 mt-5 my-lg-4 show_on_new  text-center text-md-left xs_hidden">
      {lang("Últimas Órdenes", "Past Orders")}
    </h1>
    {orders.length >= 1 ? (
      <OrderGroup clickFun={clickFun} orders={orders} />
    ) : (
      <NoOrders
        type="past_orders"
        clickFun={{ txt: "cargar", fun: loadPastOrders, btn: "" }}
      />
    )}
  </React.Fragment>
);

type GroupProps = {
  orders: OrderType[];
  clickFun: (e: any) => void;
};

export const OrderGroup: React.FC<GroupProps> = ({ orders, clickFun }) => (
  <div className="row">
    <div className="col-12 sm_hidden">
      <table className="table table-hover light_table sm_hidden show_on_new ">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha</th>
            <th scope="col">Productos</th>
            <th scope="col">Pago</th>
            <th scope="col">Mesa</th>
            <th scope="col">Tipo</th>
            <th scope="col">Status</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody id="order_list">
          {orders
            ? orders.map((item, key) => (
                <List
                  key={`${key}_${item.id}`}
                  clickFun={clickFun}
                  item={item}
                />
              ))
            : null}
        </tbody>
      </table>
    </div>
  </div>
);

type ListProps = {
  item: OrderType;
  clickFun: (e: any) => void;
};

const List: React.FC<ListProps> = ({ item, clickFun }) => {
  const delivery = deliveryText(item.delivery_type);
  const payment = paymentText(item.payment_type);
  const table_name = getTableName(item.table);
  return (
    <tr
      className={`hover_pointer ${item.type === "online" ? "bg-warning" : ""}`}
      onClick={clickFun}
      data-order_id={item.id}
    >
      <td className="order_id_row">
        <b>#{item.id}</b>
        {item.type === "online" && (
          <span className="badge badge-success ml-2">
            <i className="fas fa-mobile"></i> Online
          </span>
        )}
        {item.status === "fulfilled" && !item.payed ? (
          <span className="badge badge-warning ml-2">
            <i className="fas fa-exclamation"></i> No Cobrado
          </span>
        ) : null}
      </td>
      <td>{toDate(item.creation_date)}</td>
      <td> {item.resume_txt}</td>
      <td>{payment.txt}</td>
      <td className="table_row_id">{table_name}</td>
      <td>
        <i className={delivery.icon}></i> {delivery.txt}
      </td>
      <td className="text-center">
        <span className={`badge d-block badge-${item.status_color}`}>
          {statusText(item.status)}
        </span>
      </td>
      <td>
        <b className="">${formatNumber(item.total)}</b>
      </td>
    </tr>
  );
};

export default OrderList;
