import React from "react";
import { Link } from "react-router-dom";

type Callback = {
  txt: string;
  fun: () => void;
  btn: string;
};

type Props = {
  type: string;
  clickFun?: Callback;
};

const NoOrders: React.FC<Props> = ({ type, clickFun }) => {
  let text = "No hay órdenes activas en este momento";
  let icon = "fas fa-clipboard-check";
  if (type === "products") {
    text = "Sin productos pendientes";
    icon = "far fa-thumbs-up";
  } else if (type === "past_orders") {
    text = "Cargar Pedidos Anteriores";
    icon = "fas fa-vote-yea text-muted ";
  }
  return (
    <div
      className=" justify-content-center flex-column align-content-center text-center d-flex "
      id="no_order"
    >
      <h1 className="full_page_notice" style={{ marginTop: "10%" }}>
        <i className={`fa-3 text-success ${icon} `}></i>
      </h1>
      <h3 className="text-muted text-center">{text}</h3>
      <div>
        {clickFun ? (
          <button
            className={`btn btn-primary ${clickFun.btn}`}
            onClick={clickFun.fun}
          >
            {clickFun.txt}
          </button>
        ) : (
          <Link to="/point_of_sale" className="btn btn-danger btn_xs_lg py-4">
            Capturar Nueva Órden
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoOrders;
