import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import hide_footer_url from "../../data/hide_footer.json";
import nav_items from "../../data/sidenav.json";
import { has_access } from "../../middleware/privileges";

const Item = ({ icon, link, badge, hidden }) => (
  <Link
    to={link}
    className={`btn btn-link position-relative ${
      hidden ? "disabled opacity_0" : ""
    }`}
  >
    <i className={`fa fa-fw ${icon}`} aria-hidden="true"></i>
    {badge ? (
      <span className="badge badge-pill badge-danger">{badge}</span>
    ) : null}
  </Link>
);
const NavMob = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    if (nav_items) {
      let set_nav_items = Object.keys(nav_items)
        .map((key) => {
          const nav_item = nav_items[key];
          if (!has_access(nav_item.priv) || !nav_item.ready) {
            return null;
          }
          return nav_item;
        })
        .filter((item) => item);
      ///insert empty item for UI purposes
      <Item link="/dash_navigation" icon=" fas fa-bars" />;

      set_nav_items = set_nav_items.slice(0, 3);
      if (set_nav_items.length === 2) {
        set_nav_items.pop();
      }
      set_nav_items.push({
        link: "/dash_navigation",
        priv: 0,
        icon: "fas fa-bars",
      });
      const middle = Math.floor(set_nav_items.length / 2);

      set_nav_items.splice(middle, 0, {
        link: "",
        priv: 0,
        text_es: "Órdenes",
        text_en: "Orders",
        icon: "fa fa-inbox",
        badge: 0,
        active: "view_orders",
        hidden: true,
      });
      //max 5 items
      setNavItems(set_nav_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav_items]);
  useEffect(() => {
    hide_footer_url.forEach((url) => {
      if (location.pathname.search(url) >= 0) {
        setShowFooter(false);
      } else if (!showFooter) {
        setShowFooter(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!showFooter) {
    return null;
  }
  return (
    <footer className="footer partner_footer footer_mob d-lg-none">
      <div className="app d-flex d-lg-none justify-content-around animate_all no_focus">
        {navItems
          ? navItems.map((nav_item, key) => (
              <Item
                key={`${key}_${nav_item.active}`}
                link={nav_item.link}
                hidden={nav_item.hidden}
                icon={nav_item.icon}
              />
            ))
          : null}

        <Link
          to="/point_of_sale"
          className="btn btn-lg btn-circle btn-raised btn-danger btn_dashboard dash_change "
        >
          <i className="fas fa-plus fa-fw " aria-hidden="true"></i>
        </Link>
      </div>
    </footer>
  );
};

export default NavMob;
