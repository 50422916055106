import React, { useState, useEffect } from "react";


//minor change 
type AuthContextType = {
  isAuth: boolean;
  errors?: any;
  logoutFun: any;
};

export const AuthContext = React.createContext({
  isAuth: false,
  errors: false,
  logoutFun: () => { },
} as AuthContextType);

const AuthContextProvider: React.FC = ({ children }) => {
  const [logged, setLogged] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      console.log("running AuthContextProvider");
      user = JSON.parse(user);
      setLogged(true);
    }
  }, []);

  const logoutHandler = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("tables");
    localStorage.removeItem("all_orders");
    localStorage.removeItem("menu_all");
    localStorage.removeItem("settings");
    localStorage.removeItem("token");
    setLogged(false);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{
        logoutFun: logoutHandler,
        isAuth: logged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
