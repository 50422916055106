import React, { useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import Section from "./goups";
import ProductsRow from "./products_row";

const Products = ({ addFunction, menu_all, tiggerAdded }) => {
  const [productsbyCat, setProductsbyCat] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  useEffect(() => {
    arrangeByCat(menu_all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrangeByCat = (menu_all) => {
    const set_productsbyCat = {};
    Object.keys(menu_all.categories).map((key) => {
      const cat_id = menu_all.categories[key].id;
      let loopProducts = { ...menu_all.products };

      Object.keys(loopProducts).map((key) => {
        if (loopProducts[key].category.id !== cat_id) delete loopProducts[key];
        return true;
      });

      return (set_productsbyCat[key] = loopProducts);
    });
    setProductsbyCat({ ...set_productsbyCat });
  };

  const products = (selectedCat) => {
    let productsrow = Object.keys(productsbyCat).map((catId) => {
      let loopProducts = { ...productsbyCat[catId] };
      const category = menu_all.categories[catId];
      return (
        <ProductsRow
          key={catId}
          addFunction={addFunction}
          tiggerAdded={tiggerAdded}
          // eslint-disable-next-line
          selectedCat={selectedCat ? selectedCat == category.id : null}
          title={category.name}
          products={loopProducts}
        />
      );
    });
    return productsrow;
  };

  const catHandler = (ev) => {
    const catId = +ev.currentTarget.dataset.cat_id;
    const prev_cat = selectedCat;
    if (prev_cat === catId) {
      setSelectedCat(null);
    } else {
      if (isMobileOnly) {
        const products_cont = document.getElementById("products_container");
        products_cont.scrollIntoView({ behavior: "smooth" });
      }
      setSelectedCat(catId);
    }
  };

  return (
    <div className="pos_panel min_vh_7 ">
      {/* <Section items={group_list} title="Grupos" /> */}
      {menu_all ? (
        <Section
          items={menu_all.categories}
          selectedCat={selectedCat}
          title="Tipos"
          click={catHandler}
        />
      ) : null}
      <hr />
      <div id="products_container">
        {productsbyCat ? products(selectedCat) : null}
      </div>
    </div>
  );
};

export default Products;
