import React, { useEffect, useState } from "react";
import cx from "classnames";

type Props = {
  pageSize: number;
  page: number;
  totalCount: number;
  onClick: (page: number) => void;
};

const Pagination: React.FC<Props> = ({
  pageSize,
  page,
  totalCount,
  onClick,
}) => {
  const [lastPage, setLastPage] = useState(0);
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    if (pageSize) {
      const maxPages = Math.ceil(totalCount / pageSize);
      setLastPage(maxPages);
      const newPages = [];
      for (let i = 1; i < maxPages + 1; i++) {
        newPages.push(i);
      }
      setPages(newPages);
      console.log(pages);
    }
    //eslint-disable-next-line
  }, [pageSize, totalCount]);

  return (
    <nav aria-label="Page navigation example" className="my-4">
      <ul className="pagination justify-content-center">
        <li className={cx({ "page-item": true, disabled: page === 1 })}>
          <button
            className="page-link"
            onClick={() => onClick(page - 1)}
            tabIndex={-1}
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        </li>
        {pages.map((i) => (
          <li
            key={`page-${i}`}
            className={cx({ "page-item": true, disabled: i === page })}
          >
            <button className="page-link" onClick={() => onClick(i)}>
              {i}
            </button>
          </li>
        ))}
        <li className={cx({ "page-item": true, disabled: lastPage === page })}>
          <button onClick={() => onClick(page + 1)} className="page-link">
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};
export default Pagination;
