import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { formatNumber } from "../../../middleware/common-functions";
import { toDate } from "../../../middleware/dates";
import { CashMoveType } from "../../../models/cashouts";

const Timeline: React.FC<{ cashouts: CashMoveType[]; handleEdit: any }> = ({
  cashouts,
  handleEdit,
}) => (
  <VerticalTimeline layout="1-column">
    {cashouts.map((move) => (
      <VerticalTimelineElement
        key={`VerticalTimelineElement_${move.id}`}
        className={`timeline-element timeline-color-${move.type}`}
        date={toDate(move.creation_date)}
        icon={<i className={`icon fas fa-${renderIcon(move.type)}`}></i>}
      >
        <div className="d-flex justify-content-between w-100">
          <h5>${formatNumber(move.amount)}</h5>
          <button className="btn" onClick={handleEdit} data-cash_id={move.id}>
            <i className="far fa-edit "></i>
          </button>
        </div>
        <p>{move.concept}</p>
      </VerticalTimelineElement>
    ))}
  </VerticalTimeline>
);

function renderIcon(type: string) {
  switch (type) {
    case "in":
      return "share text-success";
    case "out":
      return "reply text-danger";
    case "closed":
      return "fas fa-cash-register";
    default:
      return "angle-right";
  }
}

export default Timeline;
