import { gql } from "@apollo/client";
export const LOGINQUERY = gql`
  mutation LoginUser($email: String!, $pass: String!) {
    login(username: $email, password: $pass) {
      token
      user {
        id
        name
        lastname
        subuser_id
        privileges
        partner {
          id
          status
          company_name
          owner_name
          owner_lastname
          logo
          branches
        }
        branch {
          id
          name
        }
      }
      settings {
        id
        type
        pref_key
        pref_value
        is_global
      }
    }
  }
`;
