import { InputCategory, InputIngredient, InputOption, InputSize, ProductInput } from "./state";

export enum ActionType {
  AddIngredient,
  RemoveIngredient,
  AddSize,
  RemoveSize,
  SetSizePrice,
  SetSizeName,
  AddOption,
  RemoveOption,
  SetOptionPrice,
  ResetProduct,
  LoadProduct,
  SetZone,
  SetStatus,
  SetStatusWeb,
  SetSnoozed,
  SetHasOption,
  SetCategory,
  SetTitle,
  SetModel,
  SetDescription,
  SetPrice
};

export interface SetPrice {
  type: ActionType.SetPrice,
  payload: number
};

export interface SetTitle {
  type: ActionType.SetTitle,
  payload: string
}

export interface SetDescription{
  type: ActionType.SetDescription,
  payload: string
}

export interface SetModel {
  type: ActionType.SetModel,
  payload: string
}

export interface SetZone {
  type: ActionType.SetZone,
  payload: number
}

export interface LoadProduct {
  type: ActionType.LoadProduct,
  payload: ProductInput
}

export interface AddIngredient {
  type: ActionType.AddIngredient,
  payload: InputIngredient
};

export interface RemoveIngredient {
  type: ActionType.RemoveIngredient,
  payload: InputIngredient
};

export interface AddSize {
  type: ActionType.AddSize,
  payload: InputSize
};

export interface RemoveSize {
  type: ActionType.RemoveSize,
  payload: { idx: number }
};

export interface SetSizePrice {
  type: ActionType.SetSizePrice,
  payload: { price: number, idx: number }
}

export interface SetSizeName {
  type: ActionType.SetSizeName,
  payload: { name: string, idx: number }
}

export interface ResetProduct {
  type: ActionType.ResetProduct
}

export interface SetStatus {
  type: ActionType.SetStatus,
  payload: boolean
}

export interface SetStatusWeb {
  type: ActionType.SetStatusWeb,
  payload: boolean
}

export interface SetSnoozed {
  type: ActionType.SetSnoozed,
  payload: boolean
}

export interface SetHasOption {
  type: ActionType.SetHasOption,
  payload: number
}

export interface SetCategory {
  type: ActionType.SetCategory,
  payload: InputCategory
}

export interface AddOption {
  type: ActionType.AddOption,
  payload: InputOption
};

export interface RemoveOption {
  type: ActionType.RemoveOption,
  payload: InputOption
};

export interface SetOptionPrice {
  type: ActionType.SetOptionPrice,
  payload: { price: number, idx: number }
}

export type ProductActions = LoadProduct | RemoveIngredient | AddSize | RemoveSize | AddIngredient | ResetProduct | SetSizeName
                            | SetSizePrice | SetZone | SetStatus | SetStatusWeb | SetSnoozed | SetHasOption | SetCategory | AddOption
                            | RemoveOption | SetOptionPrice | SetTitle | SetDescription | SetModel | SetPrice;
