import React from "react";
import { Link } from "react-router-dom";

import useSettings from "../../hooks/settings";
import { lang } from "../../middleware/lang";
import nav_items from "../../data/sidenav.json";
import { User } from "../../models/user_model";
import { NavItem } from "../../models/nav_model";
import { has_access } from "../../middleware/privileges";

type SideNavProps = { active: boolean; showside: any };

const SideNav: React.FC<SideNavProps> = ({ active, showside }) => {
  const user: User = JSON.parse(localStorage.getItem("user") || "{}");
  const [settings, saveSettings] = useSettings();
  const toggleNightMode = () => {
    saveSettings("nightmode", !settings.nightmode);
  };
  return (
    <div
      className={`fixed_top d-lg-block d-none p-0 left_nav ${
        active ? "active" : null
      }`}
      id="sideNav"
    >
      <header>
        <Link className="navbar-brand" to="/">
          <img
            src={
              user && user.partner.logo !== null
                ? `https://cdn.foopos.mx${user.partner.logo
                    ?.replace("/img", "")
                    .slice(0)}`
                : "https://fooapp.mx/img/foo_mascot_chef_hat@2x.svg"
            }
            alt="company logo"
          />
        </Link>
        <p>
          {user && user.partner?.company_name}
          <br />
          {user && user.name} {user && user.lastname ? user.lastname : ""}
        </p>
      </header>
      <nav className="nav d-none d-lg-flex">
        {Object.keys(nav_items).map((key) => {
          //@ts-ignore next line
          const nav_item = nav_items[key] as NavItem;
          if (!has_access(nav_item.priv)) {
            return null;
          }
          if (!nav_item.ready) {
            return (
              <li key={key}>
                <a className="nav-link py-3" href={nav_item.link}>
                  <i
                    className={`fa ${nav_item.icon} fa-fw`}
                    aria-hidden="true"
                  ></i>{" "}
                  <span>{lang(nav_item.text_es, nav_item.text_en)}</span>
                  {nav_item.badge ? (
                    <span className="ml-1 badge badge-pill badge-danger">
                      {nav_item}
                    </span>
                  ) : null}
                </a>
              </li>
            );
          }
          return (
            <li key={key}>
              <Link
                className={`nav-link py-3  ${
                  nav_item.ready ? "" : "disabled opacity_6"
                }`}
                to={nav_item.link}
              >
                <i
                  className={`fa ${nav_item.icon} fa-fw`}
                  aria-hidden="true"
                ></i>{" "}
                <span>{lang(nav_item.text_es, nav_item.text_en)}</span>
                {nav_item.badge ? (
                  <span className="ml-1 badge badge-pill badge-danger">
                    {nav_item}
                  </span>
                ) : null}
              </Link>
            </li>
          );
        })}
      </nav>
      <div className="navOptions">
        <div className="nigth_mode_switch">
          <label
            className="switch"
            htmlFor="checkbox"
            title="Change color scheme to dark mode"
          >
            <input
              type="checkbox"
              id="checkbox"
              onChange={toggleNightMode}
              defaultChecked={settings.nightmode}
            />
            <div className="slider round"></div>
            <div className="toggle-moon">
              <i className="fas fa-moon"></i>
            </div>
            <div className="toggle-sun">
              <i className="far fa-sun"></i>
            </div>
          </label>
        </div>
        <button
          className="min_width_1 btn btn-link text-white btn-block"
          onClick={showside}
        >
          <i className="fas fa-chevron-left animate_all"></i>
        </button>
      </div>
    </div>
  );
};

export default SideNav;
