import React, { useContext } from "react";
import IngredientsSearch from "./IngredientsSearch";
import { ActionType } from "./productContext/actions";
import { ProductContext } from "./productContext/context";
import SizesSearch from "./SizesSearch";

const Ingredients: React.FC = () => {
  const { state, dispatch } = useContext(ProductContext);

  return (
    <div className="material_card p-3">
      <h4>Opciones, Tamaños e Ingredientes</h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <h5>
            Precio por tamaño/presentación <br />
            <small className="text-muted text_small">
              Precio por tamaño/presentación (con IVA)
            </small>
          </h5>
          <SizesSearch />
          <table
            className={`table table-hover table-sm mt-4 ${
              state.sizes.length === 0 ? "d-none" : ""
            }`}
          >
            <thead>
              <tr>
                <th scope="col">Tamaño</th>
                <th scope="col">
                  Precio Tamaño{" "}
                  <i className="far fa-question-circle text-muted"></i>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {state.sizes.map((size, idx) => (
                <tr key={`size-price-${idx}`}>
                  <td>{size.name}</td>
                  <td>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm size_price"
                        value={size.price}
                        onChange={(e) =>
                          dispatch({
                            type: ActionType.SetSizePrice,
                            payload: {
                              price: +e.target.value,
                              idx,
                            },
                          })
                        }
                        placeholder="0.00"
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() =>
                        dispatch({
                          type: ActionType.RemoveSize,
                          payload: { idx },
                        })
                      }
                    >
                      <i className="fa fa-times" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <IngredientsSearch />
          {state.ingredients.map((ingredient, idx) => (
            <button
              type="button"
              className="btn btn-sm btn-info mr-1 mt-1 text-capitalize"
              onClick={() =>
                dispatch({
                  type: ActionType.RemoveIngredient,
                  payload: ingredient,
                })
              }
              key={`ingredient-${idx}`}
            >
              {ingredient.name} <span className="badge">x</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ingredients;
