import { ActionType, ProductActions } from "./actions";
import { ProductInput } from "./state";

export function productReducer(state: ProductInput, action: ProductActions) : ProductInput {
  switch (action.type) {
    case ActionType.SetTitle:
      return { ...state, title: action.payload };
    case ActionType.SetPrice:
      return { ...state, price: action.payload };
    case ActionType.SetModel:
      return { ...state, model: action.payload };
    case ActionType.SetDescription:
      return { ...state, description: action.payload };
    case ActionType.AddOption: {
      const options = [...state.options, action.payload];
      return { ...state, options };
    }
    case ActionType.RemoveOption: {
      const options = state.options.filter(option => option.ingredient.name !== action.payload.ingredient.name);
      return { ...state, options };
    }
    case ActionType.SetCategory:
      return {...state, category: action.payload };
    case ActionType.SetHasOption:
      return {...state, has_option: action.payload };
    case ActionType.SetStatus:
      return {...state, status: action.payload };
    case ActionType.SetStatusWeb:
      return {...state, status_web: action.payload };
    case ActionType.SetSnoozed:
      return {...state, snoozed: action.payload };
    case ActionType.SetZone:
      return {...state, zone_id: action.payload };
    case ActionType.LoadProduct:
      return action.payload;
    case ActionType.AddIngredient: {
      console.log(action.payload);
      const ingredients = [...state.ingredients, action.payload]; 
      return {...state, ingredients };
    }
   case ActionType.RemoveIngredient:
      const ingredients = state.ingredients.filter(i => (i.name !== action.payload.name))
      return {...state, ingredients };
    case ActionType.AddSize:
      return {...state, sizes: [...state.sizes, action.payload]};
    case ActionType.RemoveSize: {
      const sizes = state.sizes.filter((_, idx) => idx !== action.payload.idx);
      return {...state, sizes };
    }
    case ActionType.ResetProduct:
      return {...state};
    case ActionType.SetSizeName: {
      const sizes = state.sizes.map((size, idx) => {
        if (idx === action.payload.idx) {
          return {
            ...size,
            name: action.payload.name
          }
        }
        return size;
      });
      return {...state, sizes };
    }
    case ActionType.SetSizePrice: {
      const sizes = state.sizes.map((size, idx) => {
        if (idx === action.payload.idx) {
          return {
            ...size,
            price: action.payload.price
          }
        }
        return size;
      });
      return {...state, sizes };
    }
    case ActionType.SetOptionPrice: {
      const options = state.options.map((option, idx) => {
        if (idx === action.payload.idx) {
          return {
            ...option,
            price_mod: action.payload.price
          }
        }
        return option;
      });
      return {...state, options };
    }
    default:
      return state; 
  }
}