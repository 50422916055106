import React from "react";
import { Link } from "react-router-dom";

const HeaderMob = (props) => (
  <nav className="navbar navbar-dark bg_partner d-md-none ">
    <Link className="navbar-brand" to={props.link}>
      <small>
        <i className="fa fa-chevron-left opacity_7" aria-hidden="true"></i>{" "}
        {props.text}
      </small>
    </Link>
  </nav>
);

export default HeaderMob;
