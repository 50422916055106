import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import Nav from "./nav";
import General from "./general";
import Pref from "./prefs";
const main_nav = [
  { name: { es: "General", en: "Overview" }, link: "/general" },
  // { name: { es: "Pagos", en: "Payment" }, link: "/payment" },
  // { name: { es: "Cobros", en: "Billing" }, link: "/charges" },
  {
    name: { es: "Preferencias", en: "Settings" },
    link: "/settings",
  },
];
const Account = () => {
  const user = JSON.parse(localStorage.getItem("user") || "");
  return (
    <div className="container py-4">
      <h2>{user.partner.company_name}</h2>
      <Nav items={main_nav} />
      <hr />
      <Route exact path="/account">
        <Redirect to="/account/general" />
      </Route>
      <Route exact path="/account/general" component={General} />
      <Route path="/account/settings/:section?" component={Pref} />
    </div>
  );
};

export default Account;
