import React, { LegacyRef, forwardRef, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { OrderGroup } from "../orders/list";
import { lang } from "../../../middleware/lang";
import {
  CashoutInput,
  CashoutTypes,
  CashoutsType,
  CashMoveType,
  CashoutMovesTypes,
} from "../../../models/cashouts";
import { SalesDBType } from "../../../models/sales_model";
import { CashoutsModal } from "../../modals/cashouts";
import { HeaderTitle } from "../common/titles";
import SalesResume from "./sales_resume";
import Timeline from "./timeline";
import {
  SAVECASHOUT,
  GETCASHOUTS,
  UPDATECASHOUT,
  DELETECASHOUT,
} from "./queries";
import Ctas, { btnAction } from "./ctas";
import { activeBranchVar } from "../../../middleware/cache";

type ModalType = {
  open: boolean;
  edit?: boolean;
  type?: "closed" | "started" | "in" | "out";
};

type SalesDates = {
  start: string;
  startDate: Date;
  end: string;
  endDate: Date;
};
const todayDateTime = DateTime.local();
const CashCuts: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const activeBranch = useReactiveVar(activeBranchVar);
  const history = useHistory();

  const [modal, setModal] = useState<ModalType | null>(null);
  const [cashouts, setCashouts] = useState<CashoutsType | null>();
  const [cashoutsMoves, setCashoutsMoves] = useState<CashMoveType[] | null>();
  const [cashoutIpunt, setCashoutInput] = useState<CashoutInput>({});
  const [saveCashout] = useMutation(SAVECASHOUT);
  const [deleteCashLog] = useMutation(DELETECASHOUT);
  const [updateCashLog] = useMutation(UPDATECASHOUT);
  const [getCashout, { data: cashouts_data }] = useLazyQuery(GETCASHOUTS, {
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });
  const [dates, setDates] = useState<SalesDates>({
    start: todayDateTime.toISODate(),
    end: todayDateTime.toISODate(),
    startDate: new Date(),
    endDate: new Date(),
  });
  useEffect(() => {
    setCashouts(null);
    setCashoutsMoves(null);
    fetchCashouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranch, dates]);

  useEffect(() => {
    if (cashouts_data) {
      parseCashouts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashouts_data]);

  const fetchCashouts = () => {
    getCashout({
      variables: { branch_id: +activeBranch, startDate: dates.start },
    });
  };

  const goToOrder = (ev: { currentTarget: HTMLElement }) => {
    const order_id = ev.currentTarget.dataset.order_id;
    history.push(`/order/${order_id}`);
  };

  const parseCashouts = () => {
    const {
      getSales,
      getCashout,
    }: {
      getSales: SalesDBType;
      getCashout: CashMoveType[];
    } = cashouts_data;
    const { posSales, onlineSales, orders } = getSales;
    const total_sales = posSales.total + onlineSales.total;
    let total_cash = 0;
    let total_terminal = 0;
    let total_out = 0;
    let total_in = 0;
    let toal_closed = 0;
    if (getCashout) {
      getCashout.forEach((cash_move) => {
        switch (cash_move.type) {
          case "in":
            total_in += cash_move.amount;
            break;
          case "out":
            total_out += cash_move.amount;
            break;
          case "closed":
            toal_closed += cash_move.amount;
            break;

          default:
            return;
        }
      });
    }

    if (orders) {
      orders.forEach((order) => {
        if (order.payment_type === "cash") {
          total_cash += order.total;
        } else {
          total_terminal += order.total;
        }
      });
    }
    const set_chashouts: CashoutsType = {
      in: total_in,
      out: total_out,
      sales: total_sales,
      sales_cash: total_cash,
      sales_terminal: total_terminal,
      total: total_in + total_cash - total_out - toal_closed,
    };

    setCashouts(set_chashouts);
    setCashoutsMoves(getCashout);
  };

  const handleModal = (ev: { currentTarget: HTMLButtonElement }) => {
    const type = ev.currentTarget.dataset.type as CashoutMovesTypes;
    if (!type) return;
    const set_modal: ModalType = {
      open: true,
      type,
    };
    if (type === "closed") {
      setCashoutInput({
        type: "closed",
        concept: `${lang("Corte de caja, por:", "Final, made by ")} ${
          user.name || "Sin nombre"
        }`,
      });
    }
    setModal(set_modal);
  };

  const handeSave = async () => {
    if (!cashoutIpunt) return;
    if (modal && modal.edit) {
      const update_cashinput = {
        id: cashoutIpunt.id,
        branch_id: cashoutIpunt.branch_id,
        amount: cashoutIpunt.amount,
        type: cashoutIpunt.type,
        concept: cashoutIpunt.concept,
      };
      await updateCashLog({ variables: { cashoutIpunt: update_cashinput } });
    } else {
      await saveCashout({ variables: { cashoutIpunt } });
    }
    fetchCashouts();
    setCashoutInput({});
    setModal(null);
  };

  const handeDelete = async () => {
    if (!cashoutIpunt) return;
    await deleteCashLog({ variables: { id: cashoutIpunt.id } });
    fetchCashouts();
    setCashoutInput({});
    setModal(null);
  };

  const handleEdit = (ev: { currentTarget: HTMLButtonElement }) => {
    const cash_id = ev.currentTarget.dataset.cash_id;
    console.log("index.tsx:140 | cash_id", cash_id);
    if (!cashoutsMoves || !cash_id) return;
    const edited_move = cashoutsMoves.find((c) => c.id === parseInt(cash_id));
    if (edited_move) {
      setCashoutInput(edited_move);
      setModal({ open: true, edit: true, type: edited_move.type });
    }
  };

  const handeType = (ev: { target: HTMLInputElement }) => {
    const text = ev.target.value;
    const type: CashoutTypes = ev.target.dataset.type as CashoutTypes;
    if (!type) return;
    const current_data: CashoutInput = { ...cashoutIpunt };
    if (type === "branch_id" || type === "amount") {
      current_data[type] = parseFloat(text);
    } else {
      current_data[type] = text;
    }

    current_data.type = modal?.type;
    current_data.branch_id = activeBranch;
    setCashoutInput(current_data);
  };
  const handleDate = (date: Date) => {
    const set_dates = { ...dates };
    set_dates.startDate = date;
    set_dates.start = DateTime.fromJSDate(date).toISODate();
    setDates(set_dates);
  };

  const ctas_btns: btnAction[] = [
    {
      txt: lang("Agregar Entrada", "Add Cash Entry"),
      icon: "fa fa-plus",
      classes: "success",
      type: "in",
      fun: handleModal,
    },
    {
      txt: lang("Agregar Salida", "Add Cash Out"),
      icon: "fa fa-minus",
      classes: "danger",
      type: "out",
      fun: handleModal,
    },
    {
      txt: lang("Fin de turno", "Make Cash Out"),
      icon: "fas fa-user-clock",
      classes: "outline-danger ",
      type: "closed",
      fun: handleModal,
    },
  ];

  return (
    <div className="container-fluid py-2 pl-5">
      <HeaderTitle
        title={`${lang(
          "Cortes de Caja",
          "Cash Out Panel"
        )} | ${dates.startDate.toDateString()}`}
        justify="justify-content-start"
      >
        <DatePicker
          selected={dates.startDate}
          startDate={dates.startDate}
          onChange={handleDate}
          customInput={<CustomInput onClick="" />}
          maxDate={new Date()}
        />
      </HeaderTitle>
      <div className="row">
        <div className="col-md-6 order-md-2">
          <div className="row">
            <div className="col-md-6 order-md-2">
              {cashouts && <SalesResume cashouts={cashouts} />}
            </div>
            <div className="col-md-6 order-md-3">
              <Ctas btns={ctas_btns} />
            </div>
            <div className="col-md-12 order-md-5 mt-3">
              {cashouts_data?.getSales?.orders.length > 0 ? (
                <OrderGroup
                  orders={cashouts_data.getSales.orders}
                  clickFun={goToOrder}
                />
              ) : null}
            </div>
          </div>
        </div>
        {cashoutsMoves && (
          <div className="col-md-4 timeline order-md-1">
            <Timeline cashouts={cashoutsMoves} handleEdit={handleEdit} />
          </div>
        )}
      </div>
      <CashoutsModal
        open={modal?.open || false}
        handeType={handeType}
        toggle={() => {
          setModal(null);
          setCashoutInput({});
        }}
        type={modal?.type}
        edit={modal?.edit || false}
        cashoutIpunt={cashoutIpunt}
        handeSave={handeSave}
        handeDelete={handeDelete}
      />
    </div>
  );
};

const CustomInput = forwardRef(
  (
    { onClick }: { onClick: any },
    ref: LegacyRef<HTMLButtonElement> | undefined
  ) => (
    <button className="btn btn-outline-dark" onClick={onClick} ref={ref}>
      <i className="far fa-calendar"></i>
    </button>
  )
);

export default CashCuts;
