export const ESC = "\u001B";
export const GS = "\u001D";
export const Line = "\u000A";
const commands = {
  ESC,
  GS,
  Line,
  Sp: "  ",
  Font_b: `${ESC}M\u0001`,
  PrintPage: "\u000C",
  InitializePrinter: ESC + "\u0040",
  BoldOn: `${ESC}E\u0001`,
  CenterOn: `${ESC}a\u0001`,
  RightOn: `${ESC}a\u0002`,
  LeftOn: `${ESC}a\0`,
  BoldOff: `${ESC}E\0`,
  DoubleOn: `${GS}!\u0011`, // 2x sized text (double-high + double-wide)
  DoubleOff: `${GS}!\0`,
  h3On: `${GS}!\u0001`, // bigger font
  h3Off: `${GS}!\0`,
  enter: Line + Line,
  drawer: `${ESC}p025250`,
  cut_paper: `${GS}V\u0001`,
};

export default commands;
