import React from "react";
import { Link } from "react-router-dom";
import { Product } from "../../../../models/product_model";

type Props = {
  product: Product;
};

const Header: React.FC<Props> = ({ product }) => {
  return (
    <div className="col-12">
      <p className="xs_hidden">
        <Link to="/products">
          <i className="fa fa-chevron-left" aria-hidden="true"></i> Productos
        </Link>
      </p>
      <h1>{product.title}</h1>
      {product.id ? (
        <p>
          <a
            href={`https://fooapp.mx/es/pages/product/9/${product.id}`}
            className="btn btn-default btn-sm"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-external-link-alt"></i> Ver Producto
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default Header;
