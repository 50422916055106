import React from "react";
import { lang } from "../../../middleware/lang";

const UserBlock = ({
  payed,
  income,
  shipping,
  payment_type,
  card_type,
  branch,
  delivery,
  user,
  client,
  address,
  saveUser,
}) => {
  return (
    <div className="col-12">
      <div className="material_card p-3 mt-3 ">
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-8 ">
                <h4>{lang("Acerca de este Cliente", "About this Customer")}</h4>
              </div>
              {client && client.id !== "100" ? (
                <div className="col-12">
                  <p className="my-0">
                    {`${client.name} ${client.lastname ? client.lastname : ""}`}
                  </p>
                  <p className="my-3">
                    <a href={`tel:${client.phone}`} className="d-block ">
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      {client.phone} <span className="ml-2">Contactar</span>
                    </a>
                  </p>
                </div>
              ) : (
                <div className="col-12">
                  <div id="client_div">
                    <button
                      type="button"
                      className="btn btn-outline-blue btn-block my-2 btn_xs_lg_mob"
                      onClick={saveUser}
                    >
                      {lang("Agregar Cliente", "Add Customer")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-6">
            <h4>{lang("Información de Pedido", "Order info")}</h4>
            {branch && (
              <p className="my-2">
                <b>{lang("Sucursal", "Branch")}:</b> {branch.name}
              </p>
            )}
            <p className="my-2">
              <b>{lang("Costo de Envío", "Delivery Price")}:</b> $
              {shipping.toFixed(2)}
            </p>
            {payed ? (
              <p className="my-2">
                <b>{lang("Forma de pago", "Payment Type")}:</b>{" "}
                {paymentRender(payment_type, card_type)}
              </p>
            ) : null}
            {payed && payment_type === "cash" && income ? (
              <p className="my-2">
                <b>{lang("Efectivo Recibido", "Income Cash")}:</b> $
                {income.toFixed(2)}
              </p>
            ) : null}
            <p className="my-2">
              <b>{lang("Lo Atendió", "Attended by")}:</b>{" "}
              {user && `${user.name} ${user.lastname ? user.lastname : ""}`}
            </p>
            <p className="my-2">
              <b>{lang("Forma de Entrega", "Order Type")}</b>:{" "}
              {deliveryRender(delivery)}
            </p>
            {address && (
              <p className="my-2">
                <b>{lang("Dirección de Entrega", "Delivery Address")}</b>:{" "}
                {address.address}
              </p>
            )}
            {address && address.comments ? (
              <p className="my-2">
                <b>{lang("Comentarios de Dirección", "Delivery Comments")}</b>:{" "}
                {address.comments}
              </p>
            ) : null}
            {address && address.location ? (
              <>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${address.location}`}
                  rel="noreferrer"
                  class="btn btn-primary btn-block my-2"
                  target="_blank"
                >
                  <i class="fa fa-external-link" aria-hidden="true"></i> Ver
                  Mapa
                </a>
                <small className="text-secondary">
                  El mapa es informativo, revisa bien la dirección del cliente
                </small>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

function deliveryRender(delivery) {
  const delivery_text = {};
  switch (delivery) {
    case "pickup":
      delivery_text.txt = lang("Para Recoger", "Takeout");
      delivery_text.icon = "fas fa-hand-point-up mr-2";
      break;
    case "delivery":
      delivery_text.txt = lang("Envío a Domicilio", "Delivery");
      delivery_text.icon = "fas fa-motorcycle mr-2";
      break;
    case "other_apps":
      delivery_text.txt = lang("Plataformas", "Apps");
      delivery_text.icon = "fas fa-mobile-alt";
      break;

    default:
      delivery_text.txt = lang("En Comedor", "To eat Here");
      delivery_text.icon = "far fa-circle";
      break;
  }
  return (
    <span>
      <i className={`ml-2 mr-1 ${delivery_text.icon}`} aria-hidden="true"></i>
      {delivery_text.txt}
    </span>
  );
}

function paymentRender(payment, card_type = false) {
  let payment_text = "";
  switch (payment) {
    case "cash":
      payment_text = lang("Efectivo", "Cash");
      break;
    case "online":
      payment_text = lang("Pagado en Línea", "Payed Online");
      break;
    case "card_pos":
    case "terminal":
      payment_text = "Terminal";
      break;

    default:
      payment_text = "En Comedor";
      break;
  }
  if (card_type) {
    switch (card_type) {
      case "debit":
        payment_text = `${payment_text} | Tarjeta de Débito`;
        break;
      case "credit":
        payment_text = `${payment_text} | Tarjeta de Crédito`;
        break;
      case "services":
        payment_text = `${payment_text} | Tarjeta de Servicios`;
        break;
      default:
        break;
    }
  }

  return payment_text;
}
export default UserBlock;
