import React from "react";
import { Link } from "react-router-dom";
import { lang } from "../../../middleware/lang";
import { LoaderDots } from "../../../middleware/loaders";

import type { Table } from "../../../models/order_model";

type Props = {
  tables: Table[];
};

const TablesView: React.FC<Props> = ({ tables }) => {
  if (!tables) return <LoaderDots />;
  if (tables.length < 1) {
    return (
      <div className="text-center">
        <h2 className="display-5">{lang("Mesas", "Tables")}</h2>

        <h4 className="text-muted">{lang("Sin Mesas", " No Tables")}</h4>
      </div>
    );
  }
  return (
    <div className="text-center">
      <h2 className="display-5">{lang("Mesas", "Tables")}</h2>
      {tables.map((table) => (
        <TableBlock
          name={table.name}
          id={table.id}
          order={table.order_id}
          key={`table-${table.id}`}
          occupied={table.is_active && table.order_id ? table.is_active : false}
        />
      ))}
    </div>
  );
};

type TableProps = {
  occupied: boolean;
  order: number;
  id: string;
  name: string;
};

const TableBlock: React.FC<TableProps> = ({ occupied, order, id, name }) => {
  const sendTo =
    occupied && order ? `/order/${order}` : `/point_of_sale?table=${id}`;
  return (
    <Link
      className={`btn btn_helper rounded_plus p-5 m-1 my-3 darker btn-outline-${
        occupied ? "light" : "dark"
      }`}
      to={sendTo}
    >
      {occupied ? (
        <small className="text-danger helper">
          <i className="fas fa-user-circle"></i> {lang("Ocupada", "Occupied")}
        </small>
      ) : null}
      {name || id}
    </Link>
  );
};

export default TablesView;
