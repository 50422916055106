import React from "react";
import { formatNumber } from "../../../middleware/common-functions";
import { has_access } from "../../../middleware/privileges";

const ProductList = ({
  products,
  remove,
  changePrice,
  addComment,
  selectIng,
}) => {
  let list = <p className="text-muted del_help">Sin Productos en la orden </p>;
  if (products.length) {
    list = products.map((item, key) => {
      return (
        <ProductLi
          key={key}
          product={item}
          selectIng={selectIng}
          index={key}
          removeProduct={remove}
          changePrice={changePrice}
          addComment={addComment}
        />
      );
    });
  }

  return (
    <div className="material_card pos_panel p-3 scrolleable scroll_active fix_vh_50">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col" style={{ width: "80%" }}>
              Producto
            </th>
            <th scope="col">Precio</th>
          </tr>
        </thead>
      </table>

      <div className="list-group order_summary ">{list}</div>
    </div>
  );
};

const ProductLi = ({
  product,
  removeProduct,
  index,
  changePrice,
  addComment,
  selectIng,
}) => {
  const title = product.title || product.product.title;
  const byX =
    product.qty > 1 ? (
      <div className="col text-right">
        <p className="my-0">
          {" "}
          x{formatNumber(product.qty)} | $
          {formatNumber(product.price / product.qty)} c/u
        </p>
      </div>
    ) : null;

  return (
    <div
      className={`list-group-item list-group-item-action flex-column align-items-start pb-0 hover_cta_cont ${
        index === 0 && "animate"
      }`}
    >
      <div className="d-flex w-100 justify-content-between py-1">
        <h5 className="mb-1">{title}</h5>
        <h4>
          <b>${formatNumber(product.price)}</b>
        </h4>
      </div>
      <div className="row">
        <div className="col">
          <p className="my-0">{product.text ? product.text : "Con Todo"}</p>
        </div>
        {byX}
      </div>
      <div className="d-flex w-100 justify-content-between">
        <button
          type="button"
          data-product_key={index}
          className="btn btn-transparent btn-sm text-muted text-center ch_price"
          onClick={selectIng}
        >
          <i className="fas fa-pen"></i>
        </button>{" "}
        {has_access(2048) ? (
          <button
            type="button"
            data-product_key={index}
            className="btn btn-transparent btn-sm text-muted text-center ch_price"
            onClick={changePrice}
          >
            <i className="fas fa-dollar-sign"></i>
          </button>
        ) : null}
        <button
          type="button"
          data-type="product"
          data-product_key={index}
          className="btn btn-transparent btn-sm text-muted text-center"
          onClick={addComment}
        >
          <i className="far fa-comment"></i>
        </button>{" "}
        <button
          type="button"
          data-product_key={index}
          className="btn btn-transparent btn-sm text-danger text-center"
          onClick={removeProduct}
        >
          <i className="fas fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default ProductList;
