import React, { MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../middleware/common-functions";
import { OrderType } from "../../../models/order_model";

type SalesProductsProps = {
  orders: OrderType[];
};

type SalesProductsType = {
  id: string;
  unique: string;
  title: string;
  options?: string;
  model: string;
  quantity: number;
  total: number;
};

type PageSettingsType = {
  group: boolean;
  desc: boolean;
  sort_by: "model" | "title" | "quantity" | "total";
};

const SalesProducts: React.FC<SalesProductsProps> = ({ orders }) => {
  const [products, setProducts] = useState<SalesProductsType[]>([]);
  const [pageSetting, setPageSettings] = useState<PageSettingsType>({
    group: true,
    sort_by: "total",
    desc: false,
  });

  useEffect(() => {
    const orders_products: SalesProductsType[] = [];
    orders.forEach((order) => {
      order.products.forEach((p) => {
        const unique = pageSetting.group
          ? p.product.id
          : `${p.product.id}-${p.ingredients_opt}`;
        const finded = orders_products.find((op) => op.unique === unique);
        if (finded) {
          finded.quantity += p.qty;
          finded.total += p.price;
        } else {
          orders_products.push({
            id: p.product.id,
            unique,
            title: p.product.title,
            options: p.ingredients_opt ? `${p.ingredients_opt}` : "",
            model: p.product.model,
            quantity: p.qty,
            total: p.price,
          });
        }
      });
    });

    setProducts(
      orders_products.sort((a, b) => {
        return pageSetting.desc
          ? a[pageSetting.sort_by] > b[pageSetting.sort_by]
            ? 1
            : -1
          : a[pageSetting.sort_by] > b[pageSetting.sort_by]
          ? -1
          : 1;
      })
    );
  }, [orders, pageSetting]);

  const handlePageSetting = (event: MouseEvent<HTMLButtonElement>) => {
    const type = event.currentTarget.name as keyof PageSettingsType;
    setPageSettings({ ...pageSetting, [type]: !pageSetting[type] });
  };

  const handleSortBy = (event: MouseEvent<HTMLButtonElement>) => {
    const sort_by = event.currentTarget.name as
      | "model"
      | "title"
      | "quantity"
      | "total";
    setPageSettings({ ...pageSetting, sort_by });
  };

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="d-flex justify-content-end mb-3">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn btn-outline-primary"
              onClick={handlePageSetting}
              name="group"
            >
              <i
                className={`fas fa-${
                  pageSetting.group ? "compress" : "expand"
                }-arrows-alt`}
              ></i>
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={handlePageSetting}
              name="desc"
            >
              <i
                className={`fas fa-sort-amount-${
                  pageSetting.desc ? "up" : "down"
                }`}
              ></i>
            </button>
            <button
              onClick={handleSortBy}
              className={`btn btn-outline-primary ${
                pageSetting.sort_by === "model" ? "active" : ""
              }`}
              name="model"
            >
              SKU
            </button>
            <button
              onClick={handleSortBy}
              className={`btn btn-outline-primary ${
                pageSetting.sort_by === "quantity" ? "active" : ""
              }`}
              name="quantity"
            >
              Unidades
            </button>
            <button
              onClick={handleSortBy}
              className={`btn btn-outline-primary ${
                pageSetting.sort_by === "total" ? "active" : ""
              }`}
              name="total"
            >
              Ventas
            </button>
          </div>
        </div>
        <ProductsGroup products={products} pageSetting={pageSetting} />
      </div>
    </div>
  );
};

type GroupProps = {
  products: SalesProductsType[];
  pageSetting: PageSettingsType;
};

const ProductsGroup: React.FC<GroupProps> = ({ products, pageSetting }) => (
  <table className="table table-hover light_table  show_on_new ">
    <thead>
      <tr>
        <th scope="col">SKU</th>
        <th scope="col">Producto</th>
        {pageSetting.group ? null : <th scope="col">Variantes</th>}
        <th scope="col">Unidades</th>
        <th scope="col">Ventas</th>
      </tr>
    </thead>
    <tbody id="order_list">
      {products
        ? products.map((item, key) => (
            <List
              key={`${key}_${item.id}`}
              item={item}
              show_variant={!pageSetting.group}
            />
          ))
        : null}
    </tbody>
  </table>
);

type ListProps = {
  item: SalesProductsType;
  show_variant: boolean;
};

const List: React.FC<ListProps> = ({ item, show_variant }) => {
  return (
    <tr data-order_id={item.id}>
      <td className="order_id_row">
        <b>
          <Link to={`/products/edit/${item.id}`}>{item.model}</Link>
        </b>
      </td>
      <td> {item.title}</td>
      {show_variant ? <td> {item.options}</td> : null}
      <td>{item.quantity}</td>
      <td>
        <b className="">${formatNumber(item.total)}</b>
      </td>
    </tr>
  );
};

export default SalesProducts;
