import React from "react";
import { Link } from "react-router-dom";
import { has_access } from "../../../middleware/privileges";

import { lang } from "../../../middleware/lang";
const Head = ({ order_id, status }) => {
  return (
    <div className="row">
      <div className="col-12">
        <p className="xs_hidden">
          <Link to="/" className="btn btn-outline-info">
            <i className="fa fa-chevron-left py-2" aria-hidden="true"></i>{" "}
            {lang("Órdenes Activas", "Active Orders")}
          </Link>
          {has_access("512") ? (
            <Link
              to={`/point_of_sale/${order_id}`}
              className={`btn ml-3 ${
                status === "fulfilled" || status === "canceled"
                  ? "btn-outline-info disabled"
                  : "btn-info"
              }`}
              disabled={status === "fulfilled" || status === "canceled"}
            >
              <i className="far fa-edit py-2" aria-hidden="true"></i>{" "}
              {lang("Modificar Pedido", "Edit Order")}
            </Link>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default Head;
