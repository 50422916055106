import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { FileWithPath, useDropzone } from "react-dropzone";
import { Image } from "../../../../models/product_model";
import { GET_PRODUCT, PRODUCT_IMAGE_UPLOAD } from "../queries";

type DropzoneProps = {
  cancel: () => void;
  productId: number;
};

type FileWithPreview = FileWithPath & {
  preview: string;
};

const Basic: React.FC<DropzoneProps> = ({ cancel, productId }) => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [mutate, { called, error }] = useMutation(PRODUCT_IMAGE_UPLOAD, {
    refetchQueries: [{ query: GET_PRODUCT, variables: { id: productId } }],
  });

  useEffect(() => {
    if (called && !error) {
      cancel();
    }
  }, [called, error, cancel]);

  const onSend = () => {
    if (files[0]) {
      mutate({ variables: { file: files[0], productId } });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file: FileWithPreview) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img src={file.preview} alt="thumbnail" className="thumbImg" />
      </div>
    </div>
  ));

  return (
    <section className="container">
      {files.length === 0 && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Arrastra una imagen o haz click para seleccionarla</p>
        </div>
      )}
      <aside className="thumbsContainer">{thumbs}</aside>
      <div className="row pt-1">
        <button
          className="btn btn-primary btn-sm mr-1"
          onClick={onSend}
          disabled={files.length === 0}
        >
          Subir imagen
        </button>
        <button className="btn btn-secondary btn-sm" onClick={cancel}>
          Cancelar
        </button>
      </div>
    </section>
  );
};

type Props = {
  productId: number;
  images?: Image[];
};

const ProductImage: React.FC<Props> = ({ images, productId }) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit && (
        <div>
          <Basic productId={productId} cancel={() => setEdit(false)} />
        </div>
      )}
      {!edit && (
        <div className="product_img d-inline-block">
          <img
            src={
              images && images.length > 0
                ? images[0].url.replace("ftp-foo.s3.amazonaws.com", "cdn.foopos.mx").replace("s3.amazonaws.com/ftp-foo", "cdn.foopos.mx")
                : "https://s3.amazonaws.com/ftp-foo/page_assets/no_photo_04.jpg"
            }
            className="img-thumbnail"
            alt="stuff"
          />
          {productId > 0 && (
            <div className="overlay_options d-flex align-items-center justify-content-center">
              <button
                className="btn btn-link "
                type="button"
                onClick={() => setEdit(true)}
              >
                <i className="fas fa-edit" aria-hidden="true"></i>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductImage;
