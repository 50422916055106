import React from "react";
import { has_access } from "../../middleware/privileges";

type ButtonProps = {
  fun: any;
  children?: React.ReactNode;
  text?: string;
  disabled?: boolean;
  classes?: string;
  icon?: string;
  status?: string;
  priv?: number;
  data_type?: string;
};

export const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  fun,
  classes,
  status,
  priv,
  data_type,
}) => {
  if (priv && !has_access(priv)) {
    return null;
  }

  return (
    <button
      className={classes}
      onClick={fun}
      data-status={status}
      data-type={data_type}
    >
      <i className={`${icon}`}></i> {text}
    </button>
  );
};
