import { lang } from "../middleware/lang";

export function statusText(text) {
  switch (text) {
    case "placed":
      return lang("Nuevo!", "New");

    case "ready":
      return lang("Pedido Listo", "Order Ready");
    case "new":
      return lang("Nuevo!", "New");

    case "in_progress":
      return lang("Cocinando ", "Cooking");

    case "idle":
      return lang("En Espera ", "Idle");

    case "cancelled":
      return lang("Cancelado", "Cancelled");

    case "ongoing":
      return lang("Activo", "On Going");

    case "pending":
      return lang("Error", "Error"); /// partners should not see 'pending' orders because the user );

    case "fulfilled":
      return lang("Listo", "Fulfilled");

    case "rejected":
      return lang("Rechazado", "Rejected ");

    default:
      return "Error";
  }
}

export function deliveryText(text) {
  switch (text) {
    case "pickup":
      return {
        txt: lang("Para Recoger", "Takeout"),
        icon: "far fa-hand-point-up",
        desc: lang(
          "El cliente vendrá a recogerlo cuando el pedido esté listo",
          "The client is going to pick it up when"
        ),
      };
    case "for_here":
      return {
        txt: lang("En Comedor", "To eat here"),
        icon: "fas fa-stop-circle",
        desc: lang(
          "En este tipo de ordenes el cliente puede seguir agregando productos al pedido",
          "On this type of orders, the client can add more products to his order"
        ),
      };
    case "other_apps":
      return {
        txt: lang("Plataformas", "Apps"),
        icon: "fas fa-mobile-alt",
        desc: lang(
          "Pedido recibido mediante alguna aplicación de reparto",
          "This orders comes form another delivery app"
        ),
      };
    default:
      return {
        txt: lang("Envío a Domicilio", "Delivery"),
        icon: "fas fa-motorcycle",
        desc: lang(
          "El mapa es informativo, revisa bien la dirección que el cliente envió",
          ""
        ),
      };
  }
}

export function paymentText(text) {
  switch (text) {
    case "cash":
      return {
        txt: lang("Efectivo", "Cash"),
        icon: "",
      };
    case "online":
      return {
        txt: lang("Pagado Online", "Payed Online"),
        icon: "",
      };
    case "card_pos":
    case "terminal":
      return {
        txt: lang("Con Tarjeta", "By Card"),
        icon: "",
      };
    default:
      return {
        txt: lang("Efectivo", "Cash"),
        icon: "",
      };
  }
}

export function getTableName(table) {
  if (!table) return "-";
  return table.name || `#${table.id}`;
}
