import { gql } from "@apollo/client";
export const SIGNUPMUTATION = gql`
  mutation SignupUser(
    $email: String!
    $password: String!
    $company_name: String!
    $name: String!
    $lastname: String!
    $phone: String!
  ) {
    signUp(
      email: $email
      password: $password
      company_name: $company_name
      name: $name
      lastname: $lastname
      phone: $phone
    ) {
      token
      user {
        id
        name
        lastname
        subuser_id
        privileges
        partner {
          id
          status
          company_name
          owner_name
          owner_lastname
          logo
          branches
        }
        branch {
          id
          name
        }
      }
      settings {
        id
        type
        pref_key
        pref_value
        is_global
      }
    }
  }
`;
