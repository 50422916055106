const Cookies = require("js-cookie");
var selected_lang = Cookies.get("selected_lang") || "es";

export function lang(es, en) {
  return selected_lang === "en" ? en : es;
}

export const lang_is = lang("es", "en");

export function setLang(lang) {
  var ch_lang = selected_lang === "en" ? "es" : "en";
  Cookies.set("selected_lang", ch_lang);
  window.location.reload();
}
